import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, SelectField, SmallInput } from 'scorer-ui-kit';

import { INodeTargets, INodeOptionSet, INodeOptionSetOptions, IPipelineNodeCore } from '..';
import { useTranslation } from 'react-i18next';

const NodeButtonRow = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin-top: 10px;

  button {
    margin-left: 10px;
  }
`

const InlineLabel = styled.div`

  font-family: ${({theme}) => theme.fontFamily.ui};
  font-size: 14px;
  color: #888;
  line-height: 30px;

  > div, > label {
    display: inline-block;
    margin-bottom: -5px;
    margin-right: 10px;
  }
`

interface INodeSectionEdit {
  type: INodeTargets;
  index: number;
  config?: INodeOptionSet[];
  configFields?: IPipelineNodeCore['data']['configFields'],
  handlerCancel?: any
  handlerUpdate?: any
  externalData: {[key:string]: any[]}
}


const NodeSectionEdit : React.FC<INodeSectionEdit> = ({type, index, config, configFields=[], handlerCancel, handlerUpdate, externalData}) => {
  const { t } = useTranslation(['Common']);
  const [newConfigFields, setNewConfigFields] = useState<IPipelineNodeCore['data']['configFields']>(configFields);
  const updateKeyValue = useCallback((index: number, value : any) => {
    setNewConfigFields((oldConfigFields=[] )=> {
      oldConfigFields[index] = value;
      return [...oldConfigFields]
    });
  },[]);

  const createSelectField = (config : any, selectedValue: any, optionIndex: number) => {
    const {name, options, label, optionsSource} = config;
    let overrideOptions :{label: string,value: (string|number)}[]= [];
    if(optionsSource && externalData[optionsSource] && externalData[optionsSource].length > 0){
      //Watch out for now we assume this will always be a string
      overrideOptions = externalData[optionsSource].map(({name=''}:any)=>({label:name, value:name}));
    }
    return (
      <SelectField key={'option-' + optionIndex} defaultValue={selectedValue} isCompact label={{text: label, htmlFor: name}} placeholder={t('eventRules.selectConditionType')} changeCallback={ (value) => updateKeyValue(optionIndex, value) }>
        {(overrideOptions.length ? overrideOptions :options).map((option : INodeOptionSetOptions, index : number) => {
          return <option key={'select-' + name + index} value={option.value}>{option.label}</option>
        })}
      </SelectField>
    )
  }

  const createTextField = (config : any, value: any ,optionIndex: number) => {
    const {label} = config;
    return (
      <SmallInput key={'option-' + optionIndex} type='text' defaultValue={value} {...{label}} onChange={ (event) => updateKeyValue(optionIndex, event.target.value) } />
    )
  }

  const createCheckboxField = (config : any, value: any,optionIndex: number) => {
    const {label} = config;

    return (
      <InlineLabel key={'option-' + optionIndex}>
        <Checkbox checked={value} onChangeCallback={ (value) => { updateKeyValue(optionIndex, value) } }/>
        {label}
      </InlineLabel>
    )
  }


  return (
    <>
      {config?.map((conf, i) => {
        const {fieldType} = conf;
        const value = (newConfigFields||[])[i];
        switch(fieldType){
          case 'select':
            return createSelectField(conf, value, i);
          case 'text':
            return createTextField(conf, value, i);
          case 'checkbox':
            return createCheckboxField(conf, value, i);
          default:
            throw new Error();
        }
      })}

      <NodeButtonRow>
        {handlerCancel ? <Button size='xsmall' design='secondary' onClick={ () => handlerCancel(type, index, false)}>{t('cancel')}</Button> : null}
        {handlerUpdate ? <Button size='xsmall' onClick={ () => handlerUpdate({target: type, index, config, overview: newConfigFields?.[0] || '', configFields: newConfigFields})}>{t('save')}</Button> : null}
      </NodeButtonRow>
    </>
  )

}

export default NodeSectionEdit;