import format from 'date-fns/format';
import sub from 'date-fns/sub';
import { useState, useCallback } from 'react';
import { Alert } from '../context/AlertsContext';
import { dateTimeFormat } from '../utils';

interface AlertResponse {
  alerts: Alert[],
  message: string; // "Succeeded",
  info: {
    limit: number,
    total: number,
    offset: number;
  }
}

export interface IFetchAlertOptions {
  limit?: number;
  offset?: number;
  alertType?: string;
  startTime?: Date;
  endTime?: Date;
  locationId?: string | number;
  active?: boolean;
  cameraId?: string | number;
  zoneId?: string | number;
  sortBy?: string;
  sortDirection?: string;
}

export const useAlertsWithoutContext = () => {
  const [alerts, setAlerts] = useState<Alert[]>();
  const [alertsLoading, setAlertsLoading] = useState<boolean>(true);
  const [alertsErrorMessage, setAlertsErrorMessage] = useState('');
  const [alertCount, setAlertCount] = useState(0);

  const deleteAlert = useCallback(async (alertId: number) => {
    setAlertsErrorMessage('');
    try {
      const response = await fetch(`/api/alert/${alertId}`, { method: 'DELETE' })

      if (response.ok) {
        setAlerts((alerts) => {
          if (!alerts) return alerts;
          const index = alerts.findIndex(({ id }) => id === alertId);
          const newAlerts = [...alerts];
          if (index !== -1) {
            newAlerts.splice(index, 1);
            return newAlerts;
          } else {
            return alerts;
          }
        });
      } else {
        console.error(`Delete Alert error: ${response.statusText}`);
        setAlertsErrorMessage(`Delete Alert error: ${response.statusText}`);
      }


    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
      setAlertsErrorMessage('Failed to delete alert');
    }
  }, [setAlerts]);


  const dismissAlert = useCallback(async (alert: Alert) => {
    setAlertsErrorMessage('');
    try {
      const response = await fetch(`/api/alert/${alert.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...alert, active: false })
      });

      if (response.ok) {
        setAlerts((alerts) => {
          if (!alerts) return alerts;
          const index = alerts.findIndex(({ id }) => id === alert.id);
          if (index !== -1) {
            return [...alerts.slice(0, index), { ...alert, active: false }, ...alerts.slice(index + 1, alerts.length)]
          } else {
            return alerts;
          }
        });
      } else {
        console.error(`Alert dismiss error: ${response.statusText}`);
        setAlertsErrorMessage(`Alert dismiss error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
      setAlertsErrorMessage('Failed to dismiss alert');
    }
  }, [setAlerts]);

  const dismissAllAlerts = useCallback(async (property: string, id: number) => {
    setAlertsErrorMessage('');
    try {
      const response = await fetch(`/api/dismiss_all_alerts/by_${property}/${id}`, {
        method: 'PUT'
      })

      if (response.ok) {
        setAlerts((alerts) => {
          if (!alerts) return alerts;

          return [...alerts.map((alert) => alert.camera_id === id ? ({ ...alert, active: false }) : alert)];
        });
      }else {
        console.error(`Dismiss all error: ${response.statusText}`);
        setAlertsErrorMessage(`Dismiss all error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
      setAlertsErrorMessage('Failed to dismiss alert');
    }
  }, [setAlerts]);

  const fetchAlerts = useCallback(async (options?: IFetchAlertOptions) => {
    const {
      limit = 100,
      offset = 0,
      alertType = '',
      startTime,
      endTime,
      locationId,
      active,
      cameraId,
      zoneId,
      sortBy,
      sortDirection
    } = (options || {});

    setAlertsLoading(true);
    setAlertsErrorMessage('');
    try {
      const searchString = new URLSearchParams({
        limit: '' + limit,
        offset: '' + offset,
        start_time: format(startTime ? startTime : sub(new Date(), { days: 12 }), dateTimeFormat),
        end_time: format(endTime ? endTime : new Date(), dateTimeFormat),
        ...(locationId ? { location_id: `${locationId}` } : {}),
        ...(alertType ? { alert_type: alertType } : {}),
        ...(active !== undefined ? { active: `${active}` } : {}),
        ...(cameraId !== undefined ? { camera_id: `${cameraId}` } : {}),
        ...(zoneId ? { zone_id: `${zoneId}` } : {}),
        ...(sortBy ? { sort_by: `${sortBy}` } : {}),
        ...(sortDirection ? { sort_direction: `${sortDirection}` } : {})
      });
      const url = '/api/alert?' + searchString.toString();
      const response = await fetch(url);

      if (response.ok) {
        const {
          alerts = [],
          info: {
            total
          }
        } = await response.json() as AlertResponse;
        setAlerts(alerts);
        setAlertCount(total);
      }else {
        console.error(`Alert fetch error: ${response.statusText}`);
        setAlertsErrorMessage(`Alert fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setAlertsErrorMessage(error.message);
      } else {
        console.error(error);
        setAlertsErrorMessage('Unknown Error fetching Cameras.');
      }
    }
    setAlertsLoading(false);
  }, [setAlerts]);

  return {
    alerts,
    alertsLoading,
    alertsErrorMessage,
    alertCount,
    actions: {
      dismissAlert,
      fetchAlerts,
      deleteAlert,
      dismissAllAlerts
    }
  }
}