import React from 'react';
import { Checkbox } from 'scorer-ui-kit';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


interface ICheckboxWithTitle {
  checked: boolean
  onChangeCallback?: (checked: boolean, indeterminate?: boolean) => void;
}


const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxText = styled.div`
  margin-left: 10px;
`;

const CheckboxWithTitle: React.FC<ICheckboxWithTitle> = ({ checked, onChangeCallback, ...props }) => {

  const { t } = useTranslation(['RelayController']);

  return (
    <CheckboxGroup {...props} >
      <Checkbox {...{ checked, onChangeCallback }} />
      <CheckboxText>{t('portTable.show')}</CheckboxText>
    </CheckboxGroup>
  )

}

export default CheckboxWithTitle;