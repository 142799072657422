import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tab, TabList, TabContent, Icon, TabContext } from 'scorer-ui-kit';
import LocationsTab from '../dashboard-tabs/LocationsTab';
import CameraTab from '../dashboard-tabs/CameraTab';
import AlertTab from '../dashboard-tabs/AlertTab';
import { Camera } from '../../hooks/useCameras';
import { Location } from '../../hooks/useLocations';
import { CameraAlertCount } from '../../hooks/useAlertCount';
import { ContextProps } from 'scorer-ui-kit/dist/Tabs/Tabs';
import { RichLocation } from '../../pages/Dashboard';
import { DASHBOARD_PARAMS, useSelected } from '../../hooks/useSelected';
import { Alert } from '../../context/AlertsContext';
import { useAlertsWithoutContext } from '../../hooks/useAlertsWithoutContext';


const Container = styled.div`
  overflow-y: scroll;
  height: 100%;
  border-radius: 3px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  background-color: hsla(0, 0%, 84%, 0.1);
  ::-webkit-scrollbar {  /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Divider = styled.div`
  height: 2px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.divider};
`;

const TabListWrapper = styled.div`
  position: sticky;
  top: 0;
  ${({ theme }) => theme.styles.global.background};
  z-index: 1;
  & > div {
    justify-content: space-between;
    div {
      width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: row;
      margin-right: 0px;
    }
    label {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 14px;
    }
    svg {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
`;


const TAB_LIST = [
  {
    tabFor: 'locations',
    icon: 'Zone'
  },
  {
    tabFor: 'camera',
    icon: 'Camera'
  },
  {
    tabFor: 'alert',
    icon: 'Notifications'
  }
];

interface IDashboardTabs {
  locations: Location[]
  cameras: Camera[]
  alertCounts: CameraAlertCount
  richLocations: RichLocation[]
  alerts: Alert[]
}

const DashboardTabs: FC<IDashboardTabs> = ({ alertCounts, richLocations, alerts, cameras }) => {
  const { selected }: ContextProps = useContext(TabContext);
  const { getParam, updateParam } = useSelected();
  const { alerts: cameraAlerts, actions: { fetchAlerts: fetchCameraAlert, dismissAlert, dismissAllAlerts } } = useAlertsWithoutContext();
  const [alertCamera, setAlertCamera] = useState<Camera>();
  const [camera, setCamera] = useState<Camera>();
  const [intrusionAlert, setIntrusionAlert] = useState<Alert>();

  const onChangeTab = useCallback((id) => {
    updateParam(DASHBOARD_PARAMS.tab, id);
  }, [updateParam]);

  useEffect(() => {
    const id = getParam(DASHBOARD_PARAMS.alertId);
    const alert = alerts.find((alert) => alert.id === Number(id));
    if (id && alert) {
      setIntrusionAlert(alert);
    }
  }, [getParam, alerts]);

  useEffect(() => {
    if (intrusionAlert) {
      const { camera_id }: Alert = intrusionAlert;
      setAlertCamera(cameras.find(({ id }) => id === camera_id));
    }
  }, [cameras, intrusionAlert, setAlertCamera]);

  useEffect(() => {
    const cameraID = getParam(DASHBOARD_PARAMS.cameraId);
    cameraID && setCamera(cameras.find(({ id }) => id === Number(cameraID)))
  }, [cameras, getParam, setCamera]);

  useEffect(() => {
    const id = getParam(DASHBOARD_PARAMS.cameraId);
    id && fetchCameraAlert({
      limit: 1,
      cameraId: parseInt(id)
    });
  }, [alerts, fetchCameraAlert, getParam]);

  return (
    <Container>
      <TabListWrapper>
        <TabList defaultTabId={getParam(DASHBOARD_PARAMS.tab) || 'locations'}>
          {TAB_LIST.map(({ tabFor, icon }) =>
            <Tab key={tabFor} tabFor={tabFor} onClick={() => onChangeTab(tabFor)}>
              <Icon icon={icon} color={selected === tabFor ? 'primary' : 'dimmed'} size={15} />
            </Tab>)
          }
        </TabList>
        <Divider />
      </TabListWrapper>

      <TabContent tabId='locations'>
        <LocationsTab {...{ richLocations }} alertCounts={alertCounts} />
      </TabContent>

      <TabContent tabId='camera'>
        <CameraTab
          cameraDetails={camera}
          totalAlerts={camera?.id !== undefined && alertCounts ? alertCounts[camera?.id] : 0}
          lastAlert={cameraAlerts && cameraAlerts[0] ? cameraAlerts[0].datetime : ''}
          {...{ dismissAllAlerts }}
        />
      </TabContent>

      <TabContent tabId='alert'>
        <AlertTab camera={alertCamera} {...{ intrusionAlert, dismissAlert }} />
      </TabContent>

    </Container>
  )
}

export default DashboardTabs;
