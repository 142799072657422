import * as React from 'react';

function SvgVehicle(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M21.25 9.25l-1.7-3.79A2.249 2.249 0 0017.441 4H7.059A2.249 2.249 0 004.95 5.46l-1.7 3.79m-1.5 6.718V17.5a1.5 1.5 0 103 0V16m18-.032V17.5a1.5 1.5 0 11-3 0V16m.375-3.75c.207 0 .375.168.375.375m-.75 0c0-.207.168-.375.375-.375m0 .75a.375.375 0 01-.375-.375m.75 0a.375.375 0 01-.375.375m-15.75-.75a.375.375 0 00-.375.375m.75 0a.375.375 0 00-.375-.375m0 .75a.375.375 0 00.375-.375m-.75 0c0 .207.168.375.375.375" />
        <path d="M3 9.25h18.5a2 2 0 012 2V15a1 1 0 01-1 1H2a1 1 0 01-1-1v-3.75a2 2 0 012-2z" />
      </g>
    </svg>
  );
}

export default SvgVehicle;
