import * as React from 'react';

function SvgFileTypesCsv(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M5.251 23.254h-3a1.5 1.5 0 01-1.5-1.5v-19.5a1.5 1.5 0 011.5-1.5H12.88a1.5 1.5 0 011.06.439l5.872 5.871a1.5 1.5 0 01.439 1.061v4.629" />
        <path d="M20.251 8.254h-6a1.5 1.5 0 01-1.5-1.5v-6m-1.5 15a3 3 0 00-3 3v1.5a3 3 0 003 3m6-7.5h-1.5a1.5 1.5 0 00-1.5 1.5c0 2.25 3 2.25 3 4.5a1.5 1.5 0 01-1.5 1.5h-1.5m6-7.5V18.3c0 1.764.522 3.488 1.5 4.955a8.935 8.935 0 001.5-4.955v-2.546" />
      </g>
    </svg>
  );
}

export default SvgFileTypesCsv;
