import * as React from 'react';

function SvgFileTypeImage(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M22.954 22a1.5 1.5 0 01-1.5 1.5H3.5A1.5 1.5 0 012 22V2.5A1.5 1.5 0 013.5 1h15a1.5 1.5 0 011.047.426l2.955 2.883c.29.282.452.67.452 1.074V22z" />
        <circle cx={8.75} cy={7.75} r={2.25} />
        <path d="M19.29 18.45l-3.925-5.888a1.011 1.011 0 00-1.671-.019l-2.691 3.845-1.668-1.338a1.011 1.011 0 00-1.474.229L5.75 18.45" />
      </g>
    </svg>
  );
}

export default SvgFileTypeImage;
