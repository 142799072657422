import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ToolBarButton from '../atoms/ToolBarButton';
import TopBreadCrumbs from '../atoms/TopBreadCrumbs';
import { Camera } from '../../../hooks/useCameras';
import { Zone } from '../../../hooks/useZones';
import { Location } from '../../../hooks/useLocations';

const Container = styled.div`
  height: 38px;
  color: hsl(0, 0%, 50%);
  display: flex;
  justify-content: right;
  ${({ theme }) => theme.styles.global.background};
`;

const StyledTopCrumbs = styled(TopBreadCrumbs)`
  border-right: 1px solid ${({ theme }) => theme.colors.divider};
`;

const StatsToggleBtn = styled(ToolBarButton)`
  border-right: 1px solid ${({ theme }) => theme.colors.divider};
`;

const FullScreenBtn = styled(ToolBarButton)`
  border-right: 1px solid ${({ theme }) => theme.colors.divider};
`;

interface IMapTopBar {
  statsVisible: boolean
  locations: Location[]
  zones: Zone[]
  cameras: Camera[]
  toggleStats: () => void
  toggleFullScreen: () => void
}

const MapTopBar: React.FC<IMapTopBar> = ({
  statsVisible,
  locations,
  zones,
  cameras,
  toggleStats,
  toggleFullScreen,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { t } = useTranslation(['Dashboard']);
  
  useEffect(()=>{
    document.addEventListener('fullscreenchange', _ => { 
      setIsFullScreen(document.fullscreenElement !== null);
    });
  },[])

  return (
    <Container>
      <StyledTopCrumbs {...{ cameras, zones, locations }} />
      <StatsToggleBtn icon='Usage' onClick={toggleStats}>{statsVisible ? t('mapTopBar.statsOff') : t('mapTopBar.statsOn')}</StatsToggleBtn>
      <FullScreenBtn icon='Fullscreen' onClick={toggleFullScreen}>{isFullScreen ? t('mapTopBar.return') : t('mapTopBar.fullScreen')}</FullScreenBtn>
    </Container>
  );
};

export default MapTopBar;