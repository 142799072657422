import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypeTable } from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled from 'styled-components';
import { EMPTY_ROWS } from '../../utils';

const Container = styled.div`
  margin: 40px 0 10px 0;
`;

interface ICSVTable {
  rejectList: string[]
}

const RejectedTable: React.FC<ICSVTable> = ({ rejectList }) => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const { t } = useTranslation(['NumberPlates', 'Common']);

  const tableColumns: ITableColumnConfig[] = [
    {
      header: t('rejectedEntries'),
      cellStyle: 'firstColumn',
    },
  ];

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = (rejectList ?? []).map((invalidRowText) => {


      const row: IRowData = {
        header: {
        },
        columns: [
          { text: invalidRowText },
        ]
      };
      return row;
    });
    return data.length ? data : EMPTY_ROWS;

  }, [rejectList]);

  useEffect(() => {
    setRowData(generateRowData())
  }, [generateRowData])

  return (
    <Container>
      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
      />
    </Container>

  );
};

export default RejectedTable;