import { useCallback, useState } from 'react';

export interface IEmail {
  id: number,
  template_id: number;
  email_to: string;
  email_cc: string;
  email_bcc: string;
  snapshot: boolean;
  clip: boolean;
}

export interface IEmailPayload extends Partial<IEmail> {
  id?: number
}

interface IEmailResponse {
  email: IEmail,
  message: string
}


export const useEmailAction = () => {
  const [email, setEmail] = useState<IEmail>();
  const [emailLoading, setEmailLoading] = useState<boolean>(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  const fetchEmailAction = useCallback(async (id: number) => {
    setEmailLoading(true);
    setEmailErrorMessage('');
    try {
      const response = await fetch(`/api/email/${id}`);

      if (response.ok) {
        const { email } = await response.json() as IEmailResponse;
        setEmail(email);
      } else {
        console.error(`Email fetch error: ${response.statusText}`);
        setEmailErrorMessage(`Email fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setEmailErrorMessage(error.message);
      } else {
        console.error(error);
        setEmailErrorMessage('Unknown Error fetching email action');
      }
    } finally {
      setEmailLoading(false);
    }
  }, []);

  const deleteEmailAction = useCallback(async (id: number) => {
    try {
      const res = await fetch(`/api/email/${id}`, {
        method: 'DELETE',
      });
      if (!res.ok) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const updateEmailAction = useCallback(async (payload: IEmailPayload) => {
    setEmailErrorMessage('');
    try {
      const method = typeof payload.id === 'number' ? 'PUT':'POST'
      const response = await fetch(method === 'POST' ? '/api/email' : `/api/email/${payload.id}`, {
        method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        const {email} = await response.json() as IEmailResponse;
        setEmail(email);
        return email;
      } else {
        console.error(`Email update error: ${response.statusText}`);
        setEmailErrorMessage(`Email update error: ${response.statusText}`);
        return false;
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setEmailErrorMessage(error.message);
      } else {
        console.error(error);
        setEmailErrorMessage('Unknown Error updating email');
      }
      return false;
    }
  }, []);


  return {
    email,
    emailLoading,
    emailErrorMessage,
    actions: {
      fetchEmailAction,
      updateEmailAction,
      deleteEmailAction
    }
  }
}