import * as React from 'react';

function SvgPersonIn(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <circle cx={6.25} cy={4} r={3} />
        <path d="M10.805 11.138A5.251 5.251 0 001 13.75V16h2.25L4 23.5h4.5l.323-3.233m14.676-3.77h-12m7 5l5-5-5-5" />
      </g>
    </svg>
  );
}

export default SvgPersonIn;
