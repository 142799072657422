import { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Icon } from 'scorer-ui-kit';
import { animation } from '../../themes/common';
import { DASHBOARD_PARAMS, useSelected } from '../../hooks/useSelected';
import { LAYER } from '../Map/atoms/MapZoomListener';

const Container = styled.div<{ isStatistics: boolean, layer: string }>`
  overflow: hidden;
  min-height: ${({ layer }) => layer === LAYER.cameras ? '28px' : '20px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  svg {
    margin-right: 6px;
  }
`;

const ListWrapper = styled.div<{ layer: string, isStatistics: boolean }>`
  min-height:${({ isStatistics, layer }) => isStatistics && (
      layer === LAYER.cameras ? '28px' : '20px'
    )};
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const ItemText = styled.div<{ isStatistics: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  margin-right: 6px;
  ${({ isStatistics }) => isStatistics ? css`
    letter-spacing: 0.34px;
    color: #949799;
    font-weight: 500;
  `:
    css`
    font-weight: 600;
    color: #808080;
  `}
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.51px;
  color: #666;
  margin-top: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 25px;
`;

const IconWrapper = styled.div`
    & > div {
      display: flex;
    }
`;

const slideInKeyframes = keyframes`
    0% {
      opacity: 50%;
    }
    100% {
      opacity: 100%;
    }
`;

const BreadcrumbItem = styled.div`
    display: flex;
    align-items: baseline;
    overflow: hidden;
    animation: ${slideInKeyframes} 0.5s ${animation.easing.primary.in};
`;

interface ITitleBlock {
  list: string[] | number[],
  title?: string,
  isStatistics?: boolean
}

const Breadcrumb: FC<ITitleBlock> = ({ list = [], title, isStatistics = false }) => {
  const { getParam } = useSelected();
  const layer = getParam(DASHBOARD_PARAMS.layer);
  return (
    <Container {...{ isStatistics, layer }}>
      <ListWrapper {...{ layer, isStatistics }}>
        {list.map((item, index) => (
          <BreadcrumbItem key={index}>
            {index !== 0 &&
              <IconWrapper><Icon icon='Right' color='dimmed' size={8} /></IconWrapper>}
            <ItemText {...{ isStatistics }}>{item}</ItemText>
          </BreadcrumbItem>
        ))}

      </ListWrapper>
      {title && <Title title={title}>{title}</Title>}
    </Container >
  )
}

export default Breadcrumb;