import React, { ChangeEvent, useCallback, useState } from 'react';
import { Button } from 'scorer-ui-kit';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SlimInput } from '../../themes/common';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;
`;

const LineName = styled.div`
  text-transform: capitalize;
  font-weight: 600;
  cursor: pointer;
`;

const StyledSlimInput = styled(SlimInput)`
  margin: 5px 5px 5px 0;
`;

interface ILineNameEdit {
  index: number,
  name?: string
  onSave?: (index: number, name: string) => void
}

const LineNameEdit: React.FC<ILineNameEdit> = ({
  index,
  name = '',
  onSave = () => { }
}) => {
  const { t } = useTranslation(['CameraDetails', 'Common']);
  const [value, setValue] = useState(name)
  const [isEditMode, setIsEditMode] = useState(false);

  const handleChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setValue(value);
  }, [])

  const handleSave = useCallback(() => {
    if (value !== '') {
      onSave(index, value)
      setIsEditMode(false)
    }

  }, [index, onSave, value])

  return (
    <Container>
      {isEditMode ?
        <>
          <StyledSlimInput placeholder={t('configTab.namePlaceholder')} {...{ value }} onChange={handleChange} />
          <StyledButton
            onClick={handleSave}
            size='xsmall' >
            {t('save')}
          </StyledButton>
        </>
        : <LineName onClick={() => setIsEditMode(true)}>{name}</LineName>
      }

    </Container>
  );
};

export default LineNameEdit;