import { useState, useCallback } from 'react';
import format from 'date-fns/format';
import { dateTimeFormat } from '../utils';

export interface NumberPlate {
  id: number;
  name: string;  // user editable name;
  description: string; // user editable
  type: 'LISTED' | 'IMPORTANT';
  user_type: string;
  kana: string;
  number: string //cause -;
  area: string;
  last_detected?: Date
  category: string
  tags: string
  [key: string]: number | string | 'LISTED' | 'IMPORTANT' | Date | undefined;
}

interface NumberPlatesResponse {
  number_plates: NumberPlate[],
  message: string; // "Succeeded",
  info: {
    limit: number,
    total: number,
    offset: number;
  }
}

export interface IFetchNumberPlatesOptions {
  limit?: number
  offset?: number
  type?: string
  sortBy?: string
  sortDirection?: string
  category?: string
  tags?: string
  startTime?: Date
  endTime?: Date
  number?: string
}

// const dateTimeFormat = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx';

export const useNumberPlates = () => {
  const [numberPlates, setNumberPlates] = useState<NumberPlate[]>([]);
  const [numberPlatesLoading, setNumberPlatesLoading] = useState<boolean>(true);
  const [numberPlatesErrorMessage, setNumberPlatesErrorMessage] = useState('');
  const [totalNumberPlates, setTotalNumberPlates] = useState(0);


  const deleteNumberPlate = useCallback(async (numberPlateID: number): Promise<boolean> => {
    setNumberPlatesErrorMessage('');
    try {
      const response = await fetch(`/api/number_plate/${numberPlateID}`, {
        method: 'DELETE'
      })

      if (response.ok) {
        setNumberPlates((numberPlates) => {
          if (!numberPlates) return numberPlates;
          const index = numberPlates.findIndex(({ id }) => id === numberPlateID);
          const newAlerts = [...numberPlates];
          if (index !== -1) {
            newAlerts.splice(index, 1);
            return newAlerts;
          } else {
            return numberPlates;
          }
        });
        return true;
      } else {
        console.error(`Delete Number Plate error: ${response.statusText}`);
        setNumberPlatesErrorMessage(`Delete Number Plate error: ${response.statusText}`);
        return false;
      }


    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
      setNumberPlatesErrorMessage('Failed to delete Number Plate');
      return false;
    }
  }, []);

  const fetchNumberPlates = useCallback(async (options?: IFetchNumberPlatesOptions) => {
    const {
      limit = 100,
      offset = 0,
      type,
      sortBy,
      sortDirection,
      tags,
      category,
      startTime,
      endTime,
      number,
    } = (options || {});

    setNumberPlatesLoading(true);
    setNumberPlatesErrorMessage('');

    try {
      const searchString = new URLSearchParams({
        limit: '' + limit,
        offset: '' + offset,
        ...(startTime ? { start_time: format(startTime, dateTimeFormat)} : {}),
        ...(endTime ? { end_time: format(endTime, dateTimeFormat)} : {}),
        ...(type ? { type: type } : {}),
        ...(sortBy ? { sort_by: `${sortBy}` } : {}),
        ...(sortDirection ? { sort_direction: `${sortDirection}` } : {}),
        ...(tags ? { tags: `${tags}` } : {}),
        ...(category ? { category: `${category}` } : {}),
        ...(number? {number: `${number}`} : {})
      });
      const url = '/api/number_plate?' + searchString.toString();

      const response = await fetch(url);

      if (response.ok) {
        const {
          number_plates = [],
          // offset
          // limit
          info: {
            total
          }
        } = await response.json() as NumberPlatesResponse;

        setNumberPlates(number_plates);
        setTotalNumberPlates(total);

      } else {
        console.error(`Number Plates fetch error: ${response.statusText}`);
        setNumberPlatesErrorMessage(`Number Plates fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setNumberPlatesErrorMessage(error.message);
      } else {
        console.error(error);
        setNumberPlatesErrorMessage('Unknown Error fetching Number Plates.');
      }
    }
    setNumberPlatesLoading(false);
  }, []);

  return {
    numberPlates,
    numberPlatesLoading,
    numberPlatesErrorMessage,
    totalNumberPlates,
    actions: {
      fetchNumberPlates,
      deleteNumberPlate,
    }
  }
}