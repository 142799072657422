import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LatLngTuple } from 'leaflet';
// import { Marker } from 'react-leaflet';
import PinSvg from './PinSvg';

const PIN_SIZE = 40;

interface ICameraMark {
  latlngPos: LatLngTuple
  totalAlerts: number
  onClick?: () => void
  onHover?: () => void
  onMouseLeave?: () => void
}

const CameraMark: React.FC<ICameraMark> = ({ latlngPos, totalAlerts, onClick, onHover, onMouseLeave }) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const totalAlertsRef = useRef<number>(totalAlerts)
  const hasIntruders = totalAlertsRef.current > 0;

  const svgIconBounds: LatLngTuple[] = [
    [latlngPos[0] - PIN_SIZE / 2, latlngPos[1] - PIN_SIZE / 2],
    [latlngPos[0] + PIN_SIZE / 2, latlngPos[1] + PIN_SIZE / 2],
  ];

  useEffect(() => {
    //less or equal should not trigger alarm
    if (totalAlertsRef.current < totalAlerts) {
      setIsAnimating(true);
    }

    totalAlertsRef.current = totalAlerts;

  }, [totalAlerts]);

  const handleEndAnimation = useCallback(() => {
    setIsAnimating(false);
  }, []);

  return (
    <>
      {/* <Marker position={latlngPos} />  Base marker to check the custom markers position */}
      <PinSvg
        bounds={svgIconBounds}
        hasAlert={hasIntruders}
        size={PIN_SIZE}
        {...{ onClick, onHover, onMouseLeave, isAnimating }}
        animationCallback={handleEndAnimation}
      />
    </>
  );
};

export default CameraMark;