import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, SelectField, IconButton, ButtonWithIcon } from 'scorer-ui-kit';
import { ICameraListData, INodeTargets, IPipelineActionRelationLink, IRelayListData } from '..';
import cloneDeep from 'lodash.clonedeep';

import { IRelay } from '../../../hooks/useRelays';
import { Camera } from '../../../hooks/useCameras';
import { useTranslation } from 'react-i18next';

const NodeButtonRow = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin-top: 10px;

  button {
    margin-left: 10px;
  }
`

const PairedEntityRow = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10px;
`

const RowHeader = styled.div`
  flex: 0 0 24px;
  justify-content: left;
  align-items: center;
  display: flex;
`

const Fields = styled.div`
  flex: 1;
`

const ListButtons = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
`
const HoverChange = styled.div`
  position: relative;
  height: 20px;

  > div:first-child {
    pointer-events: none;
  }

  > div {
    position: absolute;
    top: 0;
    opacity: 0;

    &:first-child {
      opacity: 1;
    }
  }

  &:hover {
    > div {
      opacity: 1;
      &:first-child {
        opacity: 0;
      }
    }
  }
`

interface INodeSectionEditRelations {
  type: INodeTargets;
  index: number;
  links: IPipelineActionRelationLink[];
  linkedType: string;
  handlerCancel?: any
  handlerUpdate?: any
  cameras: Camera[];
  relays: IRelay[];
}

const NodeSectionEditRelations : React.FC<INodeSectionEditRelations> = ({type, index, links, handlerCancel, handlerUpdate, linkedType, relays, cameras}) => {
  const { t } = useTranslation(['Common']);
  const [configuration, setConfiguration] = useState<IPipelineActionRelationLink[]>(links || []);
  const [cameraList, setCameraList] = useState<ICameraListData[]>([]);
  const [relayList, setRelayList] = useState<IRelayListData[]>([]);

  useEffect(()=>{
    setRelayList(
      relays.map( ({id, name,port}) => ({relay_id: id, relay_name: name, port}) )
    );
  },[relays]);

  useEffect(()=>{
    setCameraList(
      cameras.map( ({id, name}) => ({camera_id: id, camera_name: name}) )
    );
  },[cameras]);

  const updateKeyValue = (index: number, key: 'cameraId' | 'entityId', value : any) => {
    let newConfiguration : IPipelineActionRelationLink[] = cloneDeep(configuration);
    newConfiguration[index][key] = parseInt(value);
    setConfiguration(newConfiguration);
  }

  const createCameraListOptions = (selectedCameraId : number | null) => {
    return cameraList.map(({camera_id, camera_name}, i) => {
      return <option value={camera_id} selected={camera_id === selectedCameraId} key={'camlist-' + index + '-' + i}>{camera_name} ({camera_id})</option>
    });
  }

  const createEntityTypeListOptions = (_selectedEntityId : number | null) => {
    let output ;
    switch(linkedType){
      case 'patlight':
        output = relayList.sort((a,b)=> a.port - b.port).map(({relay_id, relay_name, port}, i) => {
          return <option value={relay_id} key={'camlist-' + index + '-' + i}>{relay_name} ({port})</option>
        });
        break;
      case 'gate':
        output = relayList.sort((a,b)=> a.port - b.port).map(({relay_id, relay_name, port}, i) => {
          return <option value={relay_id} key={'camlist-' + index + '-' + i}>{relay_name} ({port})</option>
        });
        break;
      default:
        throw new Error('linkedType not found');
    }

    return output;
  }

  const addPair = () => {
    let newConfiguration : IPipelineActionRelationLink[] = cloneDeep(configuration);
    newConfiguration.push({ cameraId: null, entityId: null })
    setConfiguration(newConfiguration);
  }

  const removePair = (i: number) => {
    let newConfiguration : IPipelineActionRelationLink[] = cloneDeep(configuration);
    newConfiguration.splice(i, 1);
    setConfiguration(newConfiguration);
  }

  return (
    <>
      {configuration.map((link, i) => {
        const { cameraId, entityId } = link;
        return <PairedEntityRow key={'linklist-' + index + '-' + i}>

          <RowHeader>
            <HoverChange>
              <div><Icon icon='Link' size={16} color='dimmed' /></div>
              <div><IconButton icon='CloseCompact' size={16} onClick={() => { removePair(i) }}/></div>
            </HoverChange>
          </RowHeader>

          <Fields>
            <SelectField isCompact defaultValue={cameraId || ' '} changeCallback={(value) => updateKeyValue(i, 'cameraId', value)}>
              <option value=' ' disabled>{t('eventRules.chooseCamera')}</option>
              {createCameraListOptions(cameraId)}
            </SelectField>

            <SelectField isCompact defaultValue={entityId || ' '} changeCallback={(value) => updateKeyValue(i, 'entityId', value)}>
              <option value=' ' disabled>{t('eventRules.chooseEntity')}</option>
              { createEntityTypeListOptions(entityId) }
            </SelectField>

          </Fields>

        </PairedEntityRow>
      })}

      <ListButtons>
        <ButtonWithIcon onClick={ addPair } icon='Add' size='xsmall' design='secondary'>{t('eventRules.addLink')}</ButtonWithIcon>
      </ListButtons>

      <NodeButtonRow>
        {handlerCancel ? <Button size='xsmall' design='secondary' onClick={ () => handlerCancel(type, index, false)}>{t('cancel')}</Button> : null}
        {handlerUpdate ? <Button size='xsmall' onClick={ () => handlerUpdate(type, index, configuration, '')}>{t('save')}</Button> : null}
      </NodeButtonRow>
    </>
  )

}

export default NodeSectionEditRelations;