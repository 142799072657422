import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { SelectField } from 'scorer-ui-kit';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
  padding-bottom: 20px;
  margin-right: 10px;
`;

const FieldWrapper = styled.div`
  div {
    width: auto;
  }
  label {
    margin-left: 20px;
  }
`;

interface ILabel {
  htmlFor: string
  text: string
  isSameRow?: boolean
}

interface OwnProps {
  totalPages: number,
  pageSizeOptions?: number[],
  defaultPage?: number,
  defaultPageSize: number,
  onPageSizeChange: (value: string) => void,
  onPageChange: (value: string) => void,
  pageSizeLabel?: ILabel,
  pageLabel?: ILabel
}

type Props = OwnProps & HTMLAttributes<HTMLDivElement>

const Pagination: React.FC<Props> = ({
  pageSizeOptions = [10, 20, 30, 50, 100],
  defaultPageSize = 10,
  defaultPage = 1,
  pageSizeLabel,
  pageLabel,
  totalPages,
  onPageSizeChange = () => { },
  onPageChange = () => { }
}) => {

  return (
    totalPages ?
      <PaginationContainer>

        <FieldWrapper>
          <SelectField
            value={defaultPageSize}
            changeCallback={onPageSizeChange}
            label={pageSizeLabel}
          >
            {pageSizeOptions.map((size: number, index: number) => <option key={index} value={size}>{size}</option>)}
          </SelectField>
        </FieldWrapper>

        <FieldWrapper>
          <SelectField
            value={defaultPage}
            changeCallback={onPageChange}
            label={pageLabel}
          >
            {Array.from(Array(totalPages), (e, i) =>
              <option key={i + 1} value={i + 1}>{i + 1}</option>
            )}
          </SelectField>
        </FieldWrapper>
      </PaginationContainer>
      : null
  );
};

export default Pagination;