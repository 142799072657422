import { FC, useState } from 'react';
import { Icon, useTo } from 'scorer-ui-kit';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  div {
    display: flex;
    align-items: center;
  }
  cursor: pointer;
`;

const Text = styled.p`
  margin-right: 7px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  color: hsla(195,9.8%,51.8%,0.722);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 104px;
  :hover {
    color: hsl(205, 100%, 65%);
  }
`;

interface ILinkCell {
  text: string,
  id: number
}

const LinkCell: FC<ILinkCell> = ({ text = '-', id }) => {

  const [hovering, setHovering] = useState(false);

  const to = useTo();

  const onHoverProps = {
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false),
  };

  return (
    <Container title={text} onClick={to(`/cameras/${id}?fromPage=known-people`)}>
      <Text {...onHoverProps}>{text}</Text>
      <Icon icon='Forward' color={hovering ? 'primary' : 'dimmed'} size={12} weight='light'/>
    </Container>
  );
};

export default LinkCell;