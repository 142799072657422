import { FC } from 'react';
import { Icon } from 'scorer-ui-kit';
import styled, { css } from 'styled-components';

const CountContainer = styled.div<{ rightMargin?: string, minWidth?: string, hasBg?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-right: ${({ rightMargin }) => rightMargin};

  ${({ minWidth }) => minWidth
    ? `min-width: ${minWidth};`
    : 'min-width: 50px;'
  };

  ${({ hasBg }) => hasBg && css`
    justify-content: right;
  `}
`;

const WrapperAlert = styled.div<{ backgroundColor: string }> `
  display: flex;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 4px;
  border-radius: 3px;
  height: 20px;
  align-items: center;
`;

const CountValue = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-weight: 500;
  margin-left: 7px;
  letter-spacing: 0.08px;
  margin-bottom: 2px;
  font-size: 12px;
  color: #767676;
`;

const AlertCountValue = styled(CountValue) <{ isAlert?: boolean }>`
  font-size: 14px;
  ${({ isAlert }) => isAlert ? css`
  color: #fff;
`:
    css`
  color: #a6a6a6;
`}
`;

// TODO Remove when Darkmode updates
const IconWrapper = styled.div<{ inverseFix: boolean }>`
  ${({ inverseFix }) => inverseFix && `
    svg g {
      stroke: #fff;
    }
  `}
`;
interface IAlertCountWithIcon {
  count: number,
  rightMargin?: string,
  minWidth?: string,
}

interface ICountWithIcon {
  icon: string,
  count: number,
  rightMargin?: string,
  minWidth?: string,
}

const CountWithIcon: FC<ICountWithIcon> = ({ icon, count, rightMargin, minWidth }) => (
  <CountContainer {...{ rightMargin, minWidth }} >
    <Icon icon={icon} size={13} color='dimmed' />
    <CountValue>{count}</CountValue>
  </CountContainer>
);

const AlertsCountWithIcon: FC<IAlertCountWithIcon> = ({ count, rightMargin, minWidth }) => (
  <CountContainer {...{ rightMargin, minWidth }} hasBg={true}>
    <WrapperAlert backgroundColor={count ? '#de6b6b' : '#eaeaea'} >
      <IconWrapper inverseFix={!!count} >
        <Icon icon={'Notifications'} size={13} color={count ? 'inverse' : 'dimmed'} />
      </IconWrapper>
      <AlertCountValue isAlert={count > 0}>{count}</AlertCountValue>
    </WrapperAlert>
  </CountContainer>
);

export { CountWithIcon, AlertsCountWithIcon };