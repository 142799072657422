import React, { ButtonHTMLAttributes } from 'react';
import { resetButtonStyles, Spinner } from 'scorer-ui-kit';
import { TypeButtonDesigns, TypeButtonSizes } from 'scorer-ui-kit/dist/Form';
import styled, { css } from 'styled-components';

const StyledButton = styled.button<{ design: TypeButtonDesigns, size: TypeButtonSizes }>`
  ${resetButtonStyles};

  ${({ theme, design, size }) => css`
  ${theme.styles.form.button[design].default};
  height: ${theme.dimensions.form.button[size].height};
  padding: ${theme.dimensions.form.button[size].padding};

  ${theme.typography.form.button[design][size]};
  font-family: ${theme.fontFamily.ui};

  transition:
    background ${theme.animation.speed.fast} ${theme.animation.easing.primary.easeOut},
    opacity ${theme.animation.speed.normal} ${theme.animation.easing.primary.easeOut};

  &:hover:enabled {
    background: ${theme.styles.form.button[design].hover.backgroundColor};
  }

  &:active:enabled {
    background: ${theme.styles.form.button[design].active.backgroundColor};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 50%;
    background: ${theme.styles.form.button[design].disabled.backgroundColor};
  }
  `}

  border-radius: 3px;
`;

interface OwnProps {
  isLoading?: boolean
  design?: TypeButtonDesigns
  size?: TypeButtonSizes
}

type ILoadingButton = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

const LoadingButton: React.FC<ILoadingButton> = ({
  isLoading = false,
  design = 'secondary',
  size = 'small',
  children,
  ...props }) => {

  return (
    <StyledButton type='button' {...{ design, size }} {...props} >
      {isLoading
        ? <Spinner size={'small'} styling={'secondary'} />
        : children
      }
    </StyledButton>
  );
};

export default LoadingButton;