import * as React from 'react';

function SvgDarkMode(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M20.296 15.465l2.357-2.357a1.5 1.5 0 000-2.121L20.296 8.63V5.297a1.5 1.5 0 00-1.5-1.5h-3.333L13.107 1.44a1.5 1.5 0 00-2.122 0L8.63 3.797H5.296a1.5 1.5 0 00-1.5 1.5V8.63L1.44 10.987a1.5 1.5 0 000 2.12l2.357 2.358v3.332a1.5 1.5 0 001.5 1.5H8.63l2.356 2.357a1.5 1.5 0 002.122 0l2.356-2.357h3.333a1.5 1.5 0 001.5-1.5v-3.332z" />
        <path d="M12.046 6.797a5.194 5.194 0 00-2.25.525 5.222 5.222 0 010 9.45 5.243 5.243 0 102.25-9.975h0z" />
      </g>
    </svg>
  );
}

export default SvgDarkMode;
