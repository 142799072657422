import React from 'react';
import { TableRowThumbnail } from 'scorer-ui-kit';
import styled from 'styled-components';

interface IRowThumbnail {
  snapshotUrl?: string,
  videoUrl?: string
}
const Container = styled.div`
  svg path {
    stroke: hsla(0, 0%, 100%, 1.000);
  }
`

const RowThumbnail: React.FC<IRowThumbnail> = ({ snapshotUrl, videoUrl }) => {
  return (
    <Container>
      <TableRowThumbnail
        image={snapshotUrl}
        retryImageLoad={true}
        retryLimit={8}
        mediaType={videoUrl ? 'video' : 'img'}
        mediaUrl={videoUrl ? videoUrl : snapshotUrl}
      />
    </Container>
  );
};

export default RowThumbnail;