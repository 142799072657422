import React from 'react';
import styled from 'styled-components';

import CameraFilesTable from './CameraFilesTable';

const Container = styled.div``;

const CameraFileUpload: React.FC = () => {

  return (
    <Container>
      <CameraFilesTable  />
    </Container>
  );
};

export default CameraFileUpload;