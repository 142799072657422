import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Alert } from '../../context/AlertsContext';
import { Camera } from '../../hooks/useCameras';
import EmptyState from '../atoms/EmptyState';
import AlertInfo from './AlertInfo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const VideoContainer = styled.div`
  background-color: hsla(0deg, 0%, 0%, 35%);
  width: 100%;
  height: 250px;
`;
const Video = styled.video`
  width:  100%;
  height: 100%;
  object-fit: contain;
`;

interface IAlertTab {
  intrusionAlert?: Alert,
  camera?: Camera,
  dismissAlert?: (alert: Alert) => Promise<void>
}

const AlertTab: FC<IAlertTab> = ({ intrusionAlert, camera, dismissAlert }) => {
  const { t } = useTranslation('Dashboard');

  return (
    <Container>

      {intrusionAlert ?
        <>
          <VideoContainer>
            <Video src={intrusionAlert.movie_url} controls autoPlay muted />
          </VideoContainer>
          <AlertInfo intrusionAlert={intrusionAlert} {...camera} dismissAlert={dismissAlert} />
        </> :
        <EmptyState icon='Notifications' message={t('alertTab.noAlertSelected')} />}

    </Container>
  )
};

export default AlertTab;