import React, { FC } from 'react';
import styled from 'styled-components';
import { Camera } from '../../hooks/useCameras';
import OneCamMap from '../Map/organisms/OneCamMap';

const Container = styled.div`
  width: 100%;
  height: 520px;
  border-radius: 3px;
  background-color: #dddddd;
  margin: 50px 0;
`;

interface IZoneMapTab {
  camera?: Camera
}

const ZoneMapTab: FC<IZoneMapTab> = ({camera}) => {

  return (
    <Container>
      {/** Empty alerts for example TODO update or let's talk if this should be fetch in MapLayer3*/}
      {camera && (
        <OneCamMap
          {...{camera}}
          alertCounts={{}}
        />
      )}
    </Container>
  )
};

export default ZoneMapTab;