import React, { ButtonHTMLAttributes } from 'react';
import { Icon, resetButtonStyles } from 'scorer-ui-kit';
import styled from 'styled-components';

const ButtonContainer = styled.button`
  ${resetButtonStyles};
  color: hsl(0, 0%, 50%);
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const WrapperIcon = styled.div`
  > div {
    display: flex;
    align-items: center;
  }
`;

interface OwnProps {
  icon: string
  color?: string;
  size?: number;
}

type ITopBarButton = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

const TopBarButton: React.FC<ITopBarButton> = ({
  icon,
  color='mono',
  size = 18,
  children,
  ...props
}) => {
  return (
    <ButtonContainer {...props}>
      <WrapperIcon>
        <Icon {...{ icon }} size={size} color={color} weight='regular' />
      </WrapperIcon>

      {children}
    </ButtonContainer>
  );
};

export default TopBarButton;