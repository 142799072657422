import { useCallback, useState } from 'react';

export interface ITag {
  id: number
  tag: string
}

interface ITagResponse {
  message: string
  tags: ITag[]
}

export const useTags = () => {
  const [tags, setTags] = useState<ITag[]>([])
  const [tagsLoading, setTagsLoading] = useState(false);

  const fetchTags = useCallback(async () => {

    setTagsLoading(true);

    try {
      const response = await fetch('/api/tags')
      if (response.ok) {
        const { tags = [] } = await response.json() as ITagResponse
        setTags(tags);
      } else {
        return false;
      }
      return true;

    } catch (error) {
      console.error('error', error)
      return false;
    } finally {
      setTagsLoading(false);
    }

  }, [])

  return {
    tags,
    tagsLoading,
    actions: {
      fetchTags
    }
  }
}