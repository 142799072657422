import { IPipelineCondition } from '..';
import i18n from 'i18next';
const {t} = i18n;

export const Basic : IPipelineCondition = {
  meta: t('eventRules.condition'),
  title: t('eventRules.condition'),
  editing: false,
  subtype: 'Basic',
  data: {},
  icon: 'Critical'
}

export const NewNode : IPipelineCondition = {
  ...Basic,
  subtype: 'NewNode',
  title: t('eventRules.newCondition'),
  data: {}
}

// Person has category
// Person has tag(s)
// Number plate has category
// Number plate has tags

// export const PersonWithCategory : IPipelineCondition = {
//   ...Basic,
//   title: 'Person has category:',
//   config: [{
//     name: 'category',
//     label: 'Category',
//     fieldType: 'select',
//     options: [{
//       value: 'exampleCategoryA',
//       label: 'Example Category A'
//     },{
//      value: 'exampleCategoryB',
//      label: 'Example Category B'
//    },{
//      value: 'exampleCategoryC',
//      label: 'Example Category C'
//    }]
//    }
//   ]
// }
// export const PersonWithTag : IPipelineCondition = {
//   ...Basic,
//   title: 'Person has tag:',
//   config: [{
//      name: 'tag',
//      label: 'Tag',
//      fieldType: 'text'
//    }
//   ]
// }
export const SubjectWithCategory : IPipelineCondition = {
  ...Basic,
  icon: 'MetaCategories',
  subtype: 'SubjectWithCategory',
  title: t('eventRules.subjectHasCategory'),
  config: [
    {
      name: 'category',
      label:  t('eventRules.category'),
      fieldType: 'select',
      rule_key: 'subject_category',
      optionsSource: 'catagories',
      options: []
      }
  ],
  data: {
    configFields:[]
  }
}
export const SubjectWithTag : IPipelineCondition = {
  ...Basic,
  icon: 'MetaTags',
  subtype: 'SubjectWithTag',
  title: t('eventRules.subjectHasTag'),
  config: [{
     name: 'tag',
     label: t('eventRules.tag'),
     fieldType: 'text',
     rule_key: 'subject_tags'
  }],
  data: {
    configFields:[]
  }
}
// export const SubjectType: IPipelineCondition = {
//   ...Basic,
//   title: 'Subject Type:',
//   rule_key: 'subject_type',
//   config: [
//     {
//       name: 'metaType',
//       fieldType: 'checkbox',
//       label: 'LISTED',
//       value: true,
//       rule_value: 'LISTED'
//     }, {
//       name: 'metaType',
//       fieldType: 'checkbox',
//       label: 'Important',
//       value: true,
//       rule_value: 'IMPORTANT'
//     }, {
//       name: 'metaType',
//       fieldType: 'checkbox',
//       label: 'Unknown',
//       value: true,
//       rule_value: 'UNKNOWN'
//     }
//   ]
// }

// export const NumberPlateWithCategory : IPipelineCondition = {
//   ...Basic,
//   title: 'Number Plate has category:',
//   config: [{
//      name: 'category',
//      label: 'Category',
//      fieldType: 'select',
//      options: [{
//        value: 'exampleCategoryA',
//        label: 'Example Category A'
//      },{
//       value: 'exampleCategoryB',
//       label: 'Example Category B'
//     },{
//       value: 'exampleCategoryC',
//       label: 'Example Category C'
//     }]
//    }
//   ]
// }
// export const NumberPlateWithTag : IPipelineCondition = {
//   ...Basic,
//   title: 'Number Plate has tag:',
//   config: [{
//      name: 'tag',
//      label: 'Tag',
//      fieldType: 'text'
//    }
//   ]
// }

// export const ObjectTypes: IPipelineCondition = {
//   ...Basic,
//   title: 'Object Types',
//   rule_key: 'kind',
//   config: [
//     {
//       name: 'metaType',
//       fieldType: 'checkbox',
//       label: 'Car',
//       value: true,
//       rule_value: 'CAR'
//     }, {
//       name: 'metaType',
//       fieldType: 'checkbox',
//       label: 'Person',
//       value: true,
//       rule_value: 'PERSON'
//     }
//   ]
// }

// export const AllDetections: IPipelineCondition = {
//   ...Basic,
//   title: t('eventRules.allDetections'),
//   config: []
// }

// export const CustomCondition: IPipelineCondition = {
//   ...Basic,
//   title: 'Custom Detection Rule',
//   config: [
//     {
//       name: 'custom_rule',
//       label: 'Custom Rule (JSON)',
//       fieldType: 'text'
//     }
//   ]
// }



export const CameraFilter : IPipelineCondition = {
  ...Basic,
  title: t('eventRules.byCamera'),
  icon: 'Camera',
  subtype: 'CameraFilter',
  configTemplateSource: 'cameras',
  configTemplate:{
      name: 'metaType',
      fieldType: 'checkbox',
      label: 'name',
      value: true,
      rule_value: 'id',
      rule_key: 'camera_id'
  },
  config:[],
  data: {
    configFields:[]
  }
}
/*
export const NumberPlate : IPipelineCondition = {
  ...Basic,
  title: 'Number Plate',
  config: [
    {
      name: 'metaType',
      fieldType: 'text',
      label: 'My Label',
      value: 'Default'
    }
  ]
}

export const Person : IPipelineCondition = {
  ...Basic,
  title: 'Person',
  config: [
    {
      name: 'metaType',
      label: '',
      fieldType: 'select',
      options: [{
        value: 'person',
        label: 'Temp'
      }]
    }, {
      name: 'metaTypeB',
      fieldType: 'text'
    }, {

      name: 'metaTypeB',
      fieldType: 'text'
    }
  ]
}
*/

export type INodeConditionSubTypes = 'Basic' | 'SubjectWithCategory' | 'SubjectWithTag' | 'CameraFilter' ;

const ConditionNodes = {
  Basic,
  NewNode,
  SubjectWithCategory,
  SubjectWithTag,
  CameraFilter,
  // ObjectTypes,
  // SubjectType,
  // AllDetections,
  // CustomCondition
}

export default ConditionNodes;