import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, useModal } from 'scorer-ui-kit';
import styled, { css, keyframes } from 'styled-components';
import { StatsBlock, StatsMap } from '../../../hooks/useStats';
import Breadcrumb from '../../dashboard-tabs/Breadcrumb';
import { mapStatsZIndex } from '../mapSetup';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import { Location } from '../../../hooks/useLocations';
import { Zone } from '../../../hooks/useZones';
import { useMapHover } from '../../../hooks/useMapHover';
import { Camera } from '../../../hooks/useCameras';
import { animation } from '../../../themes/common';
import StatsTimeRangeModal from './StatsTimeRangeModal';
import { useLocation } from 'react-router-dom';
import { IFromTime } from '../../../types';
import { isBefore } from 'date-fns';

const Row = css`
  display: flex;
  flex-direction: row;
`;

const slideDownKeyframes = keyframes`
0% {
  transform: translate(0, -100%);
  opacity: 0%;
}
70% {
  opacity: 0%;
}
100% {
  transform: translate(0, 0%);
  opacity: 100%;
}
`;

const slideDownAnim = css`${slideDownKeyframes} 1s ${animation.easing.primary.inOut}`;

const Container = styled.div`
  width: 267px;
  height: auto;
  padding: 10px 16px 11px 14px;
  border-radius: 3px;
  position: absolute;
  top: 48px;
  right: 10px;
  box-shadow: 0 6px 10px 0 rgba(60, 142, 201, 0.45), inset 0 5px 3px 0 rgba(193, 203, 234, 0.15);
  background-color: rgba(239, 245, 248, 0.95);
  z-index: ${mapStatsZIndex};
  animation: ${slideDownAnim};
`;

const StatusOverviewText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #949799;
  margin-top: 3px;
  margin-bottom: 12px;
`;

const Divider = styled.div<{ marginTop?: string }>`
  height: 1px;
  opacity: 0.5;
  background-color: #d5d5d5;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 8px;
  ${({ marginTop }) => marginTop && css`
    margin-top: ${marginTop};
  `}
`;

const StatRowContainer = styled.div`
  ${Row}
  justify-content: space-between;
`;

const LeftSide = styled.div`
  ${Row}
  align-items: center;
  svg {
    margin-right: 7px;
  }
`;

const StatRowLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  line-height: 2.08;
  letter-spacing: 0.34px;
  color: #949799;
`;

const Value = styled(StatRowLabel)`
  font-family: ${({ theme }) => theme.fontFamily.data};
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Time = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  letter-spacing: 0.34px;
  color: #949799;
  display: flex;
`;

const TimeUnit = styled(Time)`
  font-size: 8px;
  margin-top: 4px;
  margin-right: 2px;
  display: flex;
  align-items: center;
`;

const EditLink = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  letter-spacing: 0.34px;
  color: #09f;
  cursor: pointer;
`;

const initialStats = {
  car_in: 0,
  car_out: 0,
  car_staying: 0,
  people_in: 0,
  people_out: 0,
  people_staying: 0
}

interface IStatRow {
  icon: string,
  label: string,
  value: string | number
}

const StatRow: FC<IStatRow> = ({ icon, label, value = '0' }) => {

  return (
    <StatRowContainer>
      <LeftSide>
        <Icon {...{ icon }} size={12} color='dimmed' />
        <StatRowLabel>{label}</StatRowLabel>
      </LeftSide>
      <Value>{value}</Value>
    </StatRowContainer>
  )
};

interface IMapStats {
  stats: StatsMap
  statsVisible: boolean
  locations: Location[]
  zones: Zone[]
  cameras: Camera[]
}

const MapStats: React.FC<IMapStats> = ({
  stats,
  statsVisible,
  zones,
  locations,
  cameras,
}) => {

  const { t } = useTranslation(['Dashboard']);
  const [selectedStats, setSelectedStats] = useState<StatsBlock>({});
  const [breadcrumbList, setBreadcrumbList] = useState<string[]>([]);
  const [toggleAnimation, setToggleAnimation] = useState(true);
  const { getParam, updateParam } = useSelected();
  const { hoverItem } = useMapHover();

  const { createModal } = useModal();

  const newParams = new URLSearchParams(useLocation().search);

  const [fromTime, setFromTime] = useState<IFromTime>({
    hrs: newParams.get(DASHBOARD_PARAMS.fromTimeHrs) || '00',
    mins: newParams.get(DASHBOARD_PARAMS.fromTimeMins) || '00'
  });

  const {
    car_in = 0,
    car_out = 0,
    car_staying = 0,
    people_in = 0,
    people_out = 0,
    people_staying = 0
  } = selectedStats;


  const getFilteredStats = useCallback((stats: StatsMap): StatsBlock => {

    if (stats === undefined) {
      return initialStats;
    }

    const { locationId, zoneId, cameraId } = DASHBOARD_PARAMS;
    const locationID = getParam(locationId);
    const zoneID = getParam(zoneId);
    const cameraID = getParam(cameraId);

    /** Hover stats take precedent over current layer stats */
    if (hoverItem !== null) {
      const { hoverType, locationId: hoverLoc, zoneId: hoverZone, cameraId: hoverCamera } = hoverItem;

      if (hoverType === 'camera' && hoverCamera !== null && hoverZone !== null && hoverLoc !== null) {
        return (stats?.locations?.[hoverLoc]?.zones[hoverZone]?.cameras[hoverCamera]?.stats || {})
      }

      if (hoverType === 'zone' && hoverLoc !== null && hoverZone !== null) {
        return stats?.locations?.[hoverLoc]?.zones[hoverZone]?.stats || {}
      }

      if (hoverType === 'location' && hoverLoc !== null) {
        return stats?.locations?.[hoverLoc]?.stats || {};
      }
    }

    if (locationID) {
      if (zoneID) {
        if (cameraID) {
          /** A camera was clicked */
          return stats?.locations?.[locationID]?.zones[zoneID]?.cameras[cameraID]?.stats || {};
        }
        return stats?.locations?.[locationID]?.zones[zoneID]?.stats || {};
      }
      /** Current location stats in zone layer */
      return stats?.locations?.[locationID]?.stats || {};
    }

    /** No filter or Hover on locations layer */
    return stats.stats;
  }, [getParam, hoverItem]);

  useEffect(() => {
    setSelectedStats(getFilteredStats(stats) || {});
  }, [stats, getFilteredStats]);


  const getBreadCrumbs = useCallback((locId, zId, camId) => {
    const newList = [];
    const resLocation = locations.find((location: Location) => location.id === parseInt(locId));
    resLocation && newList.push(resLocation.name);

    const resZone = zones.find((zone: Zone) => zone.id === parseInt(zId));
    resZone && newList.push(resZone.name);

    const resCam = cameras.find((camera: Camera) => camera.id === parseInt(camId));
    resCam && newList.push(resCam.name);

    if (newList.length === 0) {
      newList.push(t('statistics.fullMap'))
    }
    return newList

  }, [cameras, locations, t, zones])

  useEffect(() => {

    if (hoverItem !== null) {
      const { locationId: hoverLoc, zoneId: hoverZone, cameraId } = hoverItem;

      setBreadcrumbList(getBreadCrumbs(hoverLoc, hoverZone, cameraId));
    } else {
      const { locationId, zoneId, cameraId } = DASHBOARD_PARAMS;
      const camId = getParam(cameraId);
      const locId = getParam(locationId);
      const zId = getParam(zoneId);
      setBreadcrumbList(getBreadCrumbs(locId, zId, camId))
    }
  }, [getBreadCrumbs, getParam, hoverItem]);

  const onChangeCallback = useCallback((time) => {
    let { hrs, mins } = time;
    if (Number(hrs) === 24 && Number(mins)) {
      hrs = '00';
    }
    setFromTime({ hrs, mins });
  }, []);

  useEffect(() => {
    updateParam(DASHBOARD_PARAMS.fromTimeMins, Number(fromTime.mins));
    updateParam(DASHBOARD_PARAMS.fromTimeHrs, Number(fromTime.hrs));
  }, [updateParam, fromTime]);

  // This Effect toggles the entry animation every time mouse enters a different zone
  useEffect(() => {
    setToggleAnimation(false);
    const timeout = setTimeout(() => {
      setToggleAnimation(true);
    }, 5);

    return () => {
      clearTimeout(timeout);
    }

  }, [hoverItem])

  const openStatsTimeRangeModal = useCallback(() => {
    createModal({
      isCloseEnable: true,
      closeText: t('Common:close'),
      customComponent: (
        <StatsTimeRangeModal startTime={fromTime} onChangeCallback={onChangeCallback} />
      )
    });
  }, [createModal, t, fromTime, onChangeCallback]);

  const getStartDate = useCallback(() => {
    const date = new Date();
    const hrs = getParam(DASHBOARD_PARAMS.fromTimeHrs);
    const mins = getParam(DASHBOARD_PARAMS.fromTimeMins);
    date.setHours(Number(hrs) ?? 0, Number(mins) ?? 0, 0);
    return date;
  }, [getParam]);

  return (
    statsVisible && toggleAnimation
      ? (
        <Container>

          <Breadcrumb list={breadcrumbList} isStatistics />
          <StatusOverviewText>{t('statistics.visitorStatusOverview')}</StatusOverviewText>

          <Divider />

          <StatRow icon='VehicleIn' label={t('statistics.carEntered')} value={car_in} />
          <StatRow icon='VehicleOut' label={t('statistics.carLeft')} value={car_out} />
          <StatRow icon='Vehicle' label={t('statistics.totalCar')} value={car_staying} />

          <Divider marginTop='5px' />

          <StatRow icon='PersonIn' label={t('statistics.peopleEntered')} value={people_in} />
          <StatRow icon='PersonOut' label={t('statistics.peopleLeft')} value={people_out} />
          <StatRow icon='UserProfile' label={t('statistics.totalPeople')} value={people_staying} />

          <Divider marginTop='7px' />
          <DateContainer>
            <LeftSide>
              <Icon icon='DateTime' size={12} color='dimmed' />
              <Time>
                {isBefore(getStartDate(), new Date()) ?
                  t('statistics.today') : t('statistics.yesterday')}:
                &nbsp;
                {fromTime.hrs.length === 1 ? `0${fromTime.hrs}` : fromTime.hrs}:
                {fromTime.mins.length === 1 ? `0${fromTime.mins}` : fromTime.mins}
                <TimeUnit>{Number(fromTime.hrs) >= 12 ? 'PM' : 'AM'}</TimeUnit> ~ {t('statistics.now')}
              </Time>
            </LeftSide>
            <EditLink onClick={openStatsTimeRangeModal}>{t('statistics.edit')}</EditLink>
          </DateContainer>
        </Container>
      )
      : null
  );
};

export default MapStats;