import React from 'react';
import styled, { css } from 'styled-components';
import { LatLngTuple } from 'leaflet';
import { SVGOverlay } from 'react-leaflet';

const alertsClass = 'alerts';
const smallTriangle = 'small-triangle';
const alertFill = 'alert-fill';
const noAlertFill = 'no-alert-fil';

const SvgContainer = styled(SVGOverlay)`
  outline: none;
  path {
    // fill: hsla(212, 55%, 55%);
    // fill-opacity: 75%;
    fill: url(#${noAlertFill});
  }

  path.${alertsClass} {
    // fill: hsl(359, 63%, 67%);
    // fill-opacity: 75%;
    fill: url(#${alertFill})
  }

  path.${smallTriangle} {
    fill: #FFFFFF;
  }
`;

const ViewAngleG = styled.g<{ angle: number }>`
  ${({ angle }) => angle && css`
    transform-origin: center;
    transform: rotate(${angle}deg);
    overflow: visible;
  `}
`;

interface IViewAngleSvg {
  bounds: LatLngTuple[]
  hasAlert: boolean
  size: number
  angle?: number
  onClick?: () => void
  onHover?: () => void
  onMouseLeave?: () => void
  animationCallback?: () => void
}

const ViewAngleSvg: React.FC<IViewAngleSvg> = ({
  bounds,
  hasAlert,
  size,
  angle = 0,
  onClick = () => { },
  onHover = () => { },
  onMouseLeave = () => { },
  animationCallback = () => { }
}) => {
  return (
    <SvgContainer
      attributes={{ viewBox: '0 0 50 50', height: `${size}px`, width: `${size}px` }}
      bounds={bounds}
      pane='camera-view-pane'
      eventHandlers={{
        click: () => {
          onClick()
        },
        mouseover: () => {
          onHover()
        },
        mouseout: () => {
          onMouseLeave()
        }
      }}
    >
      <defs>
        <linearGradient id={`${alertFill}`} x1="100%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: 'hsla(359, 63%, 67%, .5)' }} />
          <stop offset="100%" style={{ stopColor: 'hsla(359, 63%, 67%)' }} />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id={`${noAlertFill}`} x1="100%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: 'hsla(212, 55%, 55%, .5)' }} />
          <stop offset="100%" style={{ stopColor: 'hsla(212, 55%, 55%)' }} />
        </linearGradient>
      </defs>
      <ViewAngleG {...{ angle }}>
        <path className={hasAlert ? alertsClass : ''} d="M25,25 l-10,-20 l20,0 Z" onAnimationEnd={animationCallback} />
        {/* <path className={smallTriangle} d="M24,22 l1,-2 l1,2 z" /> */}
      </ViewAngleG>
    </SvgContainer>
  );
}

export default ViewAngleSvg;