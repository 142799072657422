import { useCallback } from 'react';
import { IVector2 } from 'scorer-ui-kit/dist/LineUI';


export interface ILineConfig {
  name: string,
  points: IVector2[]
}

export type AlgorithmType = 'NUMBER_PLATE' | 'CAR_COUNTING' | 'FACE_IDENTIFICATION' | 'PEOPLE_COUNTING' | 'INTRUSIONS';

export interface ICameraConfig {
  id: number
  camera_id: number
  line_config: ILineConfig[]
  confidence_threshold: number,
  algorithm_type: AlgorithmType;
}

export interface ICamConfigPayload {
  camera_id: number
  line_config: ILineConfig[]
  confidence_threshold: number
  algorithm_type: AlgorithmType;
}

interface CameraConfigResponse {
  camera_config: ICameraConfig,
  message: string
}

interface CameraConfigsResponse {
  camera_configs: ICameraConfig[],
  message: string
}

interface CreateCameraConfigResponse {
  camera_config: ICameraConfig,
}

export const useCameraConfig = () => {

  const getCameraConfigById = useCallback(async (id: number): Promise<ICameraConfig | null> => {

    try {
      const response = await fetch(`/api/camera_config/${id}`);
      if (response.ok) {
        const { camera_config } = await response.json() as CameraConfigResponse;
        return camera_config;
      } else {
        console.error(`Error fetching Camera: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
    }
    return null;
  }, [])

  const getCameraConfigsByCameraID = useCallback(async (cameraID: number): Promise<ICameraConfig[] | null> => {

    try {
      const response = await fetch(`/api/camera_config?camera_id=${cameraID}`);
      if (response.ok) {
        const { camera_configs } = await response.json() as CameraConfigsResponse;
        return camera_configs;
      } else {
        console.error(`Error fetching Camera: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
    }
    return null;
  }, [])


  const updateCameraConfig = useCallback(async (id: number, payload: ICamConfigPayload) => {
    try {
      const response = await fetch(`/api/camera_config/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })

      if (response.ok) {
        return true;
      } else {
        console.error(`Error updating camera cameraConfig ${response.statusText}`)
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
    }

    return false;
  }, []);

  const createCameraConfig = useCallback(async (payload: ICamConfigPayload): Promise<ICameraConfig | null> => {
    try {
      const response = await fetch('/api/camera_config', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })

      if (response.ok) {
        const { camera_config } = await response.json() as CreateCameraConfigResponse;
        return camera_config;
      } else {
        console.error(`Error updating camera cameraConfig ${response.statusText}`)
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
    }
    return null;
  }, [])

  return {
    actions: {
      getCameraConfigById,
      updateCameraConfig,
      getCameraConfigsByCameraID,
      createCameraConfig
    }
  }
}