import L, { LatLngTuple } from 'leaflet';
import { Zone } from '../../hooks/useZones';
import { Points } from '../../types';


// Here is where the map says how the cartesian plane works [0,0] ->  [-MAX_HEIGHT, MAX_WIDTH]
const getMapBounds = (height: number, width: number): L.LatLngBounds => L.latLngBounds([0, 0], [-height, width]);

const getZoneMap = (zoneId: number, zones: Zone[]): string => {
  const foundZone = zones.find(({ id }) => (id === zoneId))
  if (foundZone) {
    return foundZone.image
  }
  return '';
}

const getCentroid = function ( bounds: LatLngTuple[]) {
  let twoTimesSignedArea = 0;
  let cxTimes6SignedArea = 0;
  let cyTimes6SignedArea = 0;

  const bLength = bounds.length

  const x = function (i: number) { return bounds[i %bLength][0] };
  const y = function (i: number) { return bounds[i %bLength][1] };

  for ( let i = 0; i < bLength; i++) {
      const twoSA = x(i)*y(i+1) - x(i+1)*y(i);
      twoTimesSignedArea += twoSA;
      cxTimes6SignedArea += (x(i) + x(i+1)) * twoSA;
      cyTimes6SignedArea += (y(i) + y(i+1)) * twoSA;
  }

  const sixSignedArea = 3 * twoTimesSignedArea;
  return [ cxTimes6SignedArea / sixSignedArea, cyTimes6SignedArea / sixSignedArea];
}

const getCenteredZoneId = (center: L.LatLng, zones: Zone[]) => {
  const foundZone = zones.find(({ points }) => {
    const coordinates: LatLngTuple[] = points.map(({ x, y }) => ([-y, x]))
    const bounds = new L.LatLngBounds(coordinates);
    return bounds.contains(center);
  });

  if (foundZone) {
    return foundZone.id
  }
};

const getZoneBounds = (points: Points[] ) => {
  const coordinates: LatLngTuple[] = points.map(({ x, y }) => ([-y, x]))
  return new L.LatLngBounds(coordinates);
}

export {
  getZoneMap,
  getMapBounds,
  getCentroid,
  getCenteredZoneId,
  getZoneBounds
}