import { useCallback, useState } from 'react';

export interface ICategory {
  id: number
  name: string
}

interface ICategoryResponse {
  message: string
  category: ICategory[]
}

export const useCategories = () => {
  const [catagories, setCatagories] = useState<ICategory[]>([])
  const [catagoriesLoading, setCatagoriesLoading] = useState(false);

  const fetchCategories = useCallback(async () => {

    setCatagoriesLoading(true);

    try {
      const response = await fetch('/api/category')
      if (response.ok) {
        const { category = [] } = await response.json() as ICategoryResponse
        setCatagories(category);
      } else {
        return false;
      }
      return true;

    } catch (error) {
      console.error('error', error)
      return false;
    } finally {
      setCatagoriesLoading(false);
    }

  }, [])

  return {
    catagories,
    catagoriesLoading,
    actions: {
      fetchCategories
    }
  }
}