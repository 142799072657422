import React, { useCallback } from 'react';
import { Zone } from '../../../hooks/useZones';
import MapZone from '../atoms/MapZone';
import styled from 'styled-components';
import { Camera } from '../../../hooks/useCameras';
import { CameraAlertCount } from '../../../hooks/useAlertCount';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import { LAYER } from '../atoms/MapZoomListener';
import { useMapHover } from '../../../hooks/useMapHover';
import { Location } from '../../../hooks/useLocations';

const Container = styled.div``;

interface IMapZones {
  locations: Location[]
  zones: Zone[],
  cameras: Camera[]
  alertCounts: CameraAlertCount
}

const MapZones: React.FC<IMapZones> = ({
    locations,
    zones,
    cameras,
    alertCounts,
  }) => {

  const { getParam, updateParam } = useSelected();
  const { updateZoneHover } = useMapHover();
  const layer = getParam(DASHBOARD_PARAMS.layer);

  const handleHover = useCallback((zoneId: number, locationId: number) => {
    updateZoneHover({zoneId, locationId})
  }, [updateZoneHover]);

  const handleMouseLeave = useCallback(() => {
    updateZoneHover(null);
  }, [updateZoneHover])

  const handleClick = useCallback((id: number) => {
    updateParam(DASHBOARD_PARAMS.zoneId, id);
  }, [updateParam]);

  return (
    <Container>
      {(layer === LAYER.zones) && zones.map(({ id, points, location_id, name }) => {
        const foundLocation: Location | undefined = locations.find(({ id }) => id === location_id)
        return (
          <MapZone
            key={id}
            {...{ id, alertCounts, layer }}
            coordinates={points.map(({ x, y }) => ([-y, x]))}
            cameras={cameras.filter(({ zone_id }) => id === zone_id)}
            onHover={() => handleHover(id, location_id)}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleClick(id)}
            locationName={foundLocation?.name}
            zoneName={name}
          />
        )
      })}
    </Container>
  );
};

export default MapZones;