import * as React from 'react';

function SvgLeft(props: ISvgIcons) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox={'0 0 24 24'}
      {...props}
    >
      <path
        d="M17.25 23.25L6.53 12.53a.751.751 0 010-1.06L17.25.75"
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      />
    </svg>
  );
}

export default SvgLeft;
