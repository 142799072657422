import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LatLngTuple } from 'leaflet';
import LocationInfo from './LocationInfo';
import LocationPolygon from './LocationPolygon';
import { Camera } from '../../../hooks/useCameras';
import { CameraAlertCount } from '../../../hooks/useAlertCount';
import { getTotalAlerts } from '../../../utils';
import { LAYER } from '../atoms/MapZoomListener';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import { Points } from '../../../types';

const Container = styled.div``;

interface IMapLocation {
  name: string
  coordinates: LatLngTuple[]
  points: Points[]
  isSelected?: boolean
  cameras: Camera[]
  alertCounts: CameraAlertCount,
  onClick?: () => void
  onHover?: () => void
  onMouseLeave?: () => void
}

const MapLocation: React.FC<IMapLocation> = ({
  name,
  coordinates,
  points,
  isSelected = false,
  cameras,
  alertCounts,
  onClick = () => { },
  onHover = () => { },
  onMouseLeave = () => { }
}) => {

  const totalAlertsRef = useRef<number>(getTotalAlerts(cameras, alertCounts));
  const hasAlerts = totalAlertsRef.current > 0;
  const [triggerAlerts, setTriggerAlerts] = useState(false);
  const { getParam } = useSelected();
  const layer = getParam(DASHBOARD_PARAMS.layer);

  useEffect(() => {
    const newTotal = getTotalAlerts(cameras, alertCounts);
    if (totalAlertsRef.current < newTotal) {
      setTriggerAlerts(prev => !prev);
    }
    totalAlertsRef.current = newTotal;

  }, [alertCounts, cameras])

  if (layer === LAYER.cameras) {
    return null;
  }

  return (
    <Container>
      <LocationPolygon
        hasAlerts={hasAlerts}
        isSelected={isSelected}
        triggerAlerts={triggerAlerts}
        positions={coordinates}
        {...{ onClick, onHover, onMouseLeave }}
      />
      {(layer === LAYER.locations) && <LocationInfo
        {...{ name, points }}
        size={300}
        totalAlerts={totalAlertsRef.current}
        totalCameras={cameras.length}
        bounds={coordinates}
      />}
    </Container>
  );
};
export default MapLocation;