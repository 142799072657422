import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  min-height: 70px;
  border-radius: 2px;
  border: dashed 1px hsl(0, 0%, 85%);
  background-color: hsl(208, 52%, 95%);
  padding: 17px 20px 16px;

`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-weight: 600;
  color: hsl(194, 10%, 52%);
  font-size: 14px;
  line-height: 14px;
  margin: 0 0 5px;
`

const Description = styled.h4`
  font-family: ${({ theme }) => theme.fontFamily.data};
  color: hsl(194, 10%, 52%);
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  margin: 0;

`

interface INode {
  title: string;
  description?: string;
}

const NodeEmptyState: React.FC<INode> = ({ title, description }) => {

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description ? description : '-'}</Description>
    </Container>
  )
}

export default NodeEmptyState;