import React, { useCallback, useEffect, useState } from 'react';
import { formatDistance } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import styled from 'styled-components';
import format from 'date-fns/format';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';


const Container = styled.div`
  display: block;
  line-height: 20px;
`
const RelativeDate = styled.div`
  font-weight: 500;
  font-style: italic;

  &:lang(ja){
    font-style: normal;
  }
`

const AbsoluteDate = styled.div`
  font-size: 12px;
  span {
    font-size: 10px;
  }
`

interface ITableCellRelativeTime {
  eventTime: string   // The "When" time
  timezone?: string   // Quick reference. In the future we should update this to use the +09:00 GMT instead
  dateFormat?: string // The format to show the date in the table cell
  updateRate?: number // Refresh every x seconds
}

const TableCellRelativeTime : React.FC<ITableCellRelativeTime> = ({ eventTime, dateFormat = 'yyyy/LL/dd HH:mm', timezone = 'JST', updateRate = 15 }) => {

  const [now, setNow] = useState<number>(Date.now());
  const [relativeTime, setRelativeTime] = useState<string>();
  const [stamp, setStamp] = useState<number>((new Date(eventTime)).getTime());
  const { t } = useTranslation(['Common']);


// had to add it here so it  would update when toggling translation
const getTimeSince = useCallback((date: Date | string) => {
    if (!date) return '-';

    try {
      const lang = i18n.language === 'ja' ? ja : enUS;

      const stampDate = new Date(date);
      const eventTimestamp = stampDate.getTime();

      const result = formatDistance(
        stampDate, new Date(),
        {
          locale: lang,
          addSuffix: true,
          includeSeconds: true
        }
      );

      const diff = Number(new Date()) - eventTimestamp;
      const minute = 60 * 1000;

      if( diff < minute) {
        return t('justNow')
      }

      return lang === ja ? result : result.charAt(0).toUpperCase() + result.slice(1);
    } catch (err) {
      console.error(err);
    }
    return '-';
  },[t])

  useEffect(() => {
    // If we're in the hour resolution, throttle to 5 minute refreshes.
    const stampDate = new Date(eventTime);
    const eventTimestamp = stampDate.getTime();
    const refreshRate = (now - eventTimestamp < 3600000) ? updateRate * 1000 : 300000;

    setRelativeTime(getTimeSince(eventTime))
    setStamp(eventTimestamp)

    const timeout = setTimeout(() => {
      setNow(Date.now())
    },  refreshRate);

    return () => {
      clearTimeout(timeout);
    };

  }, [eventTime, now, updateRate, t, getTimeSince]);


  return (<Container>
    <RelativeDate>{relativeTime}</RelativeDate>
    <AbsoluteDate>{format(stamp, dateFormat)} <span>{timezone}</span></AbsoluteDate>
  </Container>)
}

// Previous function
// const timeAgo = (prevDate: number) => {
//   // This function has been altered slightly. It should be unified with existing code but if in doubt, use this for now.
//   const diff = Number(new Date()) - prevDate;
//   const minute = 60 * 1000;
//   const hour = minute * 60;
//   const day = hour * 24;
//   const month = day * 30;
//   const year = day * 365;
//   switch (true) {
//       case diff < minute:
//           return 'Just Now'
//       case diff < hour:
//           return Math.round(diff / minute) + ' minutes ago';
//       case diff < day:
//           return Math.round(diff / hour) + ' hours ago';
//       case diff < month:
//           return Math.round(diff / day) + ' days ago';
//       case diff < year:
//           return Math.round(diff / month) + ' months ago';
//       case diff > year:
//           return Math.round(diff / year) + ' years ago';
//       default:
//           return '';
//   }
// };

export default TableCellRelativeTime;