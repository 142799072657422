import React, { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { animation } from '../../../themes/common';

const slideDownKeyframes = keyframes`
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0, 0%);
  }
`;

const Container = styled.div<{ hasEntryAnimation: boolean, noStripes: boolean }>`
  display: table-row;
  ${({ hasEntryAnimation }) => hasEntryAnimation && css`
    animation: ${slideDownKeyframes} 1s  ${animation.easing.primary.out}
  `};

  ${({ noStripes }) => !noStripes && `
    &:nth-child(2n-1) {
      background-color: hsla(0, 0%, 84%, 0.1);
    }
  `};

`;

const CellContainer = styled.div<{ isFirst: boolean, cellheight: string }>`
  font-family: ${(({ theme }) => theme.fontFamily.data)};
  display: table-cell;
  height: ${({ cellheight }) => cellheight};
  vertical-align: middle;
  position: relative;
  font-size: 12px;
  ${({ isFirst }) => isFirst && `
    padding-left: 15px`
  };
`;

export type ICellItem = string | ReactElement | undefined;

interface IEventRow {
  rowData: ICellItem[]
  rowId: number,
  cellheight?: string
  hasEntryAnimation?: boolean
  noStripes?: boolean
  animationEndCallback?: () => void
}

const EventRow: React.FC<IEventRow> = ({
  rowData,
  rowId,
  cellheight = '50px',
  noStripes = false,
  hasEntryAnimation = false,
  animationEndCallback = () => { },
}) => {

  return (
    <Container {...{ hasEntryAnimation, noStripes }} onAnimationEnd={animationEndCallback}>
      {
        rowData.map((cell, index) => {
          return <CellContainer key={`${rowId}-cell-${index}`} isFirst={index === 0} cellheight={cellheight}>{cell}</CellContainer>
        })
      }
    </Container>
  );
};

export default EventRow;