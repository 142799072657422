import format from 'date-fns/format';
import {useState, useCallback } from 'react';
import startOfDay from 'date-fns/startOfDay';

export interface StatsMap {
  stats: StatsBlock;
  locations: {
    [locationID: string]: LocationStats|undefined;
  }
}

interface LocationStats {
  stats: StatsBlock;
  zones: {
    [zoneID: string]: ZonesStats|undefined;
  }
}
interface ZonesStats{
  stats: StatsBlock;
  cameras: {
    [cameraID: string]: CameraStats|undefined;
  }
}
interface CameraStats{
  stats: StatsBlock
}

export interface StatsBlock {
  people_staying?: number;
  people_in?: number;
  people_out?: number;
  car_staying?: number;
  car_in?: number;
  car_out?: number;
}


interface FetchStatsOptions {
  cameraID?: number|string;
  zoneID?: number;
  locationID?: number;
  startTime?: Date;
  endTime?: Date;
}
export const useStats = () => {
  const [stats, setStats] = useState<StatsMap>({stats:{},locations:{}});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchStats = useCallback(async(options?: FetchStatsOptions) => {
    const {
      cameraID,
      zoneID,
      locationID,
      startTime = startOfDay(new Date()),
      endTime = new Date()
    } = (options||{});
    setErrorMessage('');
    //this prevents double calls to this expensive all on init
    let alreadyLoading = false;
    setLoading(loading => {
      alreadyLoading = loading;
      return true;
    });
    if(alreadyLoading) {
      return;
    }

    const searchString = new URLSearchParams({
      start_time: format(startTime, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx'),
      end_time: format(endTime, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx')
    });

    cameraID && searchString.append('camera_id',`${cameraID}`);
    zoneID && searchString.append('zone_id',`${zoneID}`);
    locationID && searchString.append('location_id',`${locationID}`);

    try {
      const response = await fetch(`/alert_api/statistics?${searchString.toString()}`)
      if(response.ok) {
        const stats = await response.json() as StatsMap;
        setStats(stats);
      }else {
        console.error(`Map Stats fetch error: ${response.statusText}`);
        setErrorMessage(`Map Stats fetch error: ${response.statusText}`);
      }

    } catch(error){
      if (error instanceof Error) {
        console.error(error.message);
        setErrorMessage(error.message);
      } else {
        console.error(error);
        setErrorMessage('Unknown Error fetching Stats.');
      }
    }
    setLoading(false);
  },[]);

  return{
    stats,
    loading,
    errorMessage,
    actions: {
      fetchStats
    }
  }
}

