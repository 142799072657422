import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Content,
  FilterBar,
  IFilterDatePicker,
  IFilterDropdownConfig,
  PageHeader,
  TypeTable,
  ActionButtons,
  IconButtonData,
  DateInterval,
  IFilterResult,
  IFilterItem,
  useModal,
  ConfirmationModal,
  useNotification
} from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled from 'styled-components';
import Pagination from '../components/Pagination';
import { INITIAL_ROWS } from '../constants';
import { downloadFile, dropdownHelper, getFilteredOptions, getSelectedDate, readParams, updateParams } from '../utils';
import LinkCell from '../components/atoms/LinkCell';
import { useCameras } from '../hooks/useCameras';
import { useLocations } from '../hooks/useLocations';
import { useZones } from '../hooks/useZones';
import { useHistory, useLocation } from 'react-router-dom';
import LongTextCell from '../components/atoms/LongTextCell';
import { Detection, IFetchDetectionOptions, isIntrusion, useDetections } from '../hooks/useDetections';
import TableCellRelativeTime from '../components/atoms/TableCellRelativeTime';

const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

const PageHeaderWrapper = styled.div`
  max-width: 610px !important;
`;

const FilterWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
`;

interface IRichDetections extends Detection {
  name?: string,
  zone_name?: string,
  location_name?: string
}

export interface ISelectedFilterValues {
  [key: string]: DateInterval | string | undefined | Date | IFilterItem[] | boolean
}


//FIXME: Fix this and the above are incorrect, each field should explicitly have a type. not be allowed to be any of the union of all types.
const initialFilterValues: ISelectedFilterValues = {
  type: undefined,
  when: undefined,
  location: undefined,
  alertStatus: undefined,
  zone: undefined,
  camera: undefined,
  sortDirection: 'asc',
  sortBy: 'datetime',
  paramsLoaded: false
}

const sizeOptions = [10, 20, 50, 100];

const IntrusionDetection: React.FC<{}> = () => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrntPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);
  const [richDetections, setRichDetections] = useState<IRichDetections[]>([]);
  const { detections, detectionCount, isLoading, actions: { fetchDetections, deleteDetection } } = useDetections();
  const { locations, loading: locationsLoading, actions: { fetchLocations } } = useLocations();
  const { zones, loadingZones, actions: { fetchZones } } = useZones();
  const { sendNotification } = useNotification();

  const params = useLocation().search;
  const [historyParams] = useState(params);

  const [dropdownConfig, setDropdownConfig] = useState<IFilterDropdownConfig[]>();

  const { cameras, loading: loadingCameras, actions: { fetchCameras } } = useCameras();

  const { push } = useHistory();
  const { createModal } = useModal();
  const { t } = useTranslation(['IntrusionDetection', 'Common']);

  const pageSizeLabel = {
    htmlFor: 'select-page-size',
    text: t('Common:filter.itemsPerPage'),
    isSameRow: true,
  };

  const pageLabel = {
    htmlFor: 'select-page',
    text: t('Common:filter.page'),
    isSameRow: true,
  };

  const tableColumns: ITableColumnConfig[] = [
    {
      groupTitle: t('meta'),
      header: t('type'),
      cellStyle: 'firstColumn',
      columnId: 'message'
    },
    {
      groupTitle: t('eventLocation'),
      header: t('location'),
      sortable: true,
      cellStyle: 'normalImportance',
      columnId: 'location_id',
      sortActive: selectedFilterValues.sortBy ?  (selectedFilterValues.sortBy === 'location_id') : false
    },
    {
      groupTitle: t('eventLocation'),
      header: t('zone'),
      sortable: true,
      cellStyle: 'normalImportance',
      columnId: 'zone_id',
      sortActive: selectedFilterValues.sortBy ?  (selectedFilterValues.sortBy === 'zone_id') : false
    },
    {
      groupTitle: t('eventLocation'),
      header: t('camera'),
      sortable: true,
      cellStyle: 'normalImportance',
      columnId: 'camera_id',
      sortActive: selectedFilterValues.sortBy ?  (selectedFilterValues.sortBy === 'camera_id') : false
    },
    {
      header: t('when'),
      sortable: true,
      cellStyle: 'lowImportance',
      columnId: 'datetime',
      sortActive: selectedFilterValues.sortBy ?  (selectedFilterValues.sortBy === 'datetime') : false
    },
    {
      header: t('actions'),
      cellStyle: 'normalImportance'
    }
  ];

  // const getTypesList = useCallback(() => {
  //   return [
  //     // {
  //     //   text: t('all'),
  //     //   value: 'ALL'
  //     // },
  //     {
  //       text: t('person'),
  //       value: 'FACE_IDENTIFICATION'
  //     },
  //     {
  //       text: t('vehicle'),
  //       value: 'NUMBER_PLATE'
  //     },
  //     {
  //       text: t('intrusions'),
  //       value: 'INTRUSIONS'
  //     }
  //   ];
  // }, [t]);

  const getDropdownConfig = useCallback(() => {

    const { filteredCameras } = getFilteredOptions(selectedFilterValues, zones, cameras);

    return ([
      // {
      //   id: 'type',
      //   buttonText: t('type'),
      //   list: getTypesList(),
      //   buttonIcon: 'AnalysisType',
      //   optionType: 'radio',
      //   selected: getSelected(selectedFilterValues.type)
      // },
      // {
      //   id: 'location',
      //   buttonText: t('location'),
      //   list: dropdownHelper(locations, { textKey: 'name', valueKey: 'id' }),
      //   buttonIcon: 'Location',
      //   optionType: 'checkbox',
      //   loadingText: t('loadingLocations'),
      //   hasOptionsFilter: true,
      //   isLoading: locationsLoading,
      //   searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
      // },
      // {
      //   id: 'zone',
      //   buttonText: t('zone'),
      //   list: dropdownHelper(filteredZones, { textKey: 'name', valueKey: 'id' }),
      //   buttonIcon: 'Zone',
      //   optionType: 'checkbox',
      //   loadingText: t('loadingZones'),
      //   hasOptionsFilter: true,
      //   isLoading: loadingZones,
      //   searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
      // },
      {
        id: 'camera',
        buttonText: t('camera'),
        list: dropdownHelper(filteredCameras, { textKey: 'name', valueKey: 'id' }),
        buttonIcon: 'Camera',
        optionType: 'checkbox',
        loadingText: t('loadingCameras'),
        hasOptionsFilter: true,
        isLoading: loadingCameras,
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
      }
    ]);
  }, [selectedFilterValues, zones, cameras, t, loadingCameras]);

  const datePickers: IFilterDatePicker[] = [
    {
      id: 'when',
      buttonIcon: 'DateTime',
      buttonText: t('when'),
      dateMode: 'interval',
      timeMode: 'interval',
      selected: getSelectedDate(selectedFilterValues.when) as DateInterval | undefined,
      dateTimeTextLower: t('Common:dateTo'),
      dateTimeTextUpper:  t('Common:dateFrom'),
    }
  ];

  useEffect(() => {
    setSelectedFilterValues(readParams(historyParams, initialFilterValues));
  }, [historyParams]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    push({ search: updateParams(selectedFilterValues) });
  }, [push, selectedFilterValues]);

  useEffect(() => {
    setDropdownConfig(getDropdownConfig() as IFilterDropdownConfig[]);
  }, [getDropdownConfig, locationsLoading, locations, zones, loadingZones, loadingCameras, cameras, selectedFilterValues]);

  useEffect(() => {
    fetchLocations();
    fetchZones();
  }, [fetchLocations, fetchZones]);

  const sortCallback = useCallback((ascending: boolean, columnId: string) => {
    setSelectedFilterValues((prev) => ({ ...prev, sortDirection: ascending ? 'asc' : 'desc', sortBy: columnId }))
  }, []);

  const getApiParams = useCallback((): IFetchDetectionOptions => {

    const { when, location, zone, camera, sortDirection = 'asc', sortBy = 'datetime' } = selectedFilterValues;

    const correctedUISort = sortBy === 'datetime'
    ? sortDirection === 'asc' ? 'desc' : 'asc'
    : sortDirection

    return ({
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      startTime: (when as DateInterval)?.start,
      endTime: (when as DateInterval)?.end,
      locationId: location as string,
      zoneId: zone as string,
      cameraId: camera as string,
      sortBy: sortBy as string,
      sortDirection: correctedUISort as string,
      algorithm: 'INTRUSIONS'
    });

  }, [pageSize, currentPage, selectedFilterValues]);

  const handleDelete = useCallback(async (detectionId: number) => {
    const result = await deleteDetection(detectionId);
    if (result) {
      sendNotification({ type: 'success', message: t('deleteSuccess') })
      fetchDetections(getApiParams());
    } else {
      sendNotification({ type: 'error', message: t('deleteError') })
    }
  }, [deleteDetection, fetchDetections, getApiParams, sendNotification, t])


  const openConfirmationModal = useCallback((detectionId: number) => {
    createModal({
      isCloseEnable: false,
      customComponent: (
        <ConfirmationModal
          title={t('Common:confirmEventDeletion')}
          message={t('Common:deleteAlertMsg')}
          leftButtonText={t('Common:cancel')}
          leftButtonDesign='secondary'
          rightButtonText={t('Common:delete')}
          rightButtonDesign='danger'
          rightButtonCallback={() => handleDelete(detectionId)}
        />
      )
    });
  }, [createModal, t, handleDelete]);

  const generateConfigButtons = useCallback((detectionId: number, movie_url): IconButtonData[] => {
    return (
      [
        {
          icon: 'Delete',
          onClick: () => openConfirmationModal(detectionId),
        },
        {
          icon: 'DownloadVideo',
          onClick: () => downloadFile(movie_url),
        },
        // {
        //   icon: 'Information',
        //   onClick: () => { },
        // }
      ]
    )
  }, [openConfirmationModal]);

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = (richDetections ?? []).map((item) => {

      const {
        id,
        movie_url,
        image_url,
        datetime,
        name = '-',
        zone_name = '-',
        location_name = '-',
        camera_id,
        detection
      } = item;

      const row: IRowData = {
        header: {
          mediaType: 'video',
          mediaUrl: movie_url,
          image: image_url,
          icon: isIntrusion(detection) && detection.kind === 'car' ? 'VehicleIntrusion' : 'PersonIntrusion'
        },
        columns: [
          { text: isIntrusion(detection) && detection.kind === 'car' ? t('vehicle') : t('person') },
          { customComponent: <LongTextCell text={location_name} /> },
          { customComponent: <LongTextCell text={zone_name} /> },
          { customComponent: <LinkCell text={name ?? '-'} id={camera_id} /> },
          { customComponent: <TableCellRelativeTime eventTime={datetime.toString() } /> },
          { customComponent: <ActionButtons buttonsConfig={generateConfigButtons(id, movie_url)} /> },
        ]
      };
      return row;
    });
    return data.length ? data : INITIAL_ROWS;
  }, [generateConfigButtons, richDetections, t]);

  useEffect(() => {
    if (isLoading) return;
    setRowData(generateRowData());
  }, [generateRowData, richDetections, isLoading]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    fetchDetections(getApiParams());
  }, [fetchDetections, getApiParams, pageSize, currentPage, selectedFilterValues]);

  const getRichDetections = useCallback(() => {
    if (!detections) return [];

    return detections.map(({ camera_id, ...rest }) => {

      const index = cameras.findIndex((camera) => camera.id === camera_id);

      if (index !== -1) {
        const { name, zone_name, location_name } = cameras[index];
        return ({
          camera_id,
          ...rest,
          name,
          zone_name,
          location_name
        })
      }

      return ({
        camera_id,
        ...rest,
      });
    }) as IRichDetections[];

  }, [detections, cameras]);

  useEffect(() => {
    fetchCameras();
  }, [fetchCameras])

  useEffect(() => {
    setRichDetections(getRichDetections())
  }, [detections, cameras, getRichDetections]);

  const onChangeFilterValue = useCallback((res: IFilterResult[]) => {
    const selectedValues: ISelectedFilterValues = res.reduce((selectedValues: ISelectedFilterValues, { id, selected }) => {

      switch (id) {
        case 'type': selectedValues[id] = (selected as IFilterItem).value as string;
          break;
        case 'when': selectedValues[id] = selected as DateInterval;
          break;
        default:
          selectedValues[id] = (selected as IFilterItem[]).map(({ value }) => value).join(',');
      }
      return selectedValues;
    },
      { ...initialFilterValues, paramsLoaded: selectedFilterValues.paramsLoaded }
    );
    setSelectedFilterValues({ ...selectedValues });
  }, [selectedFilterValues]);

  const onPageSizeChange = useCallback((val: string) => {
    setPageSize(parseInt(val));
    setCurrntPage(1);
  }, []);

  const onPageChange = useCallback((val: string) => {
    setCurrntPage(parseInt(val));
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(detectionCount / pageSize));
  }, [detectionCount, pageSize]);

  return (
    <Container>
      <PageHeaderWrapper>
        <PageHeader icon='ProductLogs' title={t('intrusionDetection')} areaTitle={t('detectionLogs')} areaHref='#' introductionText={t('introText')} />
      </PageHeaderWrapper>

      <FilterWrapper>
        {dropdownConfig &&
          <FilterBar
            searchersConfig={[]}
            dropdownsConfig={dropdownConfig}
            datePickersConfig={datePickers}
            filtersTitle={t('Common:filter.filters') + ':'}
            resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
            resultsDateFormat={'yyyy/MM/dd HH:mm'}
            totalResults={rowData.length === 1 && rowData[0].columns.length === 0 ? 0 : rowData.length}
            onChangeCallback={onChangeFilterValue}
          />}
      </FilterWrapper>

      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
        isLoading={isLoading}
        emptyTableText={t('Common:noDetectionsFound')}
        loadingText={t('Common:loadingData')}
        sortCallback={sortCallback}
        hasThumbnail
        hasHeaderGroups
        hasTypeIcon
        defaultAscending={ selectedFilterValues.sortDirection === 'asc' ? true : false }
      />

      {rowData.length !== 0 &&
        <Pagination
          pageSizeOptions={sizeOptions}
          totalPages={totalPages}
          defaultPage={currentPage}
          defaultPageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          pageSizeLabel={pageSizeLabel}
          pageLabel={pageLabel}
        />}

    </Container>
  );
};

export default IntrusionDetection;