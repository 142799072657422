import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';

const Container = styled.div``;
const Title = styled.div`
  color: hsl(210, 6%, 47%);
  font-size: 20px;
`;
const SubTitle = styled.div`
  color: hsl(200, 1%, 49%);
  font-size: 14px;
  margin: 28px 0 40px 0;
`;

const StyledButton = styled(Button)`
  margin-right: 5px;
`;

const ButtonsGroup = styled.div``;

const BottomGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxGroup = styled.div`
  display: flex;
`;
const CheckboxText = styled.div`
  margin-left: 5px;
`;


interface IEverythingOffModal {
  relayController?: string
  onCancel?: () => void
  onAllOff?: (showAgain: boolean) => void
}

const EverythingOffModal: React.FC<IEverythingOffModal> = ({
  relayController,
  onCancel = () => { },
  onAllOff = () => { }
}) => {
  const { t } = useTranslation(['RelayController', 'Common']);
  const [isChecked, setIsChecked] = useState(false);
  const { setModalOpen } = useModal();

  const onChangeCallback = useCallback((checked: boolean) => {
    setIsChecked(checked);
  }, []);


  const handleCancel = useCallback(() => {
    onCancel();
    setModalOpen(false);
  }, [onCancel, setModalOpen])

  const handleConfirm = useCallback(() => {
    onAllOff(isChecked)
    setModalOpen(false);
  }, [isChecked, onAllOff, setModalOpen])

  return (
    <Container>
      <Title>{`${t('modal.title')} ${relayController ? `: ${relayController}` : ''}`}</Title>
      <SubTitle>{t('modal.subtitle')}</SubTitle>
      <BottomGroup>
        <CheckboxGroup>
          <Checkbox checked={isChecked} {...{ onChangeCallback }} />
          <CheckboxText>{t('modal.dontShow')}</CheckboxText>
        </CheckboxGroup>
        <ButtonsGroup>
          <StyledButton design='secondary' onClick={handleCancel}>{t('Common:cancel')}</StyledButton>
          <Button design='danger' onClick={handleConfirm}>{t('allOff')}</Button>
        </ButtonsGroup>
      </BottomGroup>
    </Container>
  );
};

export default EverythingOffModal;