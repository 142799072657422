import { INodeOptionSet, IPipelineAction, IPipelineCondition, IPipelineTrigger } from '.';
import { IPipelineActionRelationLink } from '.';
import ConditionNodes, { INodeConditionSubTypes } from './Definitions/ConditionNodes';
import TriggerNodes, { INodeTriggerSubTypes } from './Definitions/TriggerNodes';
import ActionNodes, { INodeActionSubTypes } from './Definitions/ActionNodes';
import cloneDeep from 'lodash.clonedeep';
import { IActionLayout, IConditionLayout, ITriggerLayout } from '../../hooks/useLogicGroup';



interface AddTriggerAction {
  type: 'add'
  index?: number;
}
interface RemoveTriggerAction {
  type: 'remove'
  index?: number;
}
interface ClearTriggerAction {
  type: 'clear'
  index?: number;
}
interface UpdateTriggerAction {
  type: 'update'
  index?: number;
  config: INodeOptionSet[];
  overview: string;
  configFields?: (number|string|boolean)[];
}
interface SetConfigTriggerAction {
  type: 'set_config'
  index?: number;
  config: INodeOptionSet[];
}

interface SetEditingTriggerAction {
  type: 'set_editing'
  editing: boolean;
  index?: number;
}
interface SetTemplateTriggerAction {
  type: 'set_template'
  nodeSubType: INodeTriggerSubTypes
  index?: number;
}
interface LoadTriggerAction {
  type: 'load'
  state: ITriggerLayout[]
  index?:number
}

export type IReducerActionForTriggers =
  | AddTriggerAction
  | RemoveTriggerAction
  | UpdateTriggerAction
  | SetEditingTriggerAction
  | SetTemplateTriggerAction
  | SetConfigTriggerAction
  | ClearTriggerAction
  | LoadTriggerAction
;

export const triggersReducer = (state: IPipelineTrigger[], action: IReducerActionForTriggers) => {
  const { type, index = state.length + 1 } = action;
  switch (type) {
    case 'add':
      const newCondition: IPipelineTrigger = { ...TriggerNodes.NewNode, editing: true };
      const newState: IPipelineTrigger[] = [
        ...state,
        ...[newCondition],
        ...state.slice(index)
      ]
      return newState;

    case 'remove':
      return state.filter((a, i) => {
        return i !== index;
      })

    case 'update':
      const { overview, config, configFields} = action;
      return state.map((a, i) => {
        if (i === index) {
          a.overview = overview;
          a.config = config;
          a.data.configFields = configFields;
          a.unsavedNode = false
        }
        return a;
      })

    case 'clear':
      return [];

    case 'set_config': {
        const { config } = action;
        return state.map((a, i) => {
          if (i === index) {
            a.config = cloneDeep(config);
          }
          return a;
        })
      }

    case 'set_editing':
      const { editing } = action;
      return state.map((a, i) => {
        if (i === index) {
          a.editing = editing;
        }
        return a;
      })

    case 'set_template':
      const { nodeSubType } = action;
      return state.map((a, i) => {
        if (i === index) {
          const node = cloneDeep(TriggerNodes[nodeSubType]);
          const {config} = node;
          return { ...node, editing: config?.length ? true : false, unsavedNode: true };
        }
        return a;
      })

    case 'load':
      return action.state.map(({subtype, data})=> {
        const node: IPipelineTrigger = cloneDeep(TriggerNodes[subtype as keyof typeof TriggerNodes]);
        return { ...node, editing: false, data, unsavedNode: false};
    });

    default:
      throw new Error('triggersReducer does not have this action type.');
  }
}

  interface AddActionAction {
    type: 'add'
    index?: number;
  }
  interface ClearActionAction {
    type: 'clear'
    index?: number;
  }
  interface RemoveActionAction {
    type: 'remove'
    index?: number;
  }
  interface UpdateActionAction {
    type: 'update'
    index?: number;
    config: any;
    overview: string;
    configFields?: (number|string|boolean)[];
  }
  interface SetEditingActionAction {
    type: 'set_editing'
    editing: boolean;
    index?: number;
  }
  interface SetEditingReactionsActionAction {
    type: 'set_editing_relations'
    editing: boolean;
    index?: number;
  }
  interface UpdateReactionsActionAction {
    type: 'update_relations'
    index?: number;
    links: IPipelineActionRelationLink[];
  }
  interface SetTemplateActionAction {
    type: 'set_template'
    nodeSubType: INodeActionSubTypes
    index?: number;
  }

  interface LoadActionAction {
    type: 'load'
    state: IActionLayout[]
    index?:number
  }

  export type IReducerActionForActions =
    | AddActionAction
    | RemoveActionAction
    | UpdateActionAction
    | SetEditingActionAction
    | SetEditingReactionsActionAction
    | UpdateReactionsActionAction
    | SetTemplateActionAction
    | ClearActionAction
    | LoadActionAction

export const actionsReducer = (state: IPipelineAction[], action: IReducerActionForActions) => {
  const { type, index = state.length + 1 } = action;
  switch (type) {
    case 'add':
      const newCondition: IPipelineAction = { ...ActionNodes.NewNode, editing: true };
      const newState: IPipelineAction[] = [
        ...state,
        ...[newCondition],
        ...state.slice(index)
      ]
      return newState;

    case 'remove':
      return state.filter((a, i) => {
        return i !== index;
      })

    case 'clear':
      return [];

    case 'update':
      const { overview, config, configFields} = action;
        return state.map((_a, i) => {
        const a : IPipelineAction= cloneDeep(_a);
        if (i === index) {
          a.overview = overview;
          a.config = config ? cloneDeep(config) : config;
          a.data.configFields = configFields ? cloneDeep(configFields) : config;
          a.unsavedNode = false
        }
        return a;
      })

    case 'set_editing': {
      const { editing } = action;

      return state.map((a, i) => {
        if (i === index) {
          a.editing = editing;
        }
        return a;
      })
    }
    case 'set_editing_relations': {
      const { editing } = action;
      return state.map((a, i) => {
        if (i === index && a.relations) {
          a.relations.editing = editing;
        }
        return a;
      })
    }
    case 'update_relations':
      const { links } = action;

      return state.map((_a, i) => {
        const a = cloneDeep(_a);
        if (i === index && links && a.relations) {
          a.data.links = links.filter(({ cameraId, entityId }) => (cameraId !== null && entityId !== null)).map(({ cameraId, entityId })=>({cameraId, entityId }));
        }
        return a;
      })

    case 'set_template':
      const { nodeSubType } = action;
      return state.map((a, i) => {
        if (i === index) {
          const {config} = cloneDeep(ActionNodes[nodeSubType]);
          return { ...ActionNodes[nodeSubType], editing: config ? true : false, unsavedNode: true  };
        }
        return a;
      })

    case 'load':
      return action.state.map(({subtype, data, overview})=> {
        const a:IPipelineAction = ActionNodes[subtype as keyof typeof ActionNodes];
        const action = { ...a, overview, editing: false, data, unsavedNode: false}
        if(action.relations) {
          action.relations = {...action.relations, editing: false}
        }
        return action;
      })


    default:
      throw new Error('actionsReducer does not have this action type.');
  }
}


interface AddConditionsAction {
  type: 'add'
  index?: number;
}
interface RemoveConditionsAction {
  type: 'remove'
  index?: number;
}
interface UpdateConditionsAction {
  type: 'update'
  index?: number;
  config: any;
  configFields?: (number|string|boolean)[];
  overview: string;
}
interface SetConfigConditionAction {
  type: 'set_config'
  index?: number;
  config: INodeOptionSet[];
}
interface SetEditingConditionsAction {
  type: 'set_editing'
  editing: boolean;
  index?: number;
}
interface SetTemplateConditionsAction {
  type: 'set_template';
  nodeSubType: INodeConditionSubTypes;
  index?: number;
  externalData:{[key:string]: any[]}
}
interface ClearConditionsAction {
  type: 'clear'
  index?: number;
}

interface LoadConditionsAction {
  type: 'load'
  state: IConditionLayout[]
  index?:number
  externalData:{[key:string]: any[]}
}

export type IReducerActionForConditions =
  | AddConditionsAction
  | RemoveConditionsAction
  | UpdateConditionsAction
  | SetEditingConditionsAction
  | SetTemplateConditionsAction
  | ClearConditionsAction
  | SetConfigConditionAction
  | LoadConditionsAction

export const conditionsReducer = (state: IPipelineCondition[], action: IReducerActionForConditions) => {
  const { type, index = state.length + 1 } = action;
  switch (type) {
    case 'add':
      const newCondition: IPipelineCondition = { ...ConditionNodes.NewNode, editing: true };
      const newState: IPipelineCondition[] = [
        ...state,
        ...[newCondition],
        ...state.slice(index)
      ]
      return newState;

    case 'remove':
      return state.filter((a, i) =>  (i !== index))

    case 'clear':
      return [];

    case 'update':
      const { overview, config, configFields=[]} = action;

      return state.map((a, i) => {
        if (i === index) {
          a.overview = overview;
          a.config = config ? cloneDeep(config) : config;
          a.data.configFields = configFields ? cloneDeep(configFields) : config;
          a.unsavedNode = false;
        }
        return a;
      })

    case 'set_config': {
      const { config } = action;
      return state.map((a, i) => {
        if (i === index) {
          a.config = cloneDeep(config);
        }
        return a;
      })
    }

    case 'set_editing':
      const { editing } = action;

      return state.map((a, i) => {
        if (i === index) {
          a.editing = editing;
        }
        return a;
      })

    case 'set_template': {
      const { nodeSubType , externalData} = action;

      return state.map((a, i) => {
        if (i === index) {
          const node = cloneDeep(ConditionNodes[nodeSubType]);
          const config = configTemplateBuilder(node,externalData);
          return { ...node, config, editing: config ? true : false, unsavedNode: true };
        }
        return a;
      })
    }

    case 'load': {
      const {externalData, state} = action;
      return state.map(({subtype, data})=> {
        const node = cloneDeep(ConditionNodes[subtype as keyof typeof ConditionNodes]);
        const config = configTemplateBuilder(node,externalData);

        return { ...node, config, editing: false, data, unsavedNode: false}
      });
    }

    default:
      throw new Error('conditionsReducer does not have this action type.');
  }
}

const configTemplateBuilder = ({config, configTemplate, configTemplateSource} :IPipelineCondition, externalData: {[key: string]: any[]}) => {
  if(configTemplate && configTemplateSource && externalData && externalData[configTemplateSource] && externalData[configTemplateSource].length){
    const items = externalData[configTemplateSource];
    const ruleValueKey = configTemplate.rule_value;
    const labelKey = configTemplate.label;
    return items.map(item => ({...configTemplate, rule_value: item[ruleValueKey], label: item[labelKey]} as INodeOptionSet));
  } else {
    return config;
}}