import { useCallback, useState } from 'react';

export interface IEmailTemplates {
  id: number,
  name: string,
  subject: string
  body: string
}

export interface IEmailTemplatesPayload extends Partial<IEmailTemplates> {
  id?: number
}

interface IEmailTemplatesResponse {
  email_templates: IEmailTemplates[],
  message: string
}


export const useEmailTemplates = () => {
  const [emailTemplates, setEmailTemplates] = useState<IEmailTemplates[]>();
  const [emailTemplatesLoading, setEmailTemplatesLoading] = useState<boolean>(true);
  const [emailTemplatesErrorMessage, setEmailTemplatesErrorMessage] = useState('');

  const fetchEmailTemplates = useCallback(async () => {
    setEmailTemplatesLoading(true);
    setEmailTemplatesErrorMessage('');
    try {
      const response = await fetch('/api/email_template');

      if (response.ok) {
        const { email_templates } = await response.json() as IEmailTemplatesResponse;
        setEmailTemplates(email_templates);
      } else {
        console.error(`EmailTemplates fetch error: ${response.statusText}`);
        setEmailTemplatesErrorMessage(`EmailTemplates fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setEmailTemplatesErrorMessage(error.message);
      } else {
        console.error(error);
        setEmailTemplatesErrorMessage('Unknown Error fetching email templates');
      }
    } finally {
      setEmailTemplatesLoading(false);
    }
  }, []);

  const deleteEmailTemplate = useCallback(async (id: number) => {
    try {
      const res = await fetch(`/api/email_template/${id}`, {
        method: 'DELETE',
      });
      if (!res.ok) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const updateEmailTemplate = useCallback(async (payload: IEmailTemplatesPayload, method: string) => {
    setEmailTemplatesErrorMessage('');
    try {
      const response = await fetch(method === 'POST' ? '/api/email_template' : `/api/email_template/${payload.id}`, {
        method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        await response.json() as IEmailTemplatesResponse;
        // setEmailTemplate(emailTemplate);
        return true;
      } else {
        console.error(`EmailTemplates update error: ${response.statusText}`);
        setEmailTemplatesErrorMessage(`EmailTemplates update error: ${response.statusText}`);
        return false;
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setEmailTemplatesErrorMessage(error.message);
      } else {
        console.error(error);
        setEmailTemplatesErrorMessage('Unknown Error updating emailTemplates');
      }
      return false;
    }
  }, []);


  return {
    emailTemplates,
    emailTemplatesLoading,
    emailTemplatesErrorMessage,
    actions: {
      fetchEmailTemplates,
      updateEmailTemplate,
      deleteEmailTemplate
    }
  }
}