import React, { DragEvent, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';

const Divider = styled.div`
  grid-area: v-divider;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.divider};
  position: relative;
  overflow: visible;
  cursor: col-resize;
  height: 100%;
  width: 100%;
  :after {
    opacity: 0;
    display: block;
    content: '<>';
    border-radius: 50%;
    background-color: hsla(0deg, 0%, 0%, 50%);
    position: absolute;
    top:50%;
    height: 24px;
    width: 24px;
    left: -12px;
    text-align:center;
    line-height: 24px;
    z-index: 700;
  }
  &:hover { 
    :after {
      opacity:1;
      transition:opacity .3s;
    }
  }
`;

interface Props {
  setWidth: (previousValue: number) => void;
  currentWidth: number;
  onDragEnd?: () => void
}
const VerticalDivider: React.FC<Props> = ({
  setWidth,
  currentWidth,
  onDragEnd = () => { }
}) => {
  const mouseStartingRef = useRef<number>(0);
  const startingWidthRef = useRef<number>(0);

  const onDragStart = useCallback((e: DragEvent<HTMLDivElement>) => {
    const newDiv = document.createElement('div');
    e.dataTransfer.setDragImage(newDiv, 0, 0);
    mouseStartingRef.current = e.nativeEvent.pageX;
    startingWidthRef.current = currentWidth;
  }, [currentWidth]);

  const onDrag = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (mouseStartingRef.current && e.nativeEvent.pageX) {
      const moved = mouseStartingRef.current - e.nativeEvent.pageX;
      setWidth(startingWidthRef.current + moved);
    }
  }, [setWidth])

  const throttledDragHandler = useMemo(
    () => throttle(onDrag, 200)
    , [onDrag]);

  return (
    <Divider draggable='true' onDrag={throttledDragHandler} onDragStart={onDragStart} onDragEnd={onDragEnd} />
  );
}

export default VerticalDivider;
