import React, { useCallback } from 'react';
import styled from 'styled-components';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import CameraMark from '../atoms/CameraMark';
import { Camera } from '../../../hooks/useCameras';
import { CameraAlertCount } from '../../../hooks/useAlertCount';
import { useMapHover } from '../../../hooks/useMapHover';
import { LAYER } from '../atoms/MapZoomListener';
import { cameraViewZIndex, cameraZIndex } from '../mapSetup';
import { Pane } from 'react-leaflet';
import CameraAngle from '../atoms/CameraAngle';

const Container = styled.div``;

const CameraViewPane = styled(Pane)`
  z-index: ${cameraViewZIndex};
`;
const CameraPane = styled(Pane)`
  z-index: ${cameraZIndex};
`;

interface ICameraMarks {
  cameras: Camera[];
  alertCounts: CameraAlertCount
};

const CameraMarks: React.FC<ICameraMarks> = ({ cameras, alertCounts }) => {
  const { getParam, updateParam } = useSelected();
  const { updateCameraHover } = useMapHover();
  const layer = getParam(DASHBOARD_PARAMS.layer);
  const zoneId = parseInt(getParam(DASHBOARD_PARAMS.zoneId));

  const onHoverMark = useCallback((cameraId: number, zoneId: number, locationId: number) => {
    updateCameraHover({ cameraId, zoneId, locationId });
  }, [updateCameraHover])

  const onMarkLeave = useCallback(() => {
    updateCameraHover(null);
  }, [updateCameraHover])

  const onClickMark = useCallback((id) => {
    updateParam(DASHBOARD_PARAMS.tab, 'camera');
    updateParam(DASHBOARD_PARAMS.cameraId, id);
  }, [updateParam])

  return (
    <Container>
      {(layer === LAYER.cameras) && (
        <>
          <CameraPane name='camera-pane'>
            {
              cameras.filter(({zone_id}) => zone_id === zoneId).map(({ position_x, position_y, id, zone_id, location_id }) => (
                <CameraMark
                  key={id}
                  latlngPos={[-position_y, position_x]}
                  totalAlerts={alertCounts[id] || 0}
                  onClick={() => onClickMark(id)}
                  onHover={() => onHoverMark(id, zone_id, location_id)}
                  onMouseLeave={() => onMarkLeave()}
                />
              )
              )
            }
          </CameraPane>
          <CameraViewPane name='camera-view-pane'>
            {cameras.filter(({zone_id}) => zone_id === zoneId).map(({ position_x, position_y, id, orientation }) => (
              <CameraAngle
                key={id}
                latlngPos={[-position_y, position_x]}
                totalAlerts={alertCounts[id] || 0}
                {...{ orientation }}
              />
            ))}
          </CameraViewPane>
        </>
      )}
    </Container>
  );
};

export default CameraMarks;
