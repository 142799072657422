import { useState, useCallback } from 'react';
import { Zone } from './useZones';

interface IZoneResponse {
  zone: Zone
  message: string
}

export const useZone = () => {
  const [zone, setZone] = useState<Zone>();
  const [zoneLoading, setZoneLoading] = useState(false);
  const [zoneErrorMsg, setZoneErrorMsg] = useState('');

  const fetchZone = useCallback(async (zoneID: number) => {
    setZoneLoading(true);
    setZoneErrorMsg('');
    try {
      const response = await fetch(`/api/zone/${zoneID}`)
      if(response.ok){
        const {zone} = await response.json() as IZoneResponse;
        setZone(zone);
      }else{
        console.error(`Zone fetch error: ${response.statusText}`);
        setZoneErrorMsg(`Zone fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setZoneErrorMsg(error.message);
      } else {
        console.error(error);
        setZoneErrorMsg('Unknown Error fetching Zone');
      }
    }
    setZoneLoading(false);
  }, []);

  return {
    zone,
    zoneErrorMsg,
    zoneLoading,
    actions: {
      fetchZone
    }
  }
}