import { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label, SmallInput, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';
import { IFromTime } from '../../../types';

const Container = styled.div``;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: hsl(210, 6%, 47%);
`;

const MessageBox = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: hsl(200, 1%, 49%);
  margin: 28px 0;
`;

const RightButton = styled(Button)`
  margin-top: 28px;
  float: right;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 22px;
`;

const PresetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  label {
    margin-bottom: 0px;
  }
`;

const PresetButton = styled(Button)`
  margin-right: 7px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FromSmallInput = styled(SmallInput)`
  width: 90px;
  label {
    display: grid;
  }
`;

const Colon = styled.div`
  margin: 30px 5px 0 5px;
  font-weight: 600;
`;

interface IStatsTimeRangeModal {
  startTime: IFromTime;
  onChangeCallback: (val: IFromTime) => void;
}

const StatsTimeRangeModal: FC<IStatsTimeRangeModal> = ({ startTime, onChangeCallback }) => {
  const { t } = useTranslation('Dashboard');

  const [fromTime, setFromTime] = useState<IFromTime>(startTime);

  const { setModalOpen } = useModal();

  const onChangeFromTime = useCallback(({ target: { value, id } }) => {
    if (value) {
      if (id === 'hrs') {
        if (Number(value) > 24) { value = 24 };
      } else {
        if (Number(value) > 59) { value = 59 };
      }
    }
    setFromTime((prev: IFromTime) => ({ ...prev, [id]: value }));
  }, []);

  const onUpdate = useCallback(() => {
    onChangeCallback(fromTime);
    setModalOpen(false);
  }, [onChangeCallback, fromTime, setModalOpen]);

  const handleKeyPress = useCallback((event) => {
    if (!/^[0-9]/.test(event.key) || event?.target?.value?.length === 2) {
      event.preventDefault();
    }
  }, []);

  const onClickNow = useCallback(() => {
    setFromTime({ hrs: `${String(new Date().getHours()).padStart(2, '0')}`, mins: `${String(new Date().getMinutes()).padStart(2, '0')}` });
  }, []);

  const onClickReset = useCallback(() => {
    setFromTime({ hrs: '00', mins: '00' });
  }, []);

  return (
    <Container>
      <Title>{t('statistics.statisticsAggregationStartTime')}</Title>
      <MessageBox>{t('statistics.statsTimeRangeMsg')}</MessageBox>

      <InputContainer>

        <FromSmallInput type='number' id='hrs' label={t('statistics.from')} value={fromTime?.hrs} onChange={onChangeFromTime} onKeyPress={handleKeyPress} min={0} max={24} maxLength={2} unit='Hrs' />
        <Colon>:</Colon>
        <FromSmallInput type='number' id='mins' label='&nbsp;' value={fromTime?.mins} onChange={onChangeFromTime} onKeyPress={handleKeyPress} min={0} max={59} maxLength={2} unit='Mins' />

        <PresetsContainer>
          <Label htmlFor='' labelText={t('statistics.presets')} />
          <ButtonsWrapper>
            <PresetButton size='small' design='secondary' onClick={onClickNow}>{t('statistics.now')}</PresetButton>
            <PresetButton size='small' design='secondary' onClick={onClickReset}>{t('statistics.reset')}</PresetButton>
          </ButtonsWrapper>
        </PresetsContainer>
      </InputContainer>

      <RightButton onClick={onUpdate}>{t('statistics.update')}</RightButton>

    </Container>
  );
}

export default StatsTimeRangeModal;