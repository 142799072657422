import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicSearchInput } from 'scorer-ui-kit';
import styled from 'styled-components';
import { CameraAlertCount } from '../../hooks/useAlertCount';
import { RichLocation } from '../../pages/Dashboard';

import LocationDetails from './LocationDetails';

const Container = styled.div`
  ${({ theme }) => theme.styles.global.background};
`;

const BasicSearchInputWrapper = styled.div`
  position: sticky;
  top: 42px;
  z-index: 1;
  & > div {
    border: none;
    border-bottom: 1px solid ${({theme}) =>  theme.colors.divider};
    height: 40px;
    border-radius: 0;
  }
`;

const LocationContainer = styled.div`
`;

const NoLocationsText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.09px;
  color: #767676;
  margin: 15px;
  text-align: center;
  height: 40px;
`;

const getFilteredResults = (richLocations: RichLocation[], searchValue: string, alertCounts: any) => {
  if (searchValue.trim() === '') return richLocations;

  return richLocations.reduce((filteredLocations, current) => {
    let filteredZones = current.zones.filter(({ name }) => {
      return name.toLowerCase().includes(searchValue.toLowerCase())
    });

    if (filteredZones.length > 0) {
      let locationAlerts = 0;
      let totalCameras = 0;

      filteredZones = filteredZones.map(({ cameras, ...rest }) => {
        const zoneAlerts = cameras.reduce((prevResult, { id }) => prevResult += alertCounts[id] || 0, 0);
        locationAlerts += zoneAlerts;
        totalCameras += cameras.length || 0;
        return {
          ...rest,
          cameras,
          zoneAlerts
        }
      });

      filteredLocations.push({
        ...current,
        zones: filteredZones,
        locationAlerts,
        totalCameras
      })
    }

    return filteredLocations;
  }, [] as RichLocation[])

}

interface ILocationTab {
  richLocations: RichLocation[]
  alertCounts: CameraAlertCount
}

const LocationsTab: FC<ILocationTab> = ({ richLocations, alertCounts }) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredResults, setFilteredResults] = useState<RichLocation[]>([]);

  const { t } = useTranslation(['Dashboard']);

  const onChangeSearch = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
  }, []);

  useEffect(() => {
    setFilteredResults(getFilteredResults(richLocations, searchValue, alertCounts));
  }, [richLocations, searchValue, alertCounts]);

  return (
    <Container>
      <BasicSearchInputWrapper>
        <BasicSearchInput placeholder={t('locationsTab.filterByZoneName')} value={searchValue} onChange={onChangeSearch} noBackground hasBorder={false}/>
      </BasicSearchInputWrapper>

      <LocationContainer>
        {filteredResults
          .map((location: RichLocation) => <LocationDetails key={location.id} {...location} />)}

        {filteredResults.length === 0 &&
          <NoLocationsText>{t('locationsTab.noLocationsAvailable')}</NoLocationsText>
        }
      </LocationContainer>
    </Container>
  )
};

export default LocationsTab;