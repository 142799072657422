import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import L, { LatLngTuple, ImageOverlay as IImageOverlay } from 'leaflet';
import { ImageOverlay, useMap } from 'react-leaflet'
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import { Zone } from '../../../hooks/useZones';
import { LAYER } from './MapZoomListener';
import { MapSettings } from '../../../types';
import { getCentroid, getMapBounds, getZoneMap } from '../mapUtils';
import { ZOOM_LAYER0, ZOOM_LAYER2, ZOOM_LAYER3 } from '../mapSetup';
import { Camera } from '../../../hooks/useCameras';
import { Location } from '../../../hooks/useLocations';

const ZoneMapHiddenImage = styled.img`
  display: none;
`;

interface IDashboardOverlay {
  locations: Location[]
  zones: Zone[]
  cameras: Camera[]
  fullMap: HTMLImageElement
  defaultMapSettings: MapSettings
}
const DashboardOverlay: React.FC<IDashboardOverlay> = ({ locations, zones, cameras, fullMap, defaultMapSettings }) => {
  const map = useMap();

  const { getParam } = useSelected();
  const layer = getParam(DASHBOARD_PARAMS.layer);
  const locationId: number | null = parseInt(getParam(DASHBOARD_PARAMS.locationId)) || null;
  const zoneId: number | null = parseInt(getParam(DASHBOARD_PARAMS.zoneId)) || null;
  const cameraId: number | null = parseInt(getParam(DASHBOARD_PARAMS.cameraId)) || null;
  const layerRef = useRef<string | null>(null)
  const zoneMapImgRef = useRef<HTMLImageElement>(null);
  const OverlayRef = useRef<IImageOverlay>(null);


  const setupOverlay = useCallback(() => {

    if (!OverlayRef.current) {
      return;
    }

    const { height, width } = fullMap;
    const fullMapBounds = getMapBounds(height, width);
    const fullMapMaxBounds = L.latLngBounds([height / 2, -width / 2], [-height * 1.5, width * 1.5]);

    /** on mount value not cameras or update after layer 3 was in view */
    if ((layerRef.current === null || layerRef.current === LAYER.cameras) && (layer !== LAYER.cameras)) {
      OverlayRef.current.setUrl(fullMap.src);
      OverlayRef.current.setBounds(fullMapBounds);
      map.setMaxBounds(fullMapMaxBounds);
    }

    /** when browser has query params for camera layer width or without cameraId */
    if ((layer === LAYER.cameras) && zoneId && zoneMapImgRef.current) {

      const foundZone = zones.find(({ id }) => id === zoneId);
      if (foundZone) {
        const coordinates: LatLngTuple[] = foundZone.points?.map(({ x, y }) => ([-y, x]))
        const zoneBounds = new L.LatLngBounds(coordinates);
        OverlayRef.current.setUrl(foundZone.image);
        OverlayRef.current.setBounds(zoneBounds);
        map.setMaxBounds(zoneBounds);

        const foundCamera = cameras.find((camera) => camera.id === cameraId)
        if (foundCamera) {
          const { position_x = 0, position_y = 0 } = foundCamera;
          map.setView([-position_y, position_x], ZOOM_LAYER3)
        } else {
          const coordinates: LatLngTuple[] = foundZone.points?.map(({ x, y }) => ([-y, x]))
          const [lat, lng] = getCentroid(coordinates);
          map.setView([lat, lng], ZOOM_LAYER3);
        }
      }

    } else if (layer === LAYER.zones && locationId && cameraId === null) { /** when browser has query params for location */
      const foundLocation = locations.find(({ id }) => id === locationId);
      if (foundLocation) {
        const coordinates: LatLngTuple[] = foundLocation.points?.map(({ x, y }) => ([-y, x]))
        const [lat, lng] = getCentroid(coordinates);
        map.setView([lat, lng], ZOOM_LAYER2);
      }
    } else if (layer === LAYER.locations && !locationId) { /** When in menu the Dashboard/home icon is clicked */
      map.setView(defaultMapSettings.center, ZOOM_LAYER0);
    }

    layerRef.current = layer;

  }, [cameraId, cameras, defaultMapSettings.center, fullMap, layer, locationId, locations, map, zoneId, zones])

  useEffect(() => {
    setupOverlay();
  }, [cameraId, locationId, zoneId, layer, setupOverlay])

  return (
    <>
      {(layer === LAYER.cameras) && zoneId && zones && <ZoneMapHiddenImage ref={zoneMapImgRef} src={getZoneMap(zoneId, zones)} />}

      {/* Default values that will be replaced in use effect depending layer */}
      <ImageOverlay ref={OverlayRef} url={fullMap.src} bounds={defaultMapSettings.bounds} />
    </>
  );
};

export default DashboardOverlay;