import React, { ChangeEvent, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { LineUI, LineReducer, LineSetContext, TextField, Label, useNotification, ButtonWithLoading, IconButton, ButtonWithIcon } from 'scorer-ui-kit';
import { IPointSet, IVector2, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ICamConfigPayload, ICameraConfig, ILineConfig, useCameraConfig } from '../../hooks/useCameraConfig';
import { useParams } from 'react-router-dom';
import { IParams } from '../../types';
import { getDetectionTypeLocal, regexOnlyNumbers } from '../../utils';
import { Camera } from '../../hooks/useCameras';
import LineNameEdit from './LineNameEdit';

const Container = styled.div`
  display: flex;
  margin: 30px 0 20px 0;
`;

const Title = styled.div`
  margin-bottom: 40px;
`;

const LeftSection = styled.div`
  min-width: 500px;
`;

const RightSection = styled.div`
  width: 100%;
  > div, img {
    max-height: 500px;
  }
`;

const TextFieldWrapper = styled.div`
  max-width: 400px;
`;

const DefinedAreaGroup = styled.div`
  margin-top: 30px;
`;

const AreaValue = styled.div`
  color: #acb4bc;
  margin-bottom: 20px;
`;

const FieldsGroup = styled.div`
  margin: 20px 0 40px 0;
`;

const LineDescription = styled.div`
  font-size: 12px;
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
`;

const LinePoints = styled.div``;
const LineInfo = styled.div``;
const LineActions = styled.div``;

const NoImage = styled.div`
  background: #dddddd;
  height: 400px;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.divider};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HiddenImage = styled.img`
  display: none;
`;

const generatePointsText = (points: IVector2[]): string => {
  return points.reduce((result, value, index) => {
    return result + `(${value.x},${value.y}) ${index === (points.length - 1) ? '' : ','}`
  }, '');
}

const getLineConfig = (state: IPointSet[]): ILineConfig[] => {
  return state.map((pointSet) => {
    const newLine: ILineConfig = { name: pointSet.name || '', points: pointSet.points }
    return newLine
  })
}

const defaultLinePoints = [
  {
    x: 100,
    y: 100
  },
  {
    x: 200,
    y: 200
  }
]

const defaultShapePoints = [
  {
    x: 100,
    y: 100
  },
  {
    x: 100,
    y: 200
  },
  {
    x: 200,
    y: 200
  },
  {
    x: 200,
    y: 100
  },
]


const isPolygon = (algorithm: string): boolean => {
  switch (algorithm) {
    case 'NUMBER_PLATE':
    case 'FACE_IDENTIFICATION':
    case 'INTRUSIONS':
      return true

    case 'CAR_COUNTING':
    case 'PEOPLE_COUNTING':
    default:
      return false
  }
}

interface Props {
  camera?: Camera
}

const CameraConfigTab: React.FC<Props> = ({ camera }) => {
  const { t } = useTranslation(['CameraDetails', 'Common']);
  const [threshold, setThreshold] = useState('');
  const [state, dispatch] = useReducer(LineReducer, []);
  const { cameraID }: IParams = useParams();
  const { actions: { getCameraConfigsByCameraID, updateCameraConfig, createCameraConfig } } = useCameraConfig();
  const { sendNotification } = useNotification();
  const [isSaving, setIsSaving] = useState(false);
  const [hasImageLoad, setHasImageLoad] = useState(false);
  const [initialConfig, setInitialConfig] = useState<ICameraConfig | null>(null)
  const { detection_types = null } = camera || {}

  const imgRef = useRef<HTMLImageElement>(null);

  const IMG_URL = `/snapshot/api/v1/stacks/RTSPcam${cameraID}/snapshot?timestamp=${Math.ceil(Date.now()/1000)}`;

  // Testing image
  // const IMG_URL = 'https://i.picsum.photos/id/1026/4621/3070.jpg?hmac=OJ880cIneqAKIwHbYgkRZxQcuMgFZ4IZKJasZ5c5Wcw';

  const [options, setOptions] = useState<LineUIOptions>({
    showSetIndex: false,
    pointIndexOffset: 1,
    showPointLabel: true,
    setIndexOffset: 1,
    showMoveHandle: true,
    showPointHandle: true,
    boundaryOffset: 0,
    showDirectionMark: false
  });


  const setupLines = useCallback(async () => {
    if (cameraID && detection_types) {
      const configs = await getCameraConfigsByCameraID(parseInt(cameraID)) || [];
      const config = configs.find(({ algorithm_type }) => algorithm_type === detection_types);
      if (config) {
        const state = config.line_config.map((line) => {
          return { readOnly: false, styling: 'primary', name: line.name, points: line.points }
        });

        setThreshold(config.confidence_threshold.toString());
        setOptions((prev) => ({ ...prev, showDirectionMark: !isPolygon(detection_types) }))
        setInitialConfig(config);
        dispatch({
          type: 'LOAD',
          state
        });

      } else {
        // base empty
        setOptions((prev) => ({ ...prev, showDirectionMark: !isPolygon(detection_types) }))

        dispatch({
          type: 'LOAD',
          state: []
        });

      }
    }

  }, [cameraID, detection_types, getCameraConfigsByCameraID])

  const isValidForSave = useCallback((): boolean => {

    const camera_id = parseInt(cameraID);
    const confidence_threshold = parseInt(threshold);


    if (confidence_threshold === 0) {
      sendNotification({ type: 'error', message: t('configTab.thresholdError') })
      return false;
    }

    let allHaveName = true;
    state.forEach((pointSet) => {
      if (!pointSet.name) {
        allHaveName = false;
      }
    })

    if (!allHaveName) {
      sendNotification({ type: 'error', message: t('configTab.noNameError') })
      return false;
    }


    if ((typeof camera_id !== 'number') || (typeof confidence_threshold !== 'number') || detection_types === null) {
      return false;
    }

    return true;
  }, [cameraID, detection_types, sendNotification, state, t, threshold])

  const handleSave = useCallback(async () => {
    const camera_id = parseInt(cameraID);
    const confidence_threshold = parseInt(threshold);

    const valid = isValidForSave();
    if (!valid) {
      return;
    }

    if (detection_types === null) {
      return;
    }

    setIsSaving(true);
    const line_config: ILineConfig[] = getLineConfig(state);

    const newConfig: ICamConfigPayload = {
      camera_id,
      line_config,
      confidence_threshold,
      algorithm_type: detection_types
    }

    if (initialConfig) {
      const result = await updateCameraConfig(initialConfig.id, newConfig);
      if (result) {
        sendNotification({ type: 'success', message: t('configTab.updateSuccess') })
      } else {
        sendNotification({ type: 'error', message: t('configTab.updateError') })
      }
    } else {
      const config = await createCameraConfig(newConfig);
      if (config) {
        sendNotification({ type: 'success', message: t('configTab.newConfigSaved') })
        if (config) {
          setInitialConfig(config);
        }
      } else {
        sendNotification({ type: 'error', message: t('configTab.newConfigFailed') })
      }
    }

    setIsSaving(false);
  }, [cameraID, createCameraConfig, detection_types, initialConfig, isValidForSave, sendNotification, state, t, threshold, updateCameraConfig]);

  const handleImageLoad = useCallback(async () => {
    if (!imgRef.current) {
      return;
    }
    const fixedImgDimensions = {
      x: imgRef.current.naturalWidth,
      y: imgRef.current.naturalHeight
    }
    setOptions((prev) => ({ ...prev, fixedImgDimensions }))
    setHasImageLoad(true);
  }, [])

  useEffect(() => {
    setupLines()
  }, [setupLines]);

  const handleThreshold = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (value.match(regexOnlyNumbers) || value === '') {
      setThreshold(value);
    }
  }, [])

  const removeSet = useCallback((index = 0) => {
    dispatch({
      type: 'REMOVE_SET',
      index
    });
  }, []);

  const addSet = useCallback(() => {
    const points = detection_types && isPolygon(detection_types) ? defaultShapePoints : defaultLinePoints;
    const last = state.length + 1;
    dispatch({
      type: 'ADD_SET',
      data: {
        name: `${t('configTab.areaName')} ${last}`,
        points
      }
    });
  }, [detection_types, state.length, t]);

  const updateName = useCallback((index: number, name: string) => {

    dispatch({
      type: 'RENAME_SET',
      index,
      data: {
        name,
      }
    })

  }, [])

  const generateAreaComponents = useCallback(() => {
    return state.map((pointSet, index) => (
      <LineDescription key={index}>
        <LineInfo>
          <LineNameEdit {...{ index }} name={pointSet.name} onSave={updateName} />
          <LinePoints>{generatePointsText(pointSet.points)}</LinePoints>
        </LineInfo>
        <LineActions>
          <IconButton icon='Delete' size={24} color='dimmed' hoverColor='danger' onClick={() => removeSet(index)} />
        </LineActions>
      </LineDescription>
    ))
  }, [removeSet, state, updateName])

  return (
    <Container>
      <LeftSection>
        {detection_types && <Title>{getDetectionTypeLocal(detection_types)}</Title>}
        <FieldsGroup>
          <TextFieldWrapper>
            <TextField
              fieldState="default"
              label={t('configTab.threshold')}
              name="threshold"
              value={threshold}
              onChange={handleThreshold}
            />
          </TextFieldWrapper>
          <DefinedAreaGroup>
            <Label htmlFor='definedArea' labelText={t('configTab.definedArea')} />
            <AreaValue>{state && state[0]?.points ? generateAreaComponents() : '-'}</AreaValue>
            {detection_types && <ButtonWithIcon
              icon='Add'
              position='left'
              size='small'
              design='secondary'
              onClick={addSet}
            >{isPolygon(detection_types) ? t('configTab.addShape') : t('configTab.addLine')}
            </ButtonWithIcon>}
          </DefinedAreaGroup>
        </FieldsGroup>
        <ButtonWithLoading loading={isSaving} disabled={!hasImageLoad || threshold === ''} onClick={handleSave}>{t('configTab.saveConfig')}</ButtonWithLoading>
      </LeftSection>
      <RightSection>
        <HiddenImage ref={imgRef} src={IMG_URL} onLoad={handleImageLoad} />
        {hasImageLoad ?
          (<LineSetContext.Provider value={{ state, dispatch }}>
            <LineUI
              options={options}
              src={IMG_URL}
            />
          </LineSetContext.Provider>)
          : <NoImage>{t('configTab.noImage')}</NoImage>
        }
      </RightSection>
    </Container>
  );
};

export default CameraConfigTab;