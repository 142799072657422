import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { INodeTargets, IPipelineActionRelationLink } from '..';

export const MetaLabel = styled.div`
  font-family: ${({theme}) => theme.fontFamily.data};
  font-weight: 600;
  color: hsl(194, 10%, 52%);
  text-transform: titlecase;
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 5px;
`
export const MetaValue = styled.div`
  font-family: ${({theme}) => theme.fontFamily.data};
  font-weight: 500;
  color: hsl(194, 10%, 52%);
  text-transform: titlecase;
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 5px;
`

interface INodeSectionDetailsRelations {
  type: INodeTargets;
  index: number;
  links: IPipelineActionRelationLink[]
}


const NodeSectionDetailsRelations : React.FC<INodeSectionDetailsRelations> = ({ index, links=[]}) => {
  const { t } = useTranslation(['Common']);

  return (
    <>
      <MetaLabel>{t('eventRules.details')}</MetaLabel>
      {links.length > 0 ?
        links.map((link:any, relIndex: number) => {
          return (
            <div key={'a-' + index + '-r-' + relIndex}>
              <MetaValue>{t('eventRules.camID')}{link.cameraId} {'->'} {t('eventRules.relayID')}{link.entityId}</MetaValue>
            </div>
          )
        })
      : <MetaValue>{t('eventRules.noneSet')}</MetaValue>}

    </>
  )

}

export default NodeSectionDetailsRelations;