import * as React from 'react';

function SvgFullscreen(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M1 8.5v-6A1.5 1.5 0 012.5 1h6M23.5 8.5v-6A1.5 1.5 0 0022 1h-6M1 16v6a1.5 1.5 0 001.5 1.5h6M23.5 16v6a1.5 1.5 0 01-1.5 1.5h-6" />
        <rect x={5.5} y={5.5} width={13.5} height={13.5} rx={1.5} />
      </g>
    </svg>
  );
}

export default SvgFullscreen;
