import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFileButton, PageHeader, Content, Icon, ITabIcon, TabsWithIconBar, useModal, Spinner, useNotification, useTo } from 'scorer-ui-kit';
import { ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import Encoding from 'encoding-japanese';
import styled from 'styled-components';
import RejectedTable from '../components/upload-csv/RejectedTable';
import UploadTable from '../components/upload-csv/UploadTable';
import { useNumberPlateImport } from '../hooks/useNumberPlateImport';
import { NumberPlate } from '../hooks/useNumberPlates';
const SAMPLE_FILE = '/sample-file.csv';

const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 250px);
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BottomSection = styled.div`
  margin: 20px 0;
`;

const DownloadLink = styled.a`
  font-size: 14;
  color: ${({ theme }) => theme.typography.pageHeader.mainTitle.color};
  :hover {
    color: hsl(205, 100%, 65%);
  }
`;

const UploadTitle = styled.div`
  margin: 40px 10px 20px 0;
  font-size: 14;
  font-weight: 600;
  color: ${({ theme }) => theme.typography.pageHeader.mainTitle.color};
`;

const EntriesBox = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;

  padding: 5px 15px;
  border: 1px solid ${({ theme }) => theme.colors.divider};
`;

const EntriesData = styled.div`
  display: flex;
  margin: 5px 0;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  > div {
    display: flex;
    align-items: center;
  }
`;

const getValidType = (type: string) => {
  const upperCaseType = type.toUpperCase();
  if (upperCaseType === 'LISTED') {
    return 'LISTED'
  }

  return 'IMPORTANT';
}

interface IValidRejectedPlates {
  platesList: NumberPlate[]
  rejectList: string[]
  csvText: string
}

const validateHeaders = (headers: string[]) => {
  const [name, description, type, user_type, kana, number, area] = headers;

  if (name !== 'name') {
    return false;
  }

  if (description !== 'description') {
    return false;
  }

  if (type !== 'type') {
    return false;
  }

  if (user_type !== 'user_type') {
    return false;
  }

  if (kana !== 'kana') {
    return false;
  }

  if (number !== 'number') {
    return false;
  }

  if (area !== 'area') {
    return false;
  }

  return true;
}

const dataToNumberPlates = (data: string): IValidRejectedPlates => {
  const platesList: NumberPlate[] = [];
  const rejectList: string[] = []
  const [h, ...rows] = data.replace(/\r\n|\r/g, '\n') // CRLF => LF
                           .replace(/^\uFEFF/gm, '') //remove UTF BOM chars
                           .replace(/^\u00BB\u00BF/gm,'') //remove UTF BOM chars
                           .split('\n');
  const headers = h.split(new RegExp(',(?=(?:[^"]*"[^"]*")*[^"]*$)')).map((value) => value.toLowerCase());
  if (!validateHeaders(headers)) {
    return { platesList, rejectList, csvText: 'invalidHeaders' }
  }

  const validRows: string[] = [];
  rows.forEach((row: string, index: number) => {

    if (row.length === 0) {
      return;
    }

    const values = row.split(new RegExp(',(?=(?:[^"]*"[^"]*")*[^"]*$)'));

    const hasEmptyValues = values.some((value) => value === '');
    if (hasEmptyValues) {
      rejectList.push(`${index + 1} - ${row}`);
      return
    }
    const [name, description, number_plate_type, user_type, kana, number, area ] = values;

    validRows.push(row);

    const newPlate: NumberPlate = {
      id: index,
      name,
      description,
      type: getValidType(number_plate_type),
      user_type: `${user_type}`,
      kana,
      number,
      area,
      category: '',
      tags: '',
      last_detected: undefined
    }
    platesList.push(newPlate);
  });

  const csvText = headers.join(',') +
    '\n' +
    validRows.join('\n');

  return {
    platesList,
    rejectList,
    csvText
  };
}

const getPlatesList = (newFiles: FileList): Promise<IValidRejectedPlates> => {
  return new Promise((resolve, reject) => {
    const newFile = newFiles[0];
    const reader = new FileReader();

    reader.onabort = reject;
    reader.onerror = reject;

    reader.onload = e => {
      if (e === null || e.target === null || e.target.result === null || !(e.target.result instanceof ArrayBuffer)) {
        reject('invalidFile');
        return;
      } else {

        const { result } = e.target;
        if (result.byteLength === 0) {
          reject('emptyFile');
          return;
        }

        const codes = new Uint8Array(result);
        const detectedEncoding = Encoding.detect(codes);
        if(detectedEncoding === false){
          reject('invalidFileEncoding')
          return;
        }
        const output = Encoding.convert(codes, {
          to: 'UNICODE',
          from: detectedEncoding,
          type: 'string'
        });

        const { platesList, rejectList, csvText } = dataToNumberPlates(output)

        if (csvText === 'invalidHeaders') {
          reject('invalidHeaders');
          return;
        }

        resolve({ platesList, rejectList, csvText });
      }
    };

    reader.readAsArrayBuffer(newFile);
  });
}

export const INITIAL_ROWS: ITypeTableData = [
  {
    columns: []
  }
];

const UploadCSV: React.FC = () => {
  const [platesList, setPlatesList] = useState<NumberPlate[]>([]);
  const [rejectList, setRejectList] = useState<string[]>([]);
  const { t } = useTranslation(['NumberPlates', 'Common']);
  const { isLoading, actions: { uploadFile } } = useNumberPlateImport();
  const { createModal } = useModal();
  const { sendNotification } = useNotification()
  const to = useTo();

  const handleInput = useCallback(async (newFiles: FileList) => {

    try {
      const { platesList: newPlates, rejectList: newRejected, csvText: newText } = await getPlatesList(newFiles)


      if (newPlates.length > 0 && newText.length > 0) {
        const hasUpload = await uploadFile(newText);
        if (!hasUpload) {
          sendNotification({ type: 'error', message: t('errorUploading') })
        } else {
          sendNotification({ type: 'success', message: t('platesUploaded'), actionTextButton: t('Common:finish'), isPinned: true, onTextButtonClick: to('/number-plates')})
          setPlatesList(newPlates);
          setRejectList(newRejected);
        }
      } else if (newPlates.length === 0 && newRejected.length > 0) {
        sendNotification({ type: 'error', message: t('platesNotUploaded') })
        setPlatesList(newPlates);
        setRejectList(newRejected);
      } else if (newPlates.length === 0 && newRejected.length === 0) {
        sendNotification({ type: 'error', message: t('emptyFile') })
      }

    } catch (error) {
      createModal({ customComponent: <h3>{t(`${error}`)}</h3> })
    }
  }, [createModal, sendNotification, t, to, uploadFile]);

  const tabList: ITabIcon[] = [
    {
      icon: 'Success',
      title: t('newEntries'),
      subtitle: `${platesList.length} ${t('dataEntries')}`,
      tabFor: 'goodData',
      customComponent: (platesList.length > 0) ? <UploadTable {...{ platesList }} /> : <></>
    },
    {
      icon: 'Warning',
      title: t('rejectedEntries'),
      subtitle: `${rejectList.length} ${t('dataEntries')}`,
      tabFor: 'wrongData',
      customComponent: (rejectList.length > 0) ? <RejectedTable {...{ rejectList }} /> : <></>
    }
  ]


  return (
    <Container>
      <TopSection>
        <PageHeader icon='FileTypesCsv' title={t('numberPlateUpload')} introductionText={t('csvPlateDescription')} />
        {platesList.length === 0
          ? (
            <InputFileButton
              inputCallback={handleInput}
              text={t('selectCSVFile')}
              accept='text/csv'
            />)
          : (!isLoading && (
            <EntriesBox>
              <EntriesData>
                <IconWrapper>
                  <Icon icon='Success' size={16} />
                </IconWrapper>
                {`${platesList.length} ${t('entries')}`}
              </EntriesData>
              <EntriesData>
                <IconWrapper>
                  <Icon icon='Warning' size={16} />
                </IconWrapper>
                {`${rejectList.length} ${t('rejectedEntries')}`}
              </EntriesData>
            </EntriesBox>
          )

          )
        }
      </TopSection>
      {isLoading
        ? (
          <LoadingWrapper>
            <Spinner size='xlarge' styling='primary' />
          </LoadingWrapper>
        )
        : (
          (platesList.length + rejectList.length) === 0 ?
            <BottomSection>
              <DownloadLink href={SAMPLE_FILE} download>{t('downloadSample')}</DownloadLink>
            </BottomSection>
            : (
              <BottomSection>
                <UploadTitle>{t('dataEntries')}</UploadTitle>
                <TabsWithIconBar defaultTabId='goodData' paddingLeft='0px' {...{ tabList }} />
              </BottomSection>
            )
        )}

    </Container>
  );
};

export default UploadCSV;