import React, { useCallback } from 'react';
import styled from 'styled-components';
import MapLocation from '../atoms/MapLocation';
import { Location } from '../../../hooks/useLocations';
import { Camera } from '../../../hooks/useCameras';
import { useMap } from 'react-leaflet';
import { CameraAlertCount } from '../../../hooks/useAlertCount';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import { useMapHover } from '../../../hooks/useMapHover';
import { LAYER } from '../atoms/MapZoomListener';
import { ZOOM_LAYER2 } from '../mapSetup';
import { LatLngTuple } from 'leaflet';
import { getCentroid } from '../mapUtils';


const Container: React.FC = styled.div``;

interface IMapLocations {
  locations: Location[]
  cameras: Camera[]
  alertCounts: CameraAlertCount
};

const MapLocations = ({ locations, cameras, alertCounts }: IMapLocations) => {
  const map = useMap();
  const { getParam, updateParam } = useSelected();

  const locationParam = getParam(DASHBOARD_PARAMS.locationId);
  const selectedLocation: null | number = locationParam ? parseInt(locationParam) : null;
  const layer = getParam(DASHBOARD_PARAMS.layer);
  const { updateLocationHover } = useMapHover();

  const handleAreaClick = useCallback((locationId: number) => {

    const currentLoc = locations.find(({ id }) => locationId === id)
    if (currentLoc) {
      const coords: LatLngTuple[] = currentLoc.points.map(({ x, y }) => ([-y, x]));
      const [lat, lng] = getCentroid(coords);
      map.setView([lat, lng], ZOOM_LAYER2)
    } else {
      map.setZoom(ZOOM_LAYER2);
    }

    updateParam(DASHBOARD_PARAMS.locationId, locationId);

  }, [locations, map, updateParam]);

  const handleHover = useCallback((id: number) => {
    if (layer === LAYER.locations) {
      updateLocationHover(id);
    }
  }, [layer, updateLocationHover]);

  const handleMouseLeave = useCallback(() => {
    updateLocationHover(null);
  }, [updateLocationHover])

  return (
    <Container>
      {
        locations.map(({ points, id, name }) => (
          <MapLocation
            key={id}
            {...{ id, name, alertCounts, layer, points }}
            isSelected={selectedLocation === id}
            coordinates={points.map(({ x, y }) => ([-y, x]))}
            cameras={cameras.filter(({ location_id }) => id === location_id)}
            onClick={() => handleAreaClick(id)}
            onHover={() => handleHover(id)}
            onMouseLeave={handleMouseLeave}
          />)
        )
      }
    </Container>
  );
};

export default MapLocations;