import * as React from 'react';

function SvgPersonWhitelist(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <circle cx={6.25} cy={4} r={3} />
        <path d="M10.805 11.138A5.251 5.251 0 001 13.75V16h2.25L4 23.5h4.5l.323-3.233M23.5 17.5a6 6 0 11-6-6" />
        <path d="M23.5 12.999l-5.47 5.47a.749.749 0 01-1.06 0l-1.72-1.719" />
      </g>
    </svg>
  );
}

export default SvgPersonWhitelist;
