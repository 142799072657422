import React from 'react';
import styled from 'styled-components';
import { Icon } from 'scorer-ui-kit';

const Container = styled.div`
  width: 24px;
  height: 24px;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: inherit;
  height: inherit;

  background-color: hsl(207, 82%, 98%);
  box-shadow: 0 2px 4px 0px hsla(0, 0%, 0%, 0.051);
  border: 1px solid rgb(198, 214, 225);
  border-radius: 50%;
  line-height: 0;
  cursor: pointer;

  &:hover {
  }

`

interface INodeButton {
  icon: string;
  onClick: any
}

const NodeButton : React.FC<INodeButton> = ({icon, onClick}) => {
  return (
    <Container>
      <Button {...{onClick}}><Icon color='dimmed' size={12} {...{icon}} /></Button>
    </Container>
  )
}

export default NodeButton;