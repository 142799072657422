import { useState, useCallback } from 'react';
import { Points } from '../types';

export interface Location {
  id: number;
  name: string;
  points: Points[]
}

interface LocationResponse {
  locations: Location[],
  limit: number,
  message: string; // "Succeeded",
  offset: number; //0,
  total_record_count: number;
}
interface FetchLocationOptions {
  limit?: number;
  offset?: number;
}
export const useLocations = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [locationCount, setLocationCount] = useState(0);

  const fetchLocations = useCallback(async (options?: FetchLocationOptions) => {
    const { limit = 100, offset = 0 } = (options || {});
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await fetch(`/api/location?limit=${limit}&offset=${offset}`);
      if (response.ok) {
        const {
          locations = [],
          total_record_count
        } = await response.json() as LocationResponse;
        setLocations(locations);
        setLocationCount(total_record_count);
      } else {
        console.error(`Locations fetch error: ${response.statusText}`);
        setErrorMessage(`Locations fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setErrorMessage(error.message);
      } else {
        console.error(error);
        setErrorMessage('Unknown Error fetching Locations.');
      }
    }
    setLoading(false);
  }, []);

  return {
    locations,
    locationCount,
    loading,
    errorMessage,
    actions: {
      fetchLocations
    }
  }
}

