import { useState, useCallback } from 'react';
import { AlertTypes } from '../context/AlertsContext';

export interface Camera {
  id: number;
  name: string;
  details: string; //"This is a camera 1",
  detection_types: 'CAR_COUNTING'|'NUMBER_PLATE';
  alert_type: AlertTypes,
  tags: string; //"Project1,tag2",
  category: string; //"Shopping1",
  position_x: number; //999,
  position_y: number; //999;

  zone_id: number;
  zone_name: string;

  meta: { [key: string]: string }
  orientation: number;

  location_id: number;
  location_name: string;
}

interface CameraResponse {
  cameras: Camera[],
  limit: number,
  message: string; // "Succeeded",
  offset: number; //0,
  total_record_count: number;
}
interface FetchCamerasOptions {
  limit?: number;
  offset?: number;
  tags?: string;
}
export const useCameras = () => {
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [cameraCount, setCameraCount] = useState(0);

  const fetchCameras = useCallback(async (options?: FetchCamerasOptions) => {
    const { limit = 100, offset = 0, tags} = (options || {});
    setLoading(true);
    setErrorMessage('');
    try {

      const searchString = new URLSearchParams({
        limit: '' + limit,
        offset: '' + offset,
        ...(tags ? { tags } : {}),

      });

      const response = await fetch(`/api/camera?${searchString}`)
      if (response.ok) {
        const {
          cameras = [],
          total_record_count
        } = await response.json() as CameraResponse;
        setCameras(cameras);
        setCameraCount(total_record_count);
        setLoading(false);
        return cameras;
      } else {
        console.error(response.statusText);
        setErrorMessage(response.statusText);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setErrorMessage(error.message);
      } else {
        console.error(error);
        setErrorMessage('Unknown Error fetching Cameras.');
      }
    }
    setLoading(false);
  }, []);

  return {
    cameras,
    cameraCount,
    loading,
    errorMessage,
    actions: {
      fetchCameras
    }
  }
}

