import { useCallback, useEffect, useRef, useState } from 'react';
import { Spinner } from 'scorer-ui-kit';
import { io } from 'socket.io-client';
import styled, { css } from 'styled-components';
import VideoStreamButton from './VideoStreamButton';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const Controls = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
`
const CenterControls = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // margin-left: 60px;
`
const PlayButton = styled(VideoStreamButton)<{paused: boolean}>`
  border-radius: 50%;
  height: 27px;
  width: 27px;
  ${({paused})=> paused ?
    css`
      border: solid 2px hsl(0, 0%, 65.1%);
      svg path { stroke:  hsl(0, 0%, 65.1%);}
    `
    :
    css`
      border: solid 2px hsl(0, 0%, 100%);
      svg path { stroke:  hsl(0, 0%, 100%);}
    `
  };
  background-color: hsla(228, 35%, 8%, 0.3);
  /* padding: 7px 3px 7px 7px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
`;
const FullScreenButton = styled(VideoStreamButton)`
`


const ImageLoadingContainer = styled.div`
  background-image:linear-gradient(to bottom, hsl(205,12%,24%), hsl(205,14%,19%) 96%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const noImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAQAAACRI2S5AAAAEElEQVR42mNkIAAYRxWAAQAG9gAKqv6+AwAAAABJRU5ErkJggg==';
async function readImage(imageData: string): Promise<string> {
  if (!imageData) {
    return noImage;
  }
  else {
    return new Promise((resolve,_reject)=>{
      const reader = new FileReader();
      reader.addEventListener('load',()=>{
        if (reader.result === null) resolve(noImage);
        resolve(reader.result as string);
      });
      reader.addEventListener('error',()=>{
        // reject(reader.error);
        //alternatively we could just ignore and NOOP
        console.error('Error reading Image', reader.error)
        resolve(noImage);
      });
      reader.readAsDataURL(new Blob([imageData], { type: 'image/jpeg' }));
    });
  }
};

interface Props {
  url: string|null;
  streamMatch?: RegExp;
}
const VideoStream: React.FC<Props> = ({url: path, streamMatch =/.*/ }) => {
  const [imgSrc, setImgSrc] = useState(noImage);
  const imgRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [paused, setPaused] = useState(false);

  useEffect(()=>{
    if(!path || paused === true) {
      setLoading(false);
      return
    };
    setLoading(true);
    const vidSocket = io('/video-frame', {path});

    vidSocket.on('message', async(payload: {image: {data?: string}}) => {
      const {image: {data=''}={}} = payload;
      const imgString = await readImage(data);
      setImgSrc(imgString);
      data && setLoading(false);
    });

    vidSocket.on('update-stream-ids', async(streams: string[]) => {
      const streamID = streams.find(streamID => streamID.match(streamMatch) !== null);
      streamID !== undefined && vidSocket.emit('subscribe-stream', [streamID]);
    });

    vidSocket.emit('update-stream-ids');

    console.debug('start stream', path);
    return ()=>{
      vidSocket.close();
      console.debug('closed stream', path);
    }
  },[path, paused, streamMatch]);

  useEffect(()=>{
    setPaused(false);
  },[path]);

  const toggleFullScreen = useCallback(() => {
    if (imgRef.current && document.fullscreenElement == null) {
      imgRef.current.requestFullscreen();
    } else if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }
  }, []);

  const togglePlay = useCallback(() => {
    setPaused((paused)=> !paused);
  }, []);




  return (
    <Container ref={imgRef} >
      {
        loading && <ImageLoadingContainer><Spinner size='medium' styling='secondary' /></ImageLoadingContainer>
      }
      <Controls>
        <CenterControls>
          <PlayButton icon='Play' paused={paused} color={paused ? 'dimmed' : 'mono'} size={13} onClick={togglePlay}/>
        </CenterControls>
        <FullScreenButton icon='Fullscreen' onClick={toggleFullScreen} size={13}></FullScreenButton>
      </Controls>
      <Image src={imgSrc} />
    </Container>
  );
};

export default VideoStream;