import { useState, useCallback } from 'react';
import { Camera } from './useCameras';

interface CameraResponse {
  camera: Camera,
  message: string; // "Succeeded",
}

export const useCamera = () => {
  const [camera, setCamera] = useState<Camera>();
  const [cameraLoading, setCameraLoading] = useState(false);
  const [cameraErrorMessage, setcameraErrorMessage] = useState('');

  const fetchCamera = useCallback(async (cameraID: number) => {
    setCameraLoading(true);
    setcameraErrorMessage('');
    try {
      const response = await fetch(`/api/camera/${cameraID}`)
      if (response.ok) {
        const { camera } = await response.json() as CameraResponse;
        setCamera(camera);
      } else {
        console.error(`Error fetching Camera:  ${response.statusText}`);
        setcameraErrorMessage(`Error fetching Camera:  ${response.statusText}`);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setcameraErrorMessage(error.message);
      } else {
        console.error(error);
        setcameraErrorMessage('Unknown Error fetching Camera.');
      }
    }
    setCameraLoading(false);
  }, []);

  const updateCamera = useCallback(async (payload: Camera) => {
    let errorMessage = '';
    try {
      // This API is failing D:
      const response = await fetch('/api/camera/' + payload.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
      if (!response.ok) {
        console.error(`Failed to update camera: ${response.statusText}`);
        errorMessage = `Failed to update camera: ${response.statusText}`;
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        errorMessage = 'Failed to update camera';
      } else {
        console.error(error);
        errorMessage = 'Failed to update camera';
      }
    }
    return ({ errorMessage })
  }, []);

  return {
    camera,
    cameraLoading,
    cameraErrorMessage,
    actions: {
      fetchCamera,
      updateCamera
    }
  }
}

