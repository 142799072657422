import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { GlobalUI } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import menu from './pages/menu.json';

// Pages
import Dashboard from './pages/Dashboard';
import KnownVehiclesDetection from './pages/KnownVehiclesDetection';
import IntrusionDetection from './pages/IntrusionDetection';
import KnownPeopleDetection from './pages/KnownPeopleDetection';

import CameraDetails from './pages/CameraDetails';
import AlertsProvider from './context/AlertsContext';
import EditCamera from './pages/EditCamera';
import NumberPlates from './pages/NumberPlates';
import AddEditNumberPlate from './pages/AddEditNumberPlate';
import UploadCSV from './pages/UploadCSV';
import AddEditRelayController from './pages/AddEditRelayController';
import Login from './pages/Login';
import Cameras from './pages/Cameras';
import RelayController from './pages/RelayController';
import RelayLogicGroups from './pages/RelayLogicGroups';
import AddEditRelayLogicGroup from './pages/AddEditRelayLogicGroup';
import People from './pages/People';
import AddEditPerson from './pages/AddEditPerson';

const logoMark = '/logo-mark.svg';
const logoText = '/logo-text.svg';

const Container = styled.div`
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0; */
`;

interface IMenuTop {
  items: IMenuItemTop[]
}

interface IMenuItemTop {
  title: string
  icon?: string,
  href?: string
  submenu?: IMenuItemSubmenu[]
}

interface IMenuItemSubmenu {
  title: string
  href?: string
}

export interface ITheme {
  isDarkThemeEnabled: boolean,
  onChangeTheme: () => void
}

const App: React.FC<ITheme> = ({ isDarkThemeEnabled, onChangeTheme }) => {
  const [menus, setMenus] = useState<IMenuTop>({ items: [] });
  const { t } = useTranslation(['Common']);
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);

  const checkLoggedIn = useCallback(async () => {
    const response = await fetch('/api/authenticated');
    if (response.ok) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    checkLoggedIn();
  }, [checkLoggedIn]);

  const onLanguageChange = useCallback(() => {
    const language = i18n.language === 'ja' ? 'en' : 'ja';
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, []);

  const translateMenus = useCallback(() => {
    const data = menu.items.map((item: IMenuItemTop) => {
      if (item.submenu && item.submenu.length > 0) {
        const submenu = item.submenu.map((submenu: IMenuItemSubmenu) => {
          return { ...submenu, title: t(submenu.title) };
        });
        return { ...item, title: t(item.title), submenu: submenu };
      } else {
        return { ...item, title: t(item.title) };
      }
    });
    return { items: [...data] };

  }, [t]);


  useEffect(() => {
    setMenus(translateMenus());
  }, [translateMenus]);

  const login = useCallback(async ({ username, password }) => {
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password })
    });

    if (response.ok) {
      setLoggedIn(true);
    } else {
      const { message = 'Error Logging in.' } = await response.json();
      throw new Error(message);
    }
  }, []);


  const logout = useCallback(async () => {
    const response = await fetch('/api/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      setLoggedIn(false);
      window.location.reload();
    } else {
      const { message = 'Error Logging out.' } = await response.json();
      console.error(message);
    }
  }, [])

  return (
    <Router>
      <Container>
        {
          (loggedIn === true) &&
          <GlobalUI
            loggedInUser={''}
            content={menus}
            home='/'
            defaultMenuOpen={false}
            maxWidth='100%'
            paddingOverride='0px'
            hasLanguage
            onLanguageToggle={onLanguageChange}
            {...{
              logoMark,
              logoText
            }}
            hasLogout={true}
            onLogout={logout}
            logoutLink='/'
            logoutText={t('logout')}
            hasCurrentUser={false}
            canAlwaysPin={true}
            hasSwitchTheme
            isLightMode={!isDarkThemeEnabled}
            switchThemeText={t('theme.switchTheme')}
            selectedThemeText={isDarkThemeEnabled ? t('theme.darkMode') : t('theme.lightMode')}
            onThemeToggle={onChangeTheme}
            selectedLanguageText={t(`theme.${i18n.language}`)}
          >
            <Switch>
              <AlertsProvider>
                <Route path='/' exact component={Dashboard} />
                <Route path='/cameras' exact component={Cameras} />
                <Route path='/detection/known-vehicles' exact component={KnownVehiclesDetection} />
                <Route path='/detection/intrusion' exact component={IntrusionDetection} />
                <Route path='/detection/known-people' exact component={KnownPeopleDetection} />
                <Route path='/cameras/:cameraID' exact component={CameraDetails} />
                <Route path='/cameras/:cameraID/edit' exact component={EditCamera} />
                <Route path='/number-plates' exact component={NumberPlates} />
                <Route path='/number-plates/add' exact component={AddEditNumberPlate} />
                <Route path='/number-plates/:plateID/edit' exact component={AddEditNumberPlate} />
                <Route path='/number-plates/csv-upload' exact component={UploadCSV} />
                <Route path='/relay-controller/add' exact component={AddEditRelayController} />
                <Route path='/relay-controller/:relayControllerID/edit' exact component={AddEditRelayController} />
                <Route path='/relay-controller' exact component={RelayController} />
                <Route path='/event-pipeline' exact component={RelayLogicGroups} />
                <Route path='/event-pipeline/add' exact component={AddEditRelayLogicGroup} />
                <Route path='/event-pipeline/:groupID/edit' exact component={AddEditRelayLogicGroup} />
                <Route path='/people' exact component={People} />
                <Route path='/people/add' exact component={AddEditPerson} />
                <Route path='/people/:personID/edit' exact component={AddEditPerson} />
              </AlertsProvider>
            </Switch>
          </GlobalUI>
        }
        {
          (loggedIn === false) && <Login onLogin={login} />
        }
      </Container>
    </Router>
  )
};


export default App;
