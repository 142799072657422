import React, { useCallback } from 'react';
import { useMap } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';
import { Camera } from '../../../hooks/useCameras';
import ZoneInfo from './ZoneInfo';
import { CameraAlertCount } from '../../../hooks/useAlertCount';
import { getTotalAlerts } from '../../../utils';
import ZonePolygon from './ZonePolygon';
import { ZOOM_LAYER3 } from '../mapSetup';

interface IMapZone {
  coordinates: LatLngTuple[]
  cameras: Camera[]
  alertCounts: CameraAlertCount
  layer: string
  locationName?: string
  zoneName?: string
  onClick?: () => void
  onHover?: () => void
  onMouseLeave?: () => void
}

const MapZone: React.FC<IMapZone> = ({
  coordinates,
  cameras,
  alertCounts,
  locationName = '',
  zoneName = '',
  onClick = () => {},
  onHover,
  onMouseLeave,
}) => {

  const map = useMap();

  /* Todo this will be behavior will be different since next zoom is Layer 3 */
  const handleClick = useCallback(() => {
    map.setZoom(ZOOM_LAYER3)
    onClick();
  }, [map, onClick])

  const hasAlerts = getTotalAlerts(cameras, alertCounts) > 0;

  return (
    <>
        <ZonePolygon
          positions={coordinates}
          hasAlerts={hasAlerts}
          onClick={handleClick}
          {...{onHover, onMouseLeave}}
        />
        <ZoneInfo
          bounds={coordinates}
          hasAlerts={hasAlerts}
          {...{ locationName, zoneName }}
        />
    </>
  )
};

export default MapZone;