import React, { useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { animation } from '../../../themes/common';
import { Polygon } from 'react-leaflet';
import { LatLngTuple, Polygon as IPolygon} from 'leaflet';

const colorKeyframes = keyframes`
    from {
      fill: #BEDEFF;
      stroke: #3B9BFF;
    }
    to {
      fill: #F3A5A5;
      stroke: #FF1818;
    }
`;

const opacityInKeyframes = keyframes`
    from {
      fill-opacity: 45%;
      stroke-opacity: 65%;
    }
    to {
      fill-opacity: 75%;
      stroke-opacity: 100%;
    }
`;

const opacityOutKeyframes = keyframes`
  from {
    fill-opacity: 44.22%;
    stroke-opacity: 64.22%;
  }
  to {
    fill-opacity: 65%;
    stroke-opacity: 75%;
  }
`;

const opacityDisappearKeyframes = keyframes`
  from {
    fill-opacity: 44.22%;
    stroke-opacity: 64.22%;
  }
  to{
    fill-opacity: 0%;
    stroke-opacity: 0%;
  }
`

const scaleKeyframes = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.25);
  }
`;

const pulseKeyframes = keyframes`
  0% {
    fill-opacity: 75%;
    stroke-opacity: 100%;
  }
  50% {
    fill-opacity: 45%;
    stroke-opacity: 65%;
  }
  100% {
    fill-opacity: 75%;
    stroke-opacity: 100%;
  }
`;

const alertColorAnim = css`${colorKeyframes} 0.5s ${animation.easing.primary.inOut}`;
const alertOpacityInAnim = css`${opacityInKeyframes} 0.5s ${animation.easing.primary.in}`;
const alertOpacityOutAnim = css`${opacityOutKeyframes} 0.5s ${animation.easing.primary.in} 0.5s`;
const alertPulseAnim = css`${pulseKeyframes} 1.5s ${animation.easing.primary.in} 1s 9`;
const alertScaleAnim = css`${scaleKeyframes} 1s ${animation.easing.primary.inOut}`;
const alertDisappearAnim = css`${opacityDisappearKeyframes} 2s ${animation.easing.primary.out} 0.5`

const alertStyles = css`
  fill: #F3A5A5;
  stroke: #FF1818;
  transform: scale(1);
`;

const StyledPolygon = styled(Polygon)`
  fill: #BEDEFF;
  fill-opacity: 75%;
  stroke: #3B9BFF;

  &.alerts {
    ${alertStyles};
    fill-opacity: 75%;
    animation: ${alertColorAnim}, ${alertOpacityInAnim}, ${alertOpacityOutAnim}, ${alertPulseAnim};
  }

  &.grow {
    ${alertStyles};
    animation: ${alertScaleAnim}, ${alertDisappearAnim};
  }

  &.selected {
    stroke-dasharray: 6;
    fill: none;
  }

  &:hover {
    fill-opacity: 90%;
  }
`;

interface ILocationPolygon {
  positions: LatLngTuple[]
  hasAlerts: boolean
  isSelected?: boolean
  triggerAlerts?: boolean
  onClick?: () => void
  onHover?: () => void
  onMouseLeave?: () => void
}

const LocationPolygon: React.FC<ILocationPolygon> = ({
  positions,
  hasAlerts,
  isSelected = false,
  triggerAlerts = false,
  onClick = () => { },
  onHover = () => { },
  onMouseLeave = () => { }
}) => {
  const styledPolygonRef = useRef<IPolygon>(null);

  useEffect(()=>{
    if(!styledPolygonRef.current) return;
    const ele = styledPolygonRef.current.getElement();
    if(!ele) return;
    ele.classList.toggle('selected',isSelected);
  },[isSelected]);

  useEffect(()=>{
    if(!styledPolygonRef.current) return;
    const ele = styledPolygonRef.current.getElement() as SVGPathElement;
    if(!ele) return;
    if(hasAlerts){
      ele.classList.remove('alerts');
      // This forces reflow so animation re-triggers...we use this later to calculate origin
      const {x,y,height,width} = ele.getBBox();
      ele.classList.add('alerts');
      const dupe = ele.cloneNode(true);
      const parent = ele.parentElement;
      //this bit clones the path element to add the grow animation while leaving the original in place.
      if(dupe && parent) {

        const appended = parent.appendChild(dupe) as SVGPathElement;

        //manually calculate transform origin
        appended.setAttribute('transform-origin', `${width/2 + x} ${height/2 +y}`)

        appended.addEventListener('animationend',({animationName})=>{
          if(animationName === opacityDisappearKeyframes.getName()){
            appended.remove();
          }
        });
        appended.classList.add('grow');
      }
    } else {
      ele.classList.remove('alerts');
    }
  },[hasAlerts, triggerAlerts]);

  return (
    <StyledPolygon
      ref={styledPolygonRef}
      {...{ positions }}
      eventHandlers={{
        click: () => {
          onClick();
        },
        mouseover: () => {
          onHover();
        },
        mouseout: () => {
          onMouseLeave();
        }
      }}
    />
  );
};

export default LocationPolygon;