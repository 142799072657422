
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import ClearDropdown from '../atoms/ClearDropdown';
import VideoStream from '../atoms/VideoStream';
import { useTranslation } from 'react-i18next';
import { IFilterItem } from 'scorer-ui-kit';
import VideoStreamButton from '../atoms/VideoStreamButton';

const Container = styled.div`
  width: 226px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;

const LiveControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const FullScreenButton = styled(VideoStreamButton)`
  position: absolute;
  bottom: 10px;
  right: 12px;
`;


interface IMediaViewer {
  videoUrl?: string
  imageUrl: string
  cameraID: number|null
}

const MediaViewer: React.FC<IMediaViewer> = ({ videoUrl = null, imageUrl, cameraID}) => {
  const { t } = useTranslation(['Common']);
  const [isLive, setIsLive] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null)

  const handleSelect = useCallback((selection: IFilterItem) => {
    setIsLive(selection.value === 'live' ? true : false);
  }, [])

  const liveOptions: IFilterItem[] = [
    { text: t('live'), value: 'live' },
    { text: t('snapshot'), value: 'snapshot' }
  ]

  const toggleFullScreen = useCallback(() => {
    if (imgRef.current && document.fullscreenElement == null) {
      imgRef.current.requestFullscreen();
    } else if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }
  }, []);

  return (
    <Container>
      {
        isLive
          ? <VideoStream url={videoUrl} streamMatch={new RegExp(`${cameraID}$`)} />
          : (
            <ImageWrapper>
              <Image ref={imgRef} src={imageUrl} />
              <FullScreenButton icon='Fullscreen' onClick={toggleFullScreen} size={13}></FullScreenButton>
            </ImageWrapper>
          )
      }
      <LiveControl>
        <ClearDropdown
          icon='Camera'
          buttonText={isLive ? t('live') : t('snapshot')}
          list={liveOptions}
          onSelect={handleSelect}
        />
      </LiveControl>
    </Container>
  );
};

export default MediaViewer;