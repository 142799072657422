import React from 'react';
import { useMapEvents } from 'react-leaflet';
import { Zone } from '../../../hooks/useZones';
import { Location } from '../../../hooks/useLocations';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import { getCenteredZoneId } from '../mapUtils';
import { ZOOM_LAYER_TOP, ZOOM_LAYER0, ZOOM_LAYER1, ZOOM_LAYER2, ZOOM_LAYER3 } from '../mapSetup';

export enum LAYER {
  locations = '1',
  zones = '2',
  cameras = '3',
}

export const getLayer = (zoomVal: number): string => {
  switch (zoomVal) {
    case ZOOM_LAYER_TOP:
    case ZOOM_LAYER0:
    case ZOOM_LAYER1:
      return LAYER.locations;

    case ZOOM_LAYER2:
      return LAYER.zones;

    case ZOOM_LAYER3:
      return LAYER.cameras;

    default:
      return LAYER.cameras;
  }
}

interface IMapListener {
  zones?: Zone[]
  locations?: Location[]
}

export const MapZoomListener: React.FC<IMapListener> = ({ zones }) => {
  const { getParam, updateParam } = useSelected();

  const map = useMapEvents({
    zoomend: () => {
      const zoom = map.getZoom();
      const newLayer = getLayer(zoom)

      updateParam(DASHBOARD_PARAMS.layer, newLayer);

      /** if layer 3 Zooming from controls, not with click, update zoneId with centered element*/
      if (newLayer === LAYER.cameras && zones) {
        const currentZoneId = getParam(DASHBOARD_PARAMS.zoneId);
        if (!currentZoneId) {
          const center = map.getCenter()
          const zoneId = getCenteredZoneId(center, zones);
          if (zoneId) {
            updateParam(DASHBOARD_PARAMS.zoneId, zoneId);
          }
        }
      }

      if (newLayer === LAYER.zones) {
        updateParam(DASHBOARD_PARAMS.cameraId, '');
        updateParam(DASHBOARD_PARAMS.zoneId, '');
      }

      if (newLayer === LAYER.locations) {
        updateParam(DASHBOARD_PARAMS.cameraId, '');
        updateParam(DASHBOARD_PARAMS.zoneId, '');
        updateParam(DASHBOARD_PARAMS.locationId, '');
      }
    },
    click: (e) => {
      console.log(e, 'click point event')
    }
  })

  // No UI in this component
  return null
}