import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Common_en from './locales/en/Common.json';
import Common_ja from './locales/ja/Common.json';

import KnownVehiclesDetection_en from './locales/en/KnownVehiclesDetection.json';
import KnownVehiclesDetection_ja from './locales/ja/KnownVehiclesDetection.json';

import IntrusionDetection_en from './locales/en/IntrusionDetection.json';
import IntrusionDetection_ja from './locales/ja/IntrusionDetection.json';

import KnownPeopleDetection_en from './locales/en/KnownPeopleDetection.json';
import KnownPeopleDetection_ja from './locales/ja/KnownPeopleDetection.json';

import Dashboard_en from './locales/en/Dashboard.json';
import Dashboard_ja from './locales/ja/Dashboard.json';

import CameraDetails_en from './locales/en/CameraDetails.json';
import CameraDetails_ja from './locales/ja/CameraDetails.json';

import EditCamera_en from './locales/en/EditCamera.json';
import EditCamera_ja from './locales/ja/EditCamera.json';

import NumberPlates_en from './locales/en/NumberPlates.json';
import NumberPlates_ja from './locales/ja/NumberPlates.json';

import AddEditNumberPlate_en from './locales/en/AddEditNumberPlate.json';
import AddEditNumberPlate_ja from './locales/ja/AddEditNumberPlate.json';

import PatrolLights_ja from './locales/ja/PatrolLights.json';
import PatrolLights_en from './locales/en/PatrolLights.json';
import Cameras_en from './locales/en/Cameras.json';
import Cameras_ja from './locales/ja/Cameras.json';
import RelayController_en from './locales/en/RelayController.json';
import RelayController_ja from './locales/ja/RelayController.json';

import RelayLogicGroups_en from './locales/en/RelayLogicGroups.json';
import RelayLogicGroups_ja from './locales/ja/RelayLogicGroups.json';

import AddEditRelayLogicGroup_en from './locales/en/AddEditRelayLogicGroup.json';
import AddEditRelayLogicGroup_ja from './locales/ja/AddEditRelayLogicGroup.json';

import People_en from './locales/en/People.json';
import People_ja from './locales/ja/People.json';

import AddEditPerson_en from './locales/en/AddEditPerson.json';
import AddEditPerson_ja from './locales/ja/AddEditPerson.json';


const resources = {
  en: {
    Common: Common_en,
    KnownVehiclesDetection: KnownVehiclesDetection_en,
    IntrusionDetection: IntrusionDetection_en,
    KnownPeopleDetection: KnownPeopleDetection_en,
    Dashboard: Dashboard_en,
    CameraDetails: CameraDetails_en,
    EditCamera: EditCamera_en,
    NumberPlates: NumberPlates_en,
    AddEditNumberPlate: AddEditNumberPlate_en,
    PatrolLights: PatrolLights_en,
    Cameras: Cameras_en,
    RelayController: RelayController_en,
    RelayLogicGroups: RelayLogicGroups_en,
    AddEditRelayLogicGroup: AddEditRelayLogicGroup_en,
    People: People_en,
    AddEditPerson: AddEditPerson_en
  },
  ja: {
    Common: Common_ja,
    KnownVehiclesDetection: KnownVehiclesDetection_ja,
    IntrusionDetection: IntrusionDetection_ja,
    KnownPeopleDetection: KnownPeopleDetection_ja,
    Dashboard: Dashboard_ja,
    CameraDetails: CameraDetails_ja,
    EditCamera: EditCamera_ja,
    NumberPlates: NumberPlates_ja,
    AddEditNumberPlate: AddEditNumberPlate_ja,
    PatrolLights: PatrolLights_ja,
    Cameras: Cameras_ja,
    RelayController: RelayController_ja,
    RelayLogicGroups: RelayLogicGroups_ja,
    AddEditRelayLogicGroup: AddEditRelayLogicGroup_ja,
    People: People_ja,
    AddEditPerson: AddEditPerson_ja
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'ja',
    lng: localStorage.getItem('language') || navigator.language,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    defaultNS: 'Common',
    ns: [
      'Common',
      'KnownVehiclesDetection',
      'IntrusionDetection',
      'KnownPeopleDetection',
      'Dashboard',
      'CameraDetails',
      'EditCamera',
      'NumberPlates',
      'AddEditNumberPlate',
      'Cameras',
      'PatrolLights',
      'RelayController',
      'RelayLogicGroups',
      'AddEditRelayLogicGroup',
      'People',
      'AddEditPerson'
    ]
  });

export default i18n;
