import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  margin: 4px 0;
`;

const Title = styled.div`
  font-size: 10px;
  color: hsl(197, 29%, 71%);
  font-family: ${({theme}) => theme.fontFamily.ui};
`;

const SubInfo = styled.div`
  font-size: 12px;
  color: hsl(196, 10%, 56%);
  font-family: ${({theme}) => theme.fontFamily.data};
`;


interface ICellInformation {
  title: string
  subInfo: string
}
const CellInformation: React.FC<ICellInformation> = ({title, subInfo}) => {
  return(
    <Container>
      <Title>{title}</Title>
      <SubInfo>{subInfo}</SubInfo>
    </Container>
  );
};

export default CellInformation;