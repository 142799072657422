import React from 'react';
import { Button } from 'scorer-ui-kit';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  &:first-child{
    min-width: 62.5px;
  }
  width: 60px;
  &:nth-child(3) {
    width: 67.5px;
  }
`;
const Blank = styled.div`
  &:first-child{
    min-width: 62.5px;
  }
  width: 60px;
  display: inline-block;
`;
const Container = styled.div`
  ${StyledButton}, ${Blank}{
    margin-right: 5px;
  }
`;

interface IButtonAction {
  text: string,
  onClick: () => void
  blank?: false;
}
interface IBlankAction {
  blank: true;
}

export type IAction = IButtonAction|IBlankAction

interface IRowActions {
  actions: IAction[]
}

const RowActions: React.FC<IRowActions> = ({ actions }) => {
  return (
    <Container>
      {
        actions.map((action, index) =>
          action?.blank === true ?
            <Blank key={index}/>
          :
            <StyledButton key={index} design='secondary' size='xsmall' onClick={action.onClick}>
              {action.text}
            </StyledButton>
        )
      }
    </Container>
  );
};

export default RowActions;