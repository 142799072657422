import { FC, useCallback, useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Content,
  PageHeader,
  ButtonWithLoading,
  Button,
  TextField,
  TextAreaField,
  useTo,
  Spinner,
  useNotification
} from 'scorer-ui-kit';
import styled from 'styled-components';
import { useCamera } from '../hooks/useCamera';
import { Camera } from '../hooks/useCameras';
import { useElementTitle } from '../hooks/useElementTitle';
import { EllipsisStyles } from '../Style';

const Container = styled(Content)`
  overflow: inherit;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-Between;
  margin-bottom: 30px;
`;

const PageHeaderLeftContainer = styled.div`
  max-width: 610px;
  a {
    max-width: 180px;
    ${EllipsisStyles}
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const CancelButton = styled(Button)`
  margin-right: 9px;
`;

const FormContainer = styled.div`
  margin-top: 53px;
`;

const SectionTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 16px;
  font-weight: 500;
  color: #5a6269;
  display: flex;

  &:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #eee;
    margin: auto;
    margin-left: 10px;
  }
`;

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 33px;
  justify-content: space-between;
  gap: 20px;
  label {
    flex-grow: 1;
  }
`;

const PositionFieldsRow = styled(FieldsRow)`
  margin-bottom: 62px;
  margin-top: 14px;
  label {
    width: 50%;
  }
  flex-wrap: nowrap;
`;

const DescriptionRow = styled(FieldsRow)`
  margin-top: 14px;
`;

const RightDiv = styled(FieldsRow)`
  margin-top: 0px;
  width: 50%;
  flex-wrap: nowrap;
`;

interface IParams {
  [key: string]: string
}

const EditCamera: FC = () => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [cameraForm, setCameraForm] = useState<Camera>();
  const { camera, cameraLoading, cameraErrorMessage, actions: { fetchCamera, updateCamera } } = useCamera();

  const { t } = useTranslation('EditCamera');
  const { cameraID }: IParams = useParams();
  useElementTitle('a', camera?.name!);

  const to = useTo();
  const { sendNotification } = useNotification();

  const onSave = useCallback(async () => {
    if (cameraForm === undefined) return;
    setUpdateLoading(true);
    const { errorMessage } = await updateCamera(cameraForm);
    if (errorMessage === '') {
      sendNotification({ type: 'success', message: t('success.cameraUpdatedSuccessfully') })
      to(`/cameras/${cameraID}`)();
    } else {
      sendNotification({ type: 'error', message: t('error.failedToUpdateCamera') })
    }
    setUpdateLoading(false);

  }, [cameraForm, updateCamera, cameraID, to, sendNotification, t]);

  useEffect(() => {
    camera && setCameraForm(camera);
  }, [camera]);

  useEffect(() => {
    cameraID && fetchCamera(parseInt(cameraID));
  }, [cameraID, fetchCamera]);

  const onChangeHandler = useCallback(({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    cameraForm && setCameraForm(({ ...cameraForm, [name]: value }));
  }, [cameraForm]);

  useEffect(() => {
    cameraErrorMessage && sendNotification({ type: 'error', message: t('error.failedToFetchCamera') });
  }, [cameraErrorMessage, sendNotification, t])

  return (
    <Container>
      <PageHeaderWrapper>

        <PageHeaderLeftContainer>
          <PageHeader icon='Camera' title={t('editCameraMeta')} areaTitle={cameraForm?.name ?? '-'} areaHref={'/cameras/' + cameraID} introductionText={t('introText')} />
        </PageHeaderLeftContainer>

        <ButtonsWrapper>
          <CancelButton design='secondary' size='small' onClick={to(`/cameras/${cameraID}`)}>{t('cancel')}</CancelButton>
          <ButtonWithLoading loading={updateLoading} size='small' onClick={onSave}>{t('save')}</ButtonWithLoading>
        </ButtonsWrapper>

      </PageHeaderWrapper>
      {cameraLoading || cameraErrorMessage !== '' ?
        <Spinner size='large' styling='primary' /> :

        <FormContainer>
          <SectionTitle>{t('basicDetails')}</SectionTitle>

          <FieldsRow>
            <TextField
              name='camera-name'
              label={t('cameraName')}
              fieldState='disabled'
              value={camera?.name}
            />
            <TextField
              name='location'
              label={t('location')}
              fieldState='disabled'
              value={camera?.location_name}
            />
          </FieldsRow>

          <PositionFieldsRow>
            <TextField
              name='zone'
              label={t('zone')}
              fieldState='disabled'
              value={camera?.zone_name}
            />
            <RightDiv>
              <TextField
                name='position-x'
                label={t('positionX')}
                fieldState='disabled'
                value={camera?.position_x}
              />

              <TextField
                name='position-y'
                label={t('positionY')}
                fieldState='disabled'
                value={camera?.position_y}
              />
            </RightDiv>

          </PositionFieldsRow>

          <SectionTitle>{t('metaData')}</SectionTitle>

          <FieldsRow>
            <TextField
              name='category'
              label={t('category')}
              fieldState='default'
              value={cameraForm?.category}
              onChange={onChangeHandler}
            />
            <TextField
              name='tags'
              label={t('tags')}
              fieldState='default'
              value={cameraForm?.tags}
              onChange={onChangeHandler}
            />
          </FieldsRow>

          <DescriptionRow>
            <TextAreaField
              name='details'
              label={t('description')}
              fieldState='default'
              rows={6}
              value={cameraForm?.details}
              onChange={onChangeHandler}
              maxLength={256}
            />
          </DescriptionRow>
        </FormContainer>}

    </Container>
  )
};

export default EditCamera;