import * as React from 'react';

function SvgVehicleIntrusion(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M3.203 6.358L4.952 2.46A2.251 2.251 0 017.061 1h8.882c.939-.001 1.78.58 2.109 1.46l1.751 3.9M1.752 12.8v1.7a1.5 1.5 0 103 0V13M4.377 9.25a.375.375 0 00-.375.375M4.752 9.625a.375.375 0 00-.375-.375M4.377 10a.375.375 0 00.375-.375M4.002 9.625c0 .207.168.375.375.375" />
        <path d="M22 11l-.4-3.25a3 3 0 00-2.6-1.5H4a3 3 0 00-3 3v2.25A1.5 1.5 0 002.5 13H12M17.5 20.5a.375.375 0 100 .75.375.375 0 000-.75h0M17.5 18.25v-3" />
        <path d="M23.312 20.932a1.774 1.774 0 01-1.587 2.568h-8.451a1.774 1.774 0 01-1.587-2.568l4.226-8.452a1.774 1.774 0 013.173 0l4.226 8.452z" />
      </g>
    </svg>
  );
}

export default SvgVehicleIntrusion;
