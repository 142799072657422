import { useCallback, useState } from 'react';
import { IRelay } from './useRelays';

export interface IRelayController {
  id: number
  name: string
  address: string
  snmp_community_string: string
  relays: IRelay[]
}

interface IRelayControllersResponse {
  message: string
  relay_controllers: IRelayController[]
  info: {
    total: number
    limit: number
    offset: number
  }
}

interface IRelayControllerResponse {
  message: string
  RelayController: IRelayController
}

interface IFetchLightsOptions {
  limit?: number;
  offset?: number;
}

export interface IRelayInfo {
  id?: number;
  name: string
  port: number
  duration?: number
  active: boolean
}

export interface IRelayControllerUpdate {
  id: number;
  name: string
  address: string
  snmp_community_string: string,
  relays: IRelayInfo[]
}

export interface IRelayControllerInfo {
  name: string
  address: string
  snmp_community_string: string,
  relays: IRelayInfo[]
}
export type IRelayStatus = 0|1;
export interface IRelayStatusMap {
  [port : string]: IRelayStatus;
}
export interface IRelayControllerStatusMap {
  [relayControllerID: string]: IRelayStatusMap;
}

export const useRelayController = () => {
  const [relayControllers, setRelayControllers] = useState<IRelayController[]>([]);
  const [relayControllersStatusMap, setRelayControllersStatusMap] = useState<IRelayControllerStatusMap>({});
  const [relayControllersTotal, setRelayControllersTotal] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchRelayControllers = useCallback(async (options?: IFetchLightsOptions): Promise<boolean> => {
    setIsLoading(true);

    const {
      limit = 100,
      offset = 0,
    } = (options || {});


    const searchString = new URLSearchParams({
      limit: '' + limit,
      offset: '' + offset
    });

    const url = '/api/relay-controller?' + searchString.toString();

    try {
      const response = await fetch(url)

      if (response.ok) {
        const {
          relay_controllers,
          info: {
            total
          }
        } = await response.json() as IRelayControllersResponse

        setRelayControllers(relay_controllers);
        setRelayControllersTotal(total)

      } else {
        return false;
      }
      return true;

    } catch (error) {
      console.error('error', error)
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [])

  const addRelayController = useCallback(async (payload: IRelayControllerInfo): Promise<boolean> => {
    setIsLoading(true);

    try {
      const res = await fetch('/api/relay-controller', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!res.ok) {
        return false;
      }

      return true;

    } catch (error) {
      return false;

    } finally {
      setIsLoading(false);
    }

  }, [])

  const getRelayControllerById = useCallback(async (id: string): Promise<IRelayController | null> => {
    setIsLoading(true);

    try {
      const response = await fetch(`/api/relay-controller/${id}`)

      if (response.ok) {
        const { RelayController } = await response.json() as IRelayControllerResponse;
        return RelayController;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    } finally {
      setIsLoading(false)
    }

  }, []);

  const updateRelayController = useCallback(async (payload: IRelayControllerUpdate): Promise<boolean> => {
    setIsLoading(true);

    try {
      const { relays,...rest} = payload;
      const {id,...relayControllerBody} = rest;
      const res = await fetch(`/api/relay-controller/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(relayControllerBody)
      });

      if (!res.ok) {
        return false
      }
      let hasError =  false;
      await Promise.all(relays.map(async(relay)=>{
        const {id: relayID, ...relayBody} = relay;
        const res = await fetch(`/api/relay${relayID ? `/${relayID}`:''}`, {
          method: relayID ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(relayBody)
        });
        if (!res.ok) {
          hasError =  true;
        }
      }));

      if(hasError){
        return false;
      }

      return true;

    } catch (error) {
      return false;

    } finally {
      setIsLoading(false);
    }

  }, [])

  const deleteRelayController = useCallback(async (id: string) => {
    setIsLoading(true);

    const validId = parseInt(id);
    if (!validId) {
      return false;
    }

    try {
      const res = await fetch(`/api/relay-controller/${validId}`, {
        method: 'DELETE',
      });

      if (!res.ok) {
        return false;
      }

      return true;

    } catch (error) {
      return false;

    } finally {
      setIsLoading(false);
    }
  }, []);

  const relayOff = useCallback(async (id: number, relayID: number) => {
    setIsLoading(true);

    if (typeof id !== 'number' || typeof relayID !== 'number') {
      console.error('validation error', id, relayID)
      return false;
    }

    try {
      const res = await fetch(`/api/relay-controller/${id}/relay/${relayID}/off`, {
        method: 'PUT',
      });

      if (!res.ok) {
        return false;
      }

      return true;

    } catch (error) {
      return false;

    } finally {
      setIsLoading(false);
    }
  }, []);

  const relayOn = useCallback(async (id: number, relayID: number) => {
    setIsLoading(true);

    if (typeof id !== 'number' || typeof relayID !== 'number') {
      console.error('validation error', id, relayID)
      return false;
    }

    try {
      const res = await fetch(`/api/relay-controller/${id}/relay/${relayID}/on`, {
        method: 'PUT',
      });

      if (!res.ok) {
        return false;
      }

      return true;

    } catch (error) {
      console.error(error);
      return false;

    } finally {
      setIsLoading(false);
    }
  }, []);

  const relayStatus = useCallback(async (id: number, relayID: number) => {
    setIsLoading(true);

    if (typeof id !== 'number' || typeof relayID !== 'number') {
      console.error('validation error', id, relayID)
      return false;
    }

    try {
      const res = await fetch(`/api/relay-controller/${id}/relay/${relayID}/status`);

      if (!res.ok) {
        return false;
      }
      const {relay_status} = (await res.json()) as {relay_status: 1|0}

      return relay_status;

    } catch (error) {
      console.error(error);
      return false;

    } finally {
      setIsLoading(false);
    }
  }, []);


  const relayControllerAllOff = useCallback(async (id: number) => {
    setIsLoading(true);

    if (typeof id !== 'number') {
      return false;
    }

    try {
      const res = await fetch(`/api/relay-controller/${id}/clear`, {
        method: 'POST',
      });

      if (!res.ok) {
        return false;
      }

      return true;

    } catch (error) {
      console.error(error);
      return false;

    } finally {
      setIsLoading(false);
    }
  }, []);

  const relayControllerStatus = useCallback(async (id: number) => {
    setIsLoading(true);

    if (typeof id !== 'number') {
      return false;
    }

    try {
      const res = await fetch(`/api/relay-controller/${id}/status`);

      if (!res.ok) {
        return false;
      }

      const {relay_controller_status} = (await res.json()) as {relay_controller_status: IRelayStatusMap}

      return relay_controller_status;

    } catch (error) {
      console.error(error);
      return false;

    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchAllRelayControllerStatus = useCallback(async () => {
    setIsLoading(true);

    try {
      const statusMap: IRelayControllerStatusMap = {};
      const relayControllerStatusPromises = relayControllers.map( async ({id}) => {
        const res = await fetch(`/api/relay-controller/${id}/status`);

        if (!res.ok) {
          console.warn('Failed status for controller:', id);
          return null;
        }

        const {relay_controller_status} = (await res.json()) as {relay_controller_status:IRelayStatusMap}

        statusMap[id] = relay_controller_status;

      });
      await(Promise.all(relayControllerStatusPromises));
      setRelayControllersStatusMap(statusMap);

    } catch (error) {
      console.error(error);
      return false;

    } finally {
      setIsLoading(false);
    }
  }, [relayControllers]);

  return {
    isLoading,
    relayControllers,
    relayControllersStatusMap,
    relayControllersTotal,
    actions: {
      fetchRelayControllers,
      fetchAllRelayControllerStatus,
      addRelayController,
      updateRelayController,
      getRelayControllerById,
      deleteRelayController,
      relayOff,
      relayOn,
      relayStatus,
      relayControllerAllOff,
      relayControllerStatus
    }
  }
}