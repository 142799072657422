import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { LatLngTuple } from 'leaflet';
import { SVGOverlay } from 'react-leaflet';
import { animation } from '../../../themes/common';

const alertsClass = 'alerts';
const isAnimatingClass = 'alerts-animation';
const explodeClass = 'explode';

const pinColorKeyframes = keyframes`
  from {
    fill: hsla(212, 55%, 55%);
  }
  to {
    fill: hsl(359, 63%, 67%);
  }
`;

const circleExplodeKeyframes = keyframes`
  0% {
    transform: scale(1);
    fill-opacity: 0%;
  }
  50% {
    fill-opacity: 65%;
  }
  100% {
    fill-opacity: 0%;
    transform: scale(2);
  }
`;

const alertColorAnim = css`${pinColorKeyframes} 1s ${animation.easing.primary.inOut} 1s`;
const alertExplodeAnim = css`${circleExplodeKeyframes} 1s ${animation.easing.primary.inOut}`;

const CameraG = styled.g`
  pointer-events: none;
  vector-effect: non-scaling-stroke;
  transform: scale(0.25) translate(37px, 39px);

  g {
    stroke: #FFFFFF;
  }
`;

const Pin = styled(SVGOverlay)`
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
    outline: none;
    &:hover {
      circle {
        transform: translateY(-2px);
      }
      ${CameraG} {
        transform: scale(0.25) translate(37px, 31px);
      }
    }

    circle {
      stroke: none;
      fill: hsla(212, 55%, 55%);
    }

    circle.${alertsClass} {
      fill: hsl(359, 63%, 67%);
    }

    circle.${isAnimatingClass} {
      animation: ${alertColorAnim};
    }

    circle.${explodeClass} {
      fill: hsl(359, 63%, 67%);
      transform-origin: center;
      transform: scale(2);
      fill-opacity: 0%;
      overflow: visible;
      animation: ${alertExplodeAnim};
    }
`;

const getMainCircleClasses = (hasAlert: boolean, isAnimating: boolean) => {
  return `${hasAlert ? alertsClass : ''} ${isAnimating ? isAnimatingClass : ''}`
}

interface IPinSvg {
  bounds: LatLngTuple[]
  hasAlert: boolean
  size: number
  isAnimating: boolean
  onClick?: () => void
  onHover?: () => void
  onMouseLeave?: () => void
  animationCallback?: () => void
}

const PinSvg: React.FC<IPinSvg> = ({
  bounds,
  hasAlert = false,
  size,
  isAnimating = false,
  onClick = () => { },
  onHover = () => { },
  onMouseLeave = () => { },
  animationCallback = () => { }
}) => {

  return (
    <Pin
      attributes={{ viewBox: '0 0 24 24', height: `${size}px`, width: `${size}px` }}
      bounds={bounds}
      interactive={true}
      eventHandlers={{
        click: () => {
          // e.originalEvent.stopPropagation();
          onClick()
        },
        mouseover: () => {
          onHover()
        },
        mouseout: () => {
          onMouseLeave()
        }
      }}
    >
      <circle cx="12" cy="12" r="6" className={getMainCircleClasses(hasAlert, isAnimating)} />
      {hasAlert && isAnimating && <circle cx="12" cy="12" r="6" className={explodeClass} onAnimationEnd={animationCallback} />}
      <CameraG fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g>
          <g>
            <path d="M8.25 12v3.333c-.097 1.562-1.437 2.754-3 2.667H.75M.75 15.75L.75 19.5M15 9H1.5c-.414 0-.75-.336-.75-.75V4.5C.75 2.015 2.765 0 5.25 0h16.5c.828 0 1.5.672 1.5 1.5v1.275c0 .593-.35 1.13-.891 1.371L16.5 6.751V7.5c0 .828-.672 1.5-1.5 1.5z" transform="translate(-438 -222) translate(438 222)" />
            <path d="M20.25 5.084V10.5c0 .828-.672 1.5-1.5 1.5H5.25c-1.657 0-3-1.343-3-3h0M23.25 6.75L23.25 9.75" transform="translate(-438 -222) translate(438 222)" />
          </g>
        </g>
      </CameraG>
    </Pin>
  );
}

export default PinSvg;