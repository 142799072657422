import { format, sub } from 'date-fns';
import { useCallback, useState } from 'react';

interface ILogicGroup {
  active: boolean;
  id: number
  name: string;
  rules: number
  unique_actions: number
}

interface ILogicGroupResponse {
  logicgroups: ILogicGroup[],
  message: string;
  info: {
    limit: number,
    total: number,
    offset: number;
  }
}

export interface IFetchLogicGroupsOptions {
  limit?: number;
  offset?: number;
  startTime?: Date;
  endTime?: Date;
  active?: boolean;
  name?: string;
  sortBy?: string;
  sortDirection?: string;
}

const dateTimeFormat = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx';

export const useLogicGroups = () => {
  const [logicGroups, setLogicGroups] = useState<ILogicGroup[]>();
  const [totalGroups, setTotalGroups] = useState<number>(0);
  const [logicGroupsLoading, setLogicGroupsLoading] = useState<boolean>(true);
  const [logicGroupsErrorMessage, setLogicGroupsErrorMessage] = useState('');

  const fetchLogicGroups = useCallback(async (options?: IFetchLogicGroupsOptions) => {
    const {
      limit = 100,
      offset = 0,
      startTime,
      endTime,
      active,
      name = '',
      sortBy,
      sortDirection
    } = options || {};

    setLogicGroupsLoading(true);
    setLogicGroupsErrorMessage('');
    try {

      const searchString = new URLSearchParams({
        limit: '' + limit,
        offset: '' + offset,
        start_time: format(startTime ? startTime : sub(new Date(), { days: 12 }), dateTimeFormat),
        end_time: format(endTime ? endTime : new Date(), dateTimeFormat),
        ...(active !== undefined ? { active: `${active}` } : {}),
        ...(name ? { name: `${name}` } : {}),
        ...(sortBy ? { sort_by: `${sortBy}` } : {}),
        ...(sortDirection ? { sort_direction: `${sortDirection}` } : {})
      });
      const response = await fetch('/api/logicgroup?' + searchString);

      if (response.ok) {
        const { logicgroups = [], info: { total } } = await response.json() as ILogicGroupResponse;
        setLogicGroups(logicgroups);
        setTotalGroups(total)
      } else {
        console.error(`Logic Groups fetch error: ${response.statusText}`);
        setLogicGroupsErrorMessage(`Logic Groups fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setLogicGroupsErrorMessage(error.message);
      } else {
        console.error(error);
        setLogicGroupsErrorMessage('Unknown Error fetching logic groups');
      }
    }
    setLogicGroupsLoading(false);
  }, []);

  const deleteLogicGroup = useCallback(async (groupID): Promise<boolean> => {
    try {
      const res = await fetch('/api/logicgroup/' + groupID, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!res.ok) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }, [])


  return {
    logicGroups,
    totalGroups,
    logicGroupsLoading,
    logicGroupsErrorMessage,
    actions: {
      fetchLogicGroups,
      deleteLogicGroup
    }
  }

}