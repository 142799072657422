import { useCallback, useState } from 'react';

export const useNumberPlateImport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const uploadFile = useCallback(async (text: string): Promise<boolean> => {
    setIsLoading(true);
    const blob = new Blob([text], { type: 'text/csv;charset=utf-8;' });
    const formData = new FormData();
    formData.append('data_file', blob)

    try {
      const response = await fetch('/api/number_plate_import', {
        method: 'POST',
        body: formData
      })

      if (!response.ok) {
        return false;
      }
      return true;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        return false;
      } else {
        console.error(error);
        return false;
      }
    } finally {
      setIsLoading(false);
    }

  }, []);

  return {
    isLoading,
    actions: {
      uploadFile,
    }
  }
}