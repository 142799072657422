import format from 'date-fns/format';
import sub from 'date-fns/sub';
import {useState, useCallback } from 'react';

export interface CameraAlertCount {
  [cameraID:string]: number;
}

interface CameraAlertsCountResponse {
    cameras: CameraAlertCount,
}

interface FetchCameraAlertsCountOptions {
  cameraID?: number|string;
  locationID?: number;
  startTime?: Date;
  endTime?: Date;
}
export const useAlertCount = () => {
  const [alertCounts, setAlertsCount] = useState<CameraAlertCount>({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchAlertCounts = useCallback(async(options?: FetchCameraAlertsCountOptions) => {
    const {
      cameraID,
      locationID,
      startTime = sub(new Date(), {days: 2}),
      endTime = new Date()
    } = (options||{});
    setErrorMessage('');
    let alreadyLoading = false;
    setLoading(loading => {
      alreadyLoading = loading;
      return true;
    });
    if(alreadyLoading) {
      return;
    }

    const searchString = new URLSearchParams({
      start_time: format(startTime, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx'),
      end_time: format(endTime, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx'),
      ...(locationID ? { location_id: `${locationID}` } : {}),
      ...(cameraID !== undefined ? { camera_id: `${cameraID}` } : {})
    });

    try {
      const response = await fetch(`/alert_api/alert_count?${searchString.toString()}`);

      if(response.ok){
      const {
        cameras = {}
      } = await response.json() as CameraAlertsCountResponse;
        setAlertsCount(cameras);
      }else{
        console.error(`AlertCount fetch error: ${response.statusText}`);
        setErrorMessage(`AlertCount fetch error: ${response.statusText}`);
      }

    } catch(error){
      if (error instanceof Error) {
        console.error(error.message);
        setErrorMessage(error.message);
      } else {
        console.error(error);
        setErrorMessage('Unknown Error fetching Cameras.');
      }
    }
    setLoading(false);
  },[]);

  return{
    alertCounts,
    loading,
    errorMessage,
    actions: {
      fetchAlertCounts
    }
  }
}

