import React from 'react';
import { LatLngTuple } from 'leaflet';
// import { Marker } from 'react-leaflet';
import ViewAngleSvg from './ViewAngleSvg';

const ANGLE_SIZE = 200;

interface ICameraMark {
  latlngPos: LatLngTuple
  totalAlerts: number
  orientation: number
  onClick?: () => void
  onHover?: () => void
  onMouseLeave?: () => void
}

const CameraMark: React.FC<ICameraMark> = ({ latlngPos, totalAlerts, onClick, onHover, onMouseLeave, orientation }) => {

  const viewAngleBounds: LatLngTuple[] = [
    [latlngPos[0] - ANGLE_SIZE / 2, latlngPos[1] - ANGLE_SIZE / 2],
    [latlngPos[0] + ANGLE_SIZE / 2, latlngPos[1] + ANGLE_SIZE / 2],
  ];

  return (
    <>
      {/* <Marker position={latlngPos} />  Base marker to check the custom markers position */}
      <ViewAngleSvg
        {...{onClick, onHover, onMouseLeave }}
        angle={orientation}
        bounds={viewAngleBounds}
        hasAlert={totalAlerts > 0}
        size={ANGLE_SIZE}
      />
    </>
  );
};

export default CameraMark;