import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LoadingButton from '../../atoms/LoadingButton';

const Container = styled.div``;

const UpTitle = styled.div`
  color: hsl(208, 9%, 67%);
  font-size: 12px;
  margin-bottom: 6px;
`;

const Title = styled.div`
  color: hsl(208, 8%, 38%);
  font-size: 12px;
  margin-bottom: 17px;
`;

const ButtonWithStatus = styled.div`
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusDot = styled.div`
  background-color: hsl(205, 100%, 65%);
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-top: 5px;
`;

const ButtonGroup = styled.div`
  display: flex;
`;



interface IRelayControl {
  upTitle?: string
  title: string
  isOn: boolean
  hideDot?: boolean
  isLoading?: boolean
  onCallback?: () => void
  offCallback?: () => void
}

const RelayControl: React.FC<IRelayControl> = ({
  upTitle,
  title,
  isOn,
  hideDot = false,
  isLoading = false,
  onCallback = () => {},
  offCallback = () => {},
  ...props
}) => {
  const { t } = useTranslation(['RelayController']);

  return (
    <Container {...props}>
      <UpTitle>{upTitle}</UpTitle>
      <Title>{title}</Title>
      <ButtonGroup>
        <ButtonWithStatus>
          <LoadingButton onClick={onCallback} disabled={isOn} isLoading={!isOn && isLoading}>
            {t('on')}
          </LoadingButton>
          {isOn && !hideDot && <StatusDot />}
        </ButtonWithStatus>
        <ButtonWithStatus>
          <LoadingButton onClick={offCallback} disabled={!isOn} isLoading={isOn && isLoading} >
            {t('off')}
          </LoadingButton>
          {!isOn && !hideDot && <StatusDot />}
        </ButtonWithStatus>
      </ButtonGroup>
    </Container>
  );
};

export default RelayControl;