import * as React from 'react';

function SvgPersonIntrusion(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M17.5 20.5a.375.375 0 100 .75.375.375 0 000-.75h0M17.5 18.25v-3" />
        <path d="M23.312 20.932a1.774 1.774 0 01-1.587 2.568h-8.451a1.774 1.774 0 01-1.587-2.568l4.226-8.452a1.774 1.774 0 013.173 0l4.226 8.452z" />
        <circle cx={6.25} cy={4} r={3} />
        <path d="M11.5 14.5v-.75a5.25 5.25 0 00-10.5 0V16h2.25L4 23.5h4.5l.386-3.862" />
      </g>
    </svg>
  );
}

export default SvgPersonIntrusion;
