import * as React from 'react';

function SvgMetabase(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M1 23.5h21.75M3 19h2.751a.5.5 0 01.5.5v4H2.5v-4A.5.5 0 013 19zm7.5-9h2.751a.5.5 0 01.5.5v13H10v-13a.5.5 0 01.5-.5zm7.5 4.5h2.751a.5.5 0 01.5.5v8.5h-3.75V15a.5.5 0 01.499-.5zM7.134 5.371L3.117 9.384m11.445-2.809l-4.674-1.25m10.755-2.186l-3.581 2.8" />
        <circle cx={8.5} cy={4.751} r={1.5} />
        <circle cx={22.001} cy={2.5} r={1.5} />
        <circle cx={16.001} cy={7} r={1.5} />
        <circle cx={2.5} cy={10.751} r={1.5} />
      </g>
    </svg>
  );
}

export default SvgMetabase;
