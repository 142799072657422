import { FC } from 'react';
import { Icon } from 'scorer-ui-kit';
import styled, { css } from 'styled-components';

const ColumnCss = css`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
 ${ColumnCss}
 margin-bottom: 30px;
`;

const NoVideoContainer = styled.div`
  ${ColumnCss}
  justify-content: center;
  align-items: center;
  height: 250px;
  background-color: #cfd8df;
`;

const NoSeletectedText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.2px;
  color: #949ea7;
  margin-top: 15px;
`;

const Section = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  height: ${({ height }) => height ? height : 77}px;
`;

const IconWrapper = styled.div`
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.divider};
`;

const EmptyDiv = styled.div<{ width?: number }>`
  width: ${({ width }) => width ? width : 88}px;
  height: 11px;
  border-radius: 3px;
  background-color: #dfdfdf;
  margin-left: 15px;
`;

const InfoEmptyDiv = styled(EmptyDiv)`
  margin-top: 20px;
`;

const FirstEmptyDiv = styled(EmptyDiv)`
  margin-top: 18px;
  margin-bottom: 10px;
`;

const ColumnContainer = styled.div`
  ${ColumnCss}
`;

interface IEmptyState {
  icon: string,
  message: string
}

const EmptyState: FC<IEmptyState> = ({ icon, message }) => {

  return (
    <Container>
      <NoVideoContainer>
        <Icon icon={icon} size={74} color='dimmed' weight='heavy' />
        <NoSeletectedText>{message}</NoSeletectedText>
      </NoVideoContainer>

      <Section>
        <IconWrapper>
          <Icon icon={icon} color='dimmed' size={18} />
        </IconWrapper>

        <ColumnContainer>
          <FirstEmptyDiv />
          <EmptyDiv width={118} />
        </ColumnContainer>

      </Section>

      <Section height={51}>
        <IconWrapper>
          <Icon icon='Information' color='dimmed' size={18} />
        </IconWrapper>
        <InfoEmptyDiv />
      </Section>
    </Container>
  )
}

export default EmptyState;