import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonWithIcon, Content, PageHeader, useModal, useTo } from 'scorer-ui-kit';
import styled from 'styled-components';
import EverythingOffModal from '../components/relay-controller/atoms/EverythingOffModal';
import RelayControl from '../components/relay-controller/molecules/RelayControls';
import { useRelayController } from '../hooks/useRelayController';
import { useLocalStorage } from '../hooks/useLocalStorage';

const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

const TopSection = styled.div`
  margin-bottom: 44px;
  display: flex;
  justify-content: space-between;
`;

const RelayList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  row-gap: 25px;
`;

const AreaSection = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
  padding: 32px 0 52px 0;
`;

const AreaUpTitle = styled.div`
  font-size: 12px;
  color: hsl(208, 9%, 67%);
`;

const AreaTitle = styled.div`
  font-size: 16px;
  color: hsl(208, 8%, 38%);
  margin-bottom: 24px;
  margin-top: 3px;
`;

const AreaSectionTop = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TitleGroup = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > :first-child {
    margin-right: 10px;
  }
  button {
    flex-shrink: 0;
  }
  svg path, svg circle {
    stroke: hsla(0, 0%, 100%, 1.000);
  }
`;

const RelayController: React.FC = () => {
  const { t } = useTranslation(['RelayController', 'Common']);
  const [isLoading, setIsLoading] = useState('') /// I'm suggesting using the same isLoading with string to match
  const { createModal } = useModal();
  const to = useTo();
  const { relayControllers, relayControllersStatusMap, actions: { fetchRelayControllers, fetchAllRelayControllerStatus, relayOn, relayOff, relayControllerAllOff } } = useRelayController();
  const [confirmEveryThingOff, setConfirmEveryThingOff] = useLocalStorage<boolean>('relay-controller.confirmEveryThingOff', true);
  const [confirmAllOff, setConfirmAllOff] = useLocalStorage<boolean>('relay-controller.side-pane.confirmAllOff', true);

  const handleTurnOn = useCallback(async (relayControllerId: number, relayId: number) => {
    setIsLoading(`loading-${relayControllerId}-${relayId}`);
    console.debug(`turning ON Relay Controller ${relayControllerId} - relay ${relayId}`);
    await relayOn(relayControllerId, relayId);
    await fetchAllRelayControllerStatus();
    setIsLoading('');
  }, [fetchAllRelayControllerStatus, relayOn])

  const handleTurnOff = useCallback(async (relayControllerId: number, relayId: number) => {
    setIsLoading(`loading-${relayControllerId}-${relayId}`);
    console.debug(`turning OFF Relay Controller ${relayControllerId} - relay ${relayId}`);
    await relayOff(relayControllerId, relayId);
    await fetchAllRelayControllerStatus();
    setIsLoading('');
  }, [fetchAllRelayControllerStatus, relayOff]);

  const turnAllRelayOff = useCallback(async () => {
    const allOffPromises = relayControllers.map(async ({ id }) => {
      return relayControllerAllOff(id);
    });
    await (Promise.all(allOffPromises));
    fetchAllRelayControllerStatus();
  }, [fetchAllRelayControllerStatus, relayControllerAllOff, relayControllers]);

  const handleEverythingOff = useCallback(() => {
    confirmEveryThingOff ?
      createModal({
        isCloseEnable: false,
        customComponent: (<EverythingOffModal
          onCancel={() => { console.debug('Do I have to do anything? o.O') }}
          onAllOff={(doNotShowAgain) => {
            console.debug(`turning everything off and saving in local storage that this will ${doNotShowAgain ? 'not ' : ''} show this message again`);
            setConfirmEveryThingOff(!doNotShowAgain);
            turnAllRelayOff();
            fetchAllRelayControllerStatus();
          }}
        />)
      })
      :
      turnAllRelayOff();

  }, [confirmEveryThingOff, createModal, turnAllRelayOff, setConfirmEveryThingOff, fetchAllRelayControllerStatus]);

  const handleRelayAllOff = useCallback((name: string, relayControllerId: number) => {
    confirmAllOff ?
      createModal({
        isCloseEnable: false,
        customComponent: (<EverythingOffModal
          relayController={name}
          onCancel={() => { console.debug('Do I have to do anything? o.O') }}
          onAllOff={(doNotShowAgain) => {
            setConfirmAllOff(!doNotShowAgain);
            console.log(`turning everything off ${name} with ID: ${relayControllerId} and saving in local storage that this will ${doNotShowAgain ? 'not ' : ''} show this message again`);
            relayControllerAllOff(relayControllerId);
            fetchAllRelayControllerStatus();
          }}
        />)
      })
      : relayControllerAllOff(relayControllerId);

  }, [confirmAllOff, createModal, fetchAllRelayControllerStatus, relayControllerAllOff, setConfirmAllOff]);

  useEffect(() => {
    fetchRelayControllers();
  }, [fetchRelayControllers]);

  useEffect(()=>{
    fetchAllRelayControllerStatus();
  },
  [fetchAllRelayControllerStatus]);

  return (
    <Container>
      <TopSection>
        <PageHeader
          icon='LayoutGrid'
          title={t('relayController')}
          introductionText={t('introText')}
        />
        <ButtonWrapper>
          <ButtonWithIcon icon='Add' position='left' size='small' onClick={to('/relay-controller/add')}>{t('Common:add')}</ButtonWithIcon>
          <Button design='danger' size='small' onClick={handleEverythingOff}>{t('allRelaysOff')}</Button>
        </ButtonWrapper>

      </TopSection>

      {relayControllers.map((relayController) => (
        <AreaSection key={relayController.id}>
          <AreaSectionTop>
            <TitleGroup>
              <AreaUpTitle>{`${t('relayControllerID')}: ${relayController.id}`}</AreaUpTitle>
              <AreaTitle>{relayController.name}</AreaTitle>
            </TitleGroup>
            <ButtonWrapper>
              <Button design='primary' size='xsmall' onClick={to(`relay-controller/${relayController.id}/edit`)}>{t('Common:edit',)}</Button>
              <Button design='danger' size='xsmall' onClick={() => handleRelayAllOff(relayController.name, relayController.id)}>{t('allOff')}</Button>
            </ButtonWrapper>
          </AreaSectionTop>
          <RelayList>
            {relayController.relays.sort((a,b)=>a.port - b.port).map((relay) => (
              <RelayControl
                key={relay.id}
                upTitle={`${t('relayID')}: ${relay.port}`}
                title={relay.name || '[None]'}
                isOn={relayControllersStatusMap?.[relayController.id]?.[relay.port] === 1}
                hideDot={false}
                isLoading={isLoading === `loading-${relayController.id}-${relay.id}`}
                onCallback={() => handleTurnOn(relayController.id, relay.port)}
                offCallback={() => handleTurnOff(relayController.id, relay.port)} />
            ))}
          </RelayList>
        </AreaSection>
      ))}

    </Container>
  );
};

export default RelayController;
