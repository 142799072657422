import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { ButtonWithIcon, Icon } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';
import { getAlertTypeLocal, getRelativeFormat, getTimeSince } from '../../utils';
import Breadcrumb from './Breadcrumb';
import { Alert } from '../../context/AlertsContext';
import { DASHBOARD_PARAMS, useSelected } from '../../hooks/useSelected';

const Container = styled.div``;

const DismissAlertButton = styled(ButtonWithIcon)`
  margin-right: 15px;
  margin-bottom: 5px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  height: 77px;
`;

const IconWrapper = styled.div`
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.divider};
  height: 100%;
`;

const DateTimeContainer = styled(TitleContainer)`
  align-items: center;
  height: 79px;
`;

const DateTimeWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px 0 22px;
`;

const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: 0.18px;
  color: #949ea7;
  font-weight: 500;
`;

const TimeIconWrapper = styled.div`
  margin: auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px 26px 10px 55px;
`;

const CameraButton = styled(ButtonWithIcon)`
  margin-bottom: 5px;
  width: min-content;
`;

const BreadcrumbWrapper = styled.div`
  margin-left: 20px;
  display: flex;
`;

interface IAlertInfo {
  intrusionAlert: Alert
  name?: string,
  zone_name?: string,
  location_name?: string,
  dismissAlert?: (alert: Alert) => Promise<void>
}

const AlertInfo: FC<IAlertInfo> = ({ intrusionAlert, name = '-', zone_name = '-', location_name = '-', dismissAlert = () => { } }) => {
  const { datetime = '', alert_type = '-', active = false, camera_id, algorithm, detection } = intrusionAlert;

  const { t } = useTranslation('Dashboard');
  const { updateParam } = useSelected();

  const onClickViewCamera = useCallback(() => {
    updateParam(DASHBOARD_PARAMS.cameraId, camera_id);
    updateParam(DASHBOARD_PARAMS.tab, 'camera');
  }, [updateParam, camera_id]);

  return (
    <Container>

      <TitleContainer>
        <IconWrapper>
          <Icon icon='BigWarning' color='danger' size={18} />
        </IconWrapper>
        <BreadcrumbWrapper>
          <Breadcrumb list={[location_name, zone_name, name]} title={getAlertTypeLocal(alert_type, algorithm, detection)} />
        </BreadcrumbWrapper>
      </TitleContainer>

      <DateTimeContainer>
        <IconWrapper>
          <Icon icon='Information' color='dimmed' size={18} />
        </IconWrapper>

        <DateTimeWithIcon>
          <TimeIconWrapper>
            <Icon icon='Time' size={15} color='dimmed' />
          </TimeIconWrapper>
          <DateTimeWrapper>
            {getTimeSince(datetime)} <br />
            ({getRelativeFormat(datetime)})
          </DateTimeWrapper>
        </DateTimeWithIcon>

      </DateTimeContainer>

      <ButtonsWrapper>
        <DismissAlertButton icon='NotificationsOff' position='left' size='small' design='secondary' disabled={!active} onClick={() => dismissAlert(intrusionAlert)}>{t('alertTab.dismissAlert')}</DismissAlertButton>
        <CameraButton icon='Camera' position='left' size='small' design='secondary' onClick={onClickViewCamera}>{t('alertTab.viewAlertCamera')}</CameraButton>
      </ButtonsWrapper>

    </Container>
  )
};

export default AlertInfo;