import { FC } from 'react';
import { Icon } from 'scorer-ui-kit';
import styled from 'styled-components';

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
`;

const Value = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.18px;
  color: #949ea7;
  margin-left: 8px;
  margin-top: -5px;
`;

const CircleIcon = styled.div<{ color?: string }>`
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: #87d58f;
  margin-left: 2px;
  margin-bottom: 3px;
`;

interface IValueWithIcon {
  icon: string,
  value: string
}

const ValueWithIcon: FC<IValueWithIcon> = ({ icon, value }) => (
  <ItemsContainer>
    {icon === 'Circle' ? <CircleIcon /> : <Icon icon={icon} size={15} color='dimmed' weight='light' />}
    <Value>{value || '-'}</Value>
  </ItemsContainer>
);

export default ValueWithIcon;