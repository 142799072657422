import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterBar, IFilterDropdownConfig, ISearchFilter, PageHeader, Spinner } from 'scorer-ui-kit';
import { Container, PageHeaderWrapper } from '../Style';
import styled from 'styled-components';
import { useCameras } from '../hooks/useCameras';
import CameraCard from '../components/CameraCard';

const FilterWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
`;

const CamerasContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const NoCamerasText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 20px;
  font-weight: 500;
`;

const Cameras: FC = () => {

  const { cameras, loading, actions: { fetchCameras } } = useCameras();

  const { t } = useTranslation(['Cameras', 'Common']);

  const searchers: ISearchFilter[] = [
    {
      id: 'camera-search',
      placeholder: t('filterByCameraName'),
    }
  ];

  const dropdownConfig: IFilterDropdownConfig[] = [
    {
      id: 'tags',
      buttonText: t('tags'),
      list: [
        { text: 'Project 1', value: 'Project 1' },
        { text: 'Boot', value: 'Boot' },
      ],
      buttonIcon: 'AnalysisType',
      optionType: 'checkbox',
    },
    {
      id: 'location',
      buttonText: t('location'),
      list: [],
      buttonIcon: 'Location',
      optionType: 'checkbox',
      loadingText: t('loadingLocations'),
      hasOptionsFilter: true,
      searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
    },
    {
      id: 'zone',
      buttonText: t('zone'),
      list: [],
      buttonIcon: 'Zone',
      optionType: 'checkbox',
      loadingText: t('loadingZones'),
      hasOptionsFilter: true,
      searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
    },
    {
      id: 'type',
      buttonText: t('type'),
      list: [],
      buttonIcon: 'Notifications',
      optionType: 'radio',
    },
    {
      id: 'status',
      buttonText: t('status'),
      list: [],
      buttonIcon: 'Notifications',
      optionType: 'radio',
    }
  ];

  useEffect(() => {
    fetchCameras();
  }, [fetchCameras]);

  return (
    <Container>
      <PageHeaderWrapper>
        <PageHeader icon='Camera' title={t('cameras')} introductionText={t('introText')} />
      </PageHeaderWrapper>

      <FilterWrapper>
        <FilterBar
          searchersConfig={searchers}
          dropdownsConfig={dropdownConfig}
          datePickersConfig={[]}
          filtersTitle={t('Common:filter.filters') + ':'}
          resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
          totalResults={cameras.length}
        />
      </FilterWrapper>

      <CamerasContainer>
        {loading ?
          <Spinner styling='primary' size='large' /> :

          cameras.length ?
            cameras.map((camera) => <CameraCard key={camera.id} {...camera} />) :
            <NoCamerasText>{t('noCamerasAvailable')}</NoCamerasText>}

      </CamerasContainer>
    </Container>
  )

}

export default Cameras;