import { useCallback, useState } from 'react';

export interface IPerson {
  id: number,
  full_name: string,
  description: string,
  features: string,
  category: string,
  photo_url: string,
  tags: string
}

interface IPeopleResponse {
  people: IPerson[],
  message: string;
  info: {
    limit: number,
    total: number,
    offset: number;
  }
}

export interface IFetchPeopleOptions {
  limit?: number;
  offset?: number;
  name?: string;
  tags?: string;
  category?: string;
  sortBy?: string;
  sortDirection?: string;
}

export const usePeople = () => {
  const [people, setPeople] = useState<IPerson[]>([]);
  const [totalPeople, setTotalPeople] = useState<number>(0);
  const [peopleLoading, setPeopleLoading] = useState<boolean>(true);
  const [peopleErrorMessage, setPeopleErrorMessage] = useState('');

  const fetchPeople = useCallback(async (options?: IFetchPeopleOptions) => {
    const {
      limit = 100,
      offset = 0,
      name : full_name = '',
      tags = '',
      category = '',
      sortBy,
      sortDirection
    } = options || {};

    setPeopleLoading(true);
    setPeopleErrorMessage('');
    try {

      const searchString = new URLSearchParams({
        limit: '' + limit,
        offset: '' + offset,
        ...(full_name ? { full_name } : {}),
        ...(tags ? { tags: `${tags}` } : {}),
        ...(category ? { category: `${category}` } : {}),
        ...(sortBy ? { sort_by: `${sortBy}` } : {}),
        ...(sortDirection ? { sort_direction: `${sortDirection}` } : {})
      });

      const response = await fetch('/api/people?' + searchString);

      if (response.ok) {
        const { people = [], info: { total } } = await response.json() as IPeopleResponse;
        setPeople(people);
        setTotalPeople(total)
      } else {
        console.error(`People fetch error: ${response.statusText}`);
        setPeopleErrorMessage(`People fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setPeopleErrorMessage(error.message);
      } else {
        console.error(error);
        setPeopleErrorMessage('Unknown Error fetching people');
      }
    } finally {
      setPeopleLoading(false);
    }
  }, []);

  return {
    people,
    totalPeople,
    peopleLoading,
    peopleErrorMessage,
    actions: {
      fetchPeople
    }
  }
}