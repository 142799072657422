import { LatLngTuple } from 'leaflet';
import React from 'react';
import { SVGOverlay } from 'react-leaflet';
import styled, { css, keyframes } from 'styled-components';
import { animation } from '../../../themes/common';
import { getCentroid } from '../mapUtils';

const OFFSET_WIDTH = 400;
const OFFSET_HEIGHT = 200;

const fillOpacityInKeyframes = keyframes`
  0% {
    fill-opacity: 0%;
  }
  100% {
    fill-opacity: 100%;
  }
`;

const strokeOpacityInKeyframes = keyframes`
  0% {
    stroke-opacity: 0%;
  }
  100% {
    stroke-opacity: 100%;
  }
`;

const SVGContainer = styled(SVGOverlay)`
  pointer-events: none;
`;


const baseTextStyle = css`
  pointer-events: none;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  fill: hsl(0, 0%, 100%);
  stroke: hsl(0, 0%, 100%);
  stroke-width: 1.5px;
  text-anchor: middle;
  transition: 1s ${animation.easing.primary.inOut};
`;

const LocationTitle = styled.text<{ hasAlerts: boolean }>`
  ${baseTextStyle};
  font-size: 20px;
  ${({ hasAlerts }) => hasAlerts
    ? css`
      transform: translate(195px,126px);
    `
    : css`
      transform: translate(195px, 70px);
    `
  }};

`;


const ZoneTitle = styled.text<{ hasAlerts: boolean }>`
  ${baseTextStyle};
  font-size: 26px;
  transform: translate(90px, 36px);
  ${({ hasAlerts }) => hasAlerts
    ? css`
      transform: translate(195px, 156px)
  `
    : css`
      transform: translate(195px, 105px);
  `
  }};
`;

const AlertG = styled.g`
  pointer-events: none;
  transform: scale(2) translate(85px, 15px);
  vector-effect: non-scaling-stroke;
  g {
    stroke: hsl(0, 0%, 100%);
    animation: ${strokeOpacityInKeyframes} 1.5s ${animation.easing.primary.inOut};
  }
`;

const AlertSquare = styled.rect`
  transform: rotate(45deg);
  fill: hsl(359, 63%, 67%);
  stroke: none;
  rx: 8px;
  ry: 8px;
  height: 60px;
  width: 60px;
  x: 146;
  y: -129;
  animation: ${fillOpacityInKeyframes} 1.5s ${animation.easing.primary.inOut};
`;

interface IZoneInfo {
  bounds: LatLngTuple[]
  hasAlerts: boolean
  locationName?: string
  zoneName?: string
}

const ZoneInfo: React.FC<IZoneInfo> = ({
  bounds,
  hasAlerts,
  zoneName = 'Zone Has No Name',
  locationName = 'Has Not NAme'
}) => {

  const [lat, lng] = getCentroid(bounds);
  const newBounds: LatLngTuple[] = [[lat - OFFSET_HEIGHT / 2, lng - OFFSET_WIDTH / 2], [lat + OFFSET_HEIGHT / 2, lng + OFFSET_WIDTH / 2]];

  return (
    <SVGContainer
      attributes={{ viewBox: `0 0 ${OFFSET_WIDTH} ${OFFSET_HEIGHT}` }}
      bounds={newBounds}
      interactive={false}
    >
      <LocationTitle {...{ hasAlerts }} >{locationName}</LocationTitle>
      <ZoneTitle {...{ hasAlerts }} >{zoneName}</ZoneTitle>
      {hasAlerts && <AlertSquare />}
      {hasAlerts && (
        <AlertG fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
          <g>
            <g>
              <path d="M12 16.5c-.207 0-.375.168-.375.375s.168.375.375.375.375-.168.375-.375-.168-.375-.375-.375h0M12 13.5L12 6" transform="translate(-276 -222) translate(276 222)" />
              <path d="M1.28 13.281c-.707-.707-.707-1.854 0-2.561l9.44-9.439c.34-.34.8-.531 1.28-.531s.94.191 1.28.531l9.44 9.439c.707.707.707 1.854 0 2.561l-9.44 9.439c-.707.706-1.853.706-2.56 0l-9.44-9.439z" transform="translate(-276 -222) translate(276 222)" />
            </g>
          </g>
        </AlertG>)}
    </SVGContainer>
  );
};

export default ZoneInfo;