import React, { DragEvent, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';

const Divider = styled.div`
  grid-area: h-divider;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.divider};
  position: relative;
  overflow: visible;
  cursor: row-resize;
  height: 100%;
  width: 100%;
  :after{
    opacity: 0;
    display: block;
    content: '<>';
    border-radius: 50%;
    background-color: hsla(0deg, 0%, 0%, 50%);
    position: absolute;
    left:50%;
    height: 24px;
    width: 24px;
    top: -13px;
    text-align:center;
    line-height: 24px;
    transform: rotate(90deg);
    z-index: 700;
  }
  &:hover { 
    :after {
      opacity:1;
      transition: opacity .3s;
    }
  }
`;

interface Props {
  setHeight: (previousValue: number) => void;
  currentHeight: number;
  onDragEnd?: () => void;
}
const HorizontalDivider: React.FC<Props> = ({
  setHeight,
  currentHeight,
  onDragEnd = () => { }
}) => {
  const mouseStartingRef = useRef<number>(0);
  const startingHeightRef = useRef<number>(0);

  const onDragStart = useCallback((e: DragEvent<HTMLDivElement>) => {
    const newDiv = document.createElement('div');
    e.dataTransfer.setDragImage(newDiv, 0, 0);
    mouseStartingRef.current = e.nativeEvent.pageY;
    startingHeightRef.current = currentHeight;
  }, [currentHeight]);

  const onDrag = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (mouseStartingRef.current && e.nativeEvent.pageY) {
      const moved = mouseStartingRef.current - e.nativeEvent.pageY;
      setHeight(startingHeightRef.current + moved);
    }
  }, [setHeight])

  const throttledDragHandler = useMemo(
    () => throttle(onDrag, 200)
    , [onDrag]);

  return (
    <Divider draggable='true' onDrag={throttledDragHandler} onDragStart={onDragStart} onDragEnd={onDragEnd} />
  );
}

export default HorizontalDivider;
