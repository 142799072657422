import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'scorer-ui-kit';
import styled, { css } from 'styled-components';
import { Alert } from '../../context/AlertsContext';

const StatusContainer = styled.div`
  text-align: center;
`;

const StatusText = styled.div`
  color: rgba(120, 138, 144, 0.72);
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-style: italic;
  color: rgba(120, 138, 144, 0.72);
`;

const ActiveStatusButton = styled(Button) <{ hovering: boolean }>`
${({ hovering }) => hovering ? css`
  color: #8d8d8d;
` :
    css`
  background: none;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-style: italic;
  color: hsla(195, 10%, 52%, 0.72);
  font-weight: normal;
`}
`;

interface IAlertStatusCell {
  status: boolean,
  dismissAlert?: (alert: Alert) => Promise<void>,
  alert: Alert
}

const AlertStatusCell: FC<IAlertStatusCell> = ({ status, alert, dismissAlert = () => { } }) => {
  const [hovering, setHovering] = useState(false);
  const { t } = useTranslation('Common');

  const onHoverProps = {
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false),
  };

  return (
    <StatusContainer>

      {status ?
        <ActiveStatusButton size='xsmall' design='secondary' {...onHoverProps} hovering={hovering && status} disabled={!status} onClick={() => dismissAlert(alert)}>
          {hovering ? t('filter.alertStatus.dismiss') : t('filter.alertStatus.active')}
        </ActiveStatusButton> :
        <StatusText>
          {t('filter.alertStatus.dismissed')}
        </StatusText>}

    </StatusContainer>)
};

export default AlertStatusCell;