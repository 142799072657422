import { FC } from 'react';
import styled from 'styled-components';
import { EllipsisStyles } from '../../Style';

const CellText = styled.div`
  font-size: 14px;
  color: hsla(195,10%,52%,0.72);
  font-family: ${({ theme }) => theme.fontFamily.data};
  max-width: 100px;
 ${EllipsisStyles}
`;

interface ILongTextCell {
  text: string
}

const LongTextCell: FC<ILongTextCell> = ({ text }) => {

  return <CellText>{text}</CellText>
}

export default LongTextCell;