import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CardContent = styled.div`
  padding: 29px 0 51px 40px;
  font-size: 14px;
  font-weight: 500;
  color: hsl(200, 1%, 49%);
`;

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: hsl(210, 6%, 47%);
  margin-bottom: 27px;
`;

const GuidelineList = styled.ul`
  margin-left: -32px;
`;

const GuidelineItem = styled.li`
  font-size: 14px;
  line-height: 2.08;
  color: #8b9196;
`;


const PhotoGuidelinesModal: React.FC = () => {
  const { t } = useTranslation(['AddEditPerson']);

  return (
    <CardContent>
      <CardTitle>{t('uploadGuidelines')}</CardTitle>
      <GuidelineList>
        {
          Array.from(Array(7), (e, i) => <GuidelineItem key={i}>{t('guidelines.' + (i))}</GuidelineItem>)
        }
      </GuidelineList>
    </CardContent>);
};

export default PhotoGuidelinesModal;