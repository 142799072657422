import * as React from 'react';

function SvgVehicleIn(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M3.203 6.358L4.952 2.46A2.249 2.249 0 017.061 1h8.882c.939 0 1.78.581 2.109 1.46l1.75 3.9M1.752 12.8v1.7a1.5 1.5 0 103 0V13m-.375-3.75a.375.375 0 00-.375.375m.75 0a.375.375 0 00-.375-.375m0 .75a.375.375 0 00.375-.375m-.75 0c0 .207.168.375.375.375" />
        <path d="M21.6 7.75a3 3 0 00-2.6-1.5H4a3 3 0 00-3 3v2.25A1.5 1.5 0 002.5 13h6m14.999 3.497h-12m7 5l5-5-5-5" />
      </g>
    </svg>
  );
}

export default SvgVehicleIn;
