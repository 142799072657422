import React, { useCallback, useEffect, useState } from 'react';
import { ActionButtons, ConfirmationModal, DateInterval, FilterBar, IconButtonData, IFilterDatePicker, IFilterDropdownConfig, IFilterItem, IFilterResult, TypeTable, useModal, useNotification } from 'scorer-ui-kit';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import { downloadFile, getActions, getMetaDetectionList, getSelected, getSelectedDate, readParams } from '../../utils';
import { INITIAL_ROWS } from '../../constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Pagination from '../Pagination';
import { IParams } from '../../types';
import { IFetchDetectionOptions, isCarDetection, useDetections } from '../../hooks/useDetections';
import { AlgorithmType } from '../../hooks/useCameraConfig';
import TableCellRelativeTime from '../atoms/TableCellRelativeTime';

const Container = styled.div``;

const FilterWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
`;

export interface ISelectedFilterValues {
  [key: string]: DateInterval | string | undefined | Date | IFilterItem[] | boolean | AlgorithmType
}

const initialFilterValues: ISelectedFilterValues = {
  type: undefined,
  when: undefined,
  location: undefined,
  sortDirection: 'asc',
  sortBy: 'datetime',
  paramsLoaded: false,
  algorithm: undefined
}

const sizeOptions = [10, 20, 50, 100];


const DetectionTab: React.FC = () => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);
  const { t } = useTranslation(['CameraDetails', 'Common']);
  const { createModal } = useModal();
  const { cameraID }: IParams = useParams();
  const { detections, detectionCount, isLoading, actions: { fetchDetections, deleteDetection } } = useDetections();
  const { sendNotification } = useNotification();

  const { push } = useHistory();

  const tableColumns: ITableColumnConfig[] = [
    {
      groupTitle: t('detectionTab.meta'),
      header: t('detectionTab.type'),
      cellStyle: 'firstColumn',
      columnId: 'message'
    },
    {
      groupTitle: t('detectionTab.meta'),
      header: isCarDetection(detections[0]?.algorithm) ? t('detectionTab.numberPlate') : t('detectionTab.name'),
      cellStyle: 'lowImportance',
      columnId: isCarDetection(detections[0]?.algorithm) ? 'plate' : 'name'
    },
    {
      groupTitle: t('detectionTab.meta'),
      header: t('detectionTab.when'),
      sortable: true,
      sortActive: true,
      cellStyle: 'lowImportance',
      columnId: 'datetime'
    },
    {
      header: t('detectionTab.actions'),
      cellStyle: 'normalImportance'
    }
  ];



  const getIcon = (isCarDetection: boolean, subject_type: string): string => {
    if (isCarDetection) {
      switch (subject_type) {
        case 'LISTED':
          return 'VehicleWhitelist'

        case 'IMPORTANT':
          return 'VehicleBlacklist'

        default:
          return 'VehicleUnknown'
      }
    } else {
      switch (subject_type) {
        case 'LISTED':
          return 'PersonWhitelist'

        case 'IMPORTANT':
          return 'PersonBlacklist'

        default:
          return 'PersonUnknown'
      }
    }
  }

  const getMetaType = useCallback((subject_type: string, algorithm: AlgorithmType ,  kind?: string) => {

    if (kind === 'person' || algorithm === 'FACE_IDENTIFICATION' ) {
      switch (subject_type) {
        case 'LISTED':
          return t('Common:alertType.listedPerson');

        case 'IMPORTANT':
          return t('Common:alertType.importantPerson');

        default:
          if(algorithm === 'INTRUSIONS') {
            return t('Common:alertType:intruderPerson')
          }
          return t('Common:alertType.unknownPerson');
      }
    } else {

      switch (subject_type) {
        case 'LISTED':
          return t('Common:alertType.listedVehicle');

        case 'IMPORTANT':
          return t('Common:alertType.importantVehicle')

        default:
          if(algorithm === 'INTRUSIONS') {
            return t('Common:alertType:intruderVehicle')
          }
          return t('Common:alertType.unknownVehicle');
      }
    }
  },[t]);

  const getApiParams = useCallback((): IFetchDetectionOptions => {

    const { when, sortDirection = 'asc', sortBy = 'datetime', type } = selectedFilterValues;

    const correctedUISort = sortBy === 'datetime'
    ? sortDirection === 'asc' ? 'desc' : 'asc'
    : sortDirection

    const params: IFetchDetectionOptions = {
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      startTime: (when as DateInterval)?.start,
      endTime: (when as DateInterval)?.end,
      cameraId: cameraID,
      sortBy: sortBy as string,
      sortDirection: correctedUISort as string,
      subjectType: type as string
    };

    return params;

  }, [selectedFilterValues, pageSize, currentPage, cameraID]);

  const handleDelete = useCallback(async (detectionId: number) => {
    const result = await deleteDetection(detectionId);
    if (result) {
      sendNotification({ type: 'success', message: t('detectionTab.deleteSuccess') })
      fetchDetections(getApiParams());
    } else {
      sendNotification({ type: 'error', message: t('detectionTab.deleteError') })
    }
  }, [deleteDetection, fetchDetections, getApiParams, sendNotification, t])

  const openConfirmationModal = useCallback((detectionId: number) => {
    createModal({
      isCloseEnable: false,
      customComponent: (
        <ConfirmationModal
          title={t('Common:confirmEventDeletion')}
          message={t('Common:deleteAlertMsg')}
          leftButtonText={t('Common:cancel')}
          leftButtonDesign='secondary'
          rightButtonText={t('Common:delete')}
          rightButtonDesign='danger'
          rightButtonCallback={() => handleDelete(detectionId)}
        />
      )
    });
  }, [createModal, handleDelete, t]);

  const generateConfigButtons = useCallback((detectionId: number, movie_url, algorithm, detection, image_url): IconButtonData[] => {
    const actions = [
      {
        icon: 'Delete',
        onClick: () => openConfirmationModal(detectionId),
      },
      {
        icon: 'DownloadVideo',
        onClick: () => downloadFile(movie_url),
      }
    ];

    return getActions(actions, algorithm, detection, image_url, push);

  }, [openConfirmationModal, push]);

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = (detections ?? []).map((item) => {

      const {
        id,
        movie_url,
        datetime,
        algorithm,
        detection,
        image_url
      } = item;
      const {
        subject_area = '-',
        subject_number = '',
        subject_kana = '',
        subject_user_type = '',
        subject_full_name = '-',
        subject_type = '-',
        kind = '-'
      } = detection;

      const row: IRowData = {
        header: {
          image: image_url,
          mediaType: 'video',
          mediaUrl: movie_url,
          icon: getIcon(isCarDetection(algorithm), subject_type)
        },
        columns: [
          { text: getMetaType(subject_type, algorithm, kind) },
          { text: isCarDetection(algorithm) ? `${subject_area}${subject_user_type}  ${subject_kana}${subject_number}` : subject_full_name },
          { customComponent: <TableCellRelativeTime eventTime={datetime.toString()} /> },
          { customComponent: <ActionButtons buttonsConfig={generateConfigButtons(id, movie_url, algorithm, detection, image_url)} /> },
        ]
      };
      return row;
    });
    return data.length ? data : INITIAL_ROWS;
  }, [detections, generateConfigButtons, getMetaType]);

  const datePickers: IFilterDatePicker[] = [
    {
      id: 'when',
      buttonIcon: 'DateTime',
      buttonText: t('detectionTab.when'),
      dateMode: 'interval',
      timeMode: 'interval',
      selected: getSelectedDate(selectedFilterValues.when) as DateInterval | undefined,
      dateTimeTextLower: t('Common:dateTo'),
      dateTimeTextUpper: t('Common:dateFrom'),
    }
  ];

  const dropdownConfig: IFilterDropdownConfig[] = [
    {
      id: 'type',
      buttonText: t('detectionTab.type'),
      list: getMetaDetectionList(),
      buttonIcon: 'AnalysisType',
      optionType: 'radio',
      selected: getSelected(selectedFilterValues.type) as IFilterItem
    }
  ]

  const pageSizeLabel = {
    htmlFor: 'select-page-size',
    text: t('Common:filter.itemsPerPage'),
    isSameRow: true,
  };

  const pageLabel = {
    htmlFor: 'select-page',
    text: t('Common:filter.page'),
    isSameRow: true,
  };

  const onChangeFilterValue = useCallback((res: IFilterResult[]) => {
    const selectedValues: ISelectedFilterValues = res.reduce((selectedValues: ISelectedFilterValues, { id, selected }) => {

      switch (id) {
        case 'type': selectedValues[id] = (selected as IFilterItem).value as string;
          break;
        case 'when': selectedValues[id] = selected as DateInterval;
          break;
        default:
          selectedValues[id] = (selected as IFilterItem[]).map(({ value }) => value).join(',');
      }
      return selectedValues;
    },
      { ...initialFilterValues, paramsLoaded: selectedFilterValues.paramsLoaded }
    );
    setSelectedFilterValues({ ...selectedValues });
  }, [selectedFilterValues]);

  const onPageSizeChange = useCallback((val: string) => {
    setPageSize(parseInt(val));
    setCurrentPage(1);
  }, []);

  const onPageChange = useCallback((val: string) => {
    setCurrentPage(parseInt(val));
  }, []);

  const sortCallback = useCallback((ascending: boolean, columnId: string) => {
    setSelectedFilterValues((prev) => ({ ...prev, sortDirection: ascending ? 'asc' : 'desc', sortBy: columnId }))
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(detectionCount / pageSize));
  }, [detectionCount, pageSize]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    fetchDetections(getApiParams());
  }, [getApiParams, pageSize, currentPage, selectedFilterValues, fetchDetections]);


  useEffect(() => {
    if (isLoading) return;
    setRowData(generateRowData());
  }, [generateRowData, isLoading]);

  useEffect(() => {
    setSelectedFilterValues(readParams(historyParams, initialFilterValues));
  }, [historyParams]);

  return (
    <Container>
      <FilterWrapper>
        <FilterBar
          searchersConfig={[]}
          dropdownsConfig={dropdownConfig}
          datePickersConfig={datePickers}
          filtersTitle={t('Common:filter.filters') + ':'}
          resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
          resultsDateFormat={'yyyy/MM/dd HH:mm'}
          totalResults={rowData.length === 1 && rowData[0].columns.length === 0 ? 0 : rowData.length}
          onChangeCallback={onChangeFilterValue}
        />
      </FilterWrapper>
      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
        isLoading={isLoading}
        emptyTableText={t('Common:noDetectionsFound')}
        loadingText={t('Common:loadingData')}
        sortCallback={sortCallback}
        hasThumbnail
        hasHeaderGroups
        hasTypeIcon
        defaultAscending={ selectedFilterValues.sortDirection === 'asc' ? true : false }
      />
      {rowData.length !== 0 &&
        <Pagination
          pageSizeOptions={sizeOptions}
          totalPages={totalPages}
          defaultPage={currentPage}
          defaultPageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          pageSizeLabel={pageSizeLabel}
          pageLabel={pageLabel}
        />}
    </Container>
  );
};

export default DetectionTab;