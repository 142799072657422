import React, { useCallback, useMemo, useState } from 'react';

type MapItem = 'camera' | 'zone' | 'location';

interface IMapHover {
  hoverType: MapItem
  cameraId: number | null
  zoneId: number | null
  locationId: number
}

export type IHoverItem = IMapHover | null;

interface IMapHoverContext {
  hoverItem: IHoverItem
  updateHoverItem: (newHover: IHoverItem) => void
}

const defaultHoverContext: IMapHoverContext = {
  hoverItem: null,
  updateHoverItem: (_newHover: IHoverItem) => console.log('verify MapHover Provider is set correctly')
};

const MapHoverContext = React.createContext<IMapHoverContext>(defaultHoverContext);

const MapHoverProvider: React.FC = ({ children }) => {
  const [hoverItem, setHoverItem] = useState<IHoverItem>(null)

  const updateHoverItem = useCallback((newHover: IHoverItem) => {
    setHoverItem(newHover)
  }, [])

  const hoverContext = useMemo(() => ({ hoverItem, updateHoverItem }), [hoverItem, updateHoverItem])

  return (
    <MapHoverContext.Provider value={hoverContext}>
      {children}
    </MapHoverContext.Provider>
  );
}

export { MapHoverContext }
export type { IMapHover }
export default MapHoverProvider