import { FC } from 'react';
import { Icon } from 'scorer-ui-kit';
import styled from 'styled-components';
import { EllipsisStyles } from '../../Style';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 23px;
  margin-top: 21px;
  margin-right: 30px;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 23px;
  align-items: center;
`;

const Value = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.18px;
  color: #949ea7;
  max-width: 102px;
  ${EllipsisStyles}
`;

const CircleIcon = styled.div<{ color?: string }>`
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: #87d58f;
  margin-left: 2px;
`;

const ItemInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Type = styled.div`
  color: hsl(197, 29%, 71%);
  font-size: 10px;
`;

interface ICameraBasicDetails {
  status: string,
  locationName: string,
  detectionTypes: string,
  zoneName: string
}

interface IDetailsItem {
  icon: string
  value: string
  type: string
}

const DetailsItem = ({ icon, value, type }: IDetailsItem) => (
  <ItemsContainer>
    {icon === 'Circle' ? <CircleIcon /> : <Icon icon={icon} size={15} color='dimmed' weight='light' />}
    <ItemInformation>
    <Type>{type || '-'}</Type>
    <Value title={value}>{value || '-'}</Value>
    </ItemInformation>

  </ItemsContainer>
);

const CameraBasicDetails: FC<ICameraBasicDetails> = ({ locationName, detectionTypes, zoneName }) => {
  const { t } = useTranslation(['CameraDetails']);

  return (
    <Container>
      {/* <DetailsItem icon='Circle' value={status} /> */} {/* todo show status in next phase */}
      <DetailsItem icon='Location' value={locationName} type={t('location')} />
      <DetailsItem icon='Zone' value={zoneName} type={t('zone')}/>
      <DetailsItem icon='Analyse' value={detectionTypes} type={t('algorithms')} />
    </Container>
  )
};

export default CameraBasicDetails;