import * as React from 'react';

function SvgProductMapLayers(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M23.167 6.45l-10.21-5.278a1.543 1.543 0 00-1.415 0L1.332 6.45a.616.616 0 000 1.094l10.21 5.281c.444.23.972.23 1.416 0L23.167 7.55a.616.616 0 000-1.094V6.45z" />
        <path d="M5.35 9.625l-4.017 2.078a.616.616 0 000 1.094l10.209 5.281c.444.23.972.23 1.416 0l10.209-5.281a.616.616 0 000-1.094L19.15 9.625" />
        <path d="M5.35 14.875L1.333 16.95a.616.616 0 000 1.094l10.209 5.281c.444.23.972.23 1.416 0l10.209-5.275a.616.616 0 000-1.094l-4.017-2.081" />
      </g>
    </svg>
  );
}

export default SvgProductMapLayers;
