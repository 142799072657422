import { Content } from 'scorer-ui-kit';
import Theme from 'scorer-ui-kit/dist/themes/light/light';
import styled, { createGlobalStyle, css } from 'styled-components'

const GlobalStyle = createGlobalStyle<{ theme: typeof Theme }>`
  * , body{
    box-sizing: border-box;
  }
  body, html {
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    height: 100%;
  }
  html {

  }
  body {
    font-family: ${({ theme }) => theme.fontFamily.ui};
    color: hsl(0, 0%, 50%);;

  }
  #root {
    height: 100%;
    overflow: auto;
    ${({ theme }) => theme.styles.global.background};
    background-repeat: no-repeat, repeat;

  }

`

export const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

export const PageHeaderWrapper = styled.div`
  max-width: 610px !important;
`;

export const EllipsisStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RowCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default GlobalStyle;

