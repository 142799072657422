import { useTranslation } from 'react-i18next';
import React from 'react';
import { TextAreaField, TextField } from 'scorer-ui-kit';
import styled from 'styled-components';

const Container = styled.div``;

const SmallTitle = styled.div`
  color: hsl(210, 6%, 47%);
  font-size: 14px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  color: hsl(210, 6%, 47%);
  font-size: 20px;
`;

const Header = styled.div`
  margin: 2px 0 30px 0;
`;

const StyledTextArea = styled(TextAreaField)`
  ::-webkit-scrollbar {  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

interface IEmailPreviewModal {
  name: string,
  subject: string
  body: string
}

const EmailPreviewModal: React.FC<IEmailPreviewModal> = ({name, subject, body}) => {
  const { t } = useTranslation(['Common']);

  return(
    <Container>
      <Header>
        <SmallTitle>{t('eventRules.templatePreview')}</SmallTitle>
        <Title>{name}</Title>
      </Header>

      <TextField
        name='subject'
        label={t('eventRules.subject')}
        fieldState={'default'}
        disabled
        defaultValue={subject}
      />
      <StyledTextArea
        name='mail-body'
        label={t('eventRules.emailBody')} fieldState={'default'}
        defaultValue={body}
        rows={8}
        disabled
      />

    </Container>
  );
};

export default EmailPreviewModal;