import React, { useEffect, useState } from 'react';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import { Icon } from 'scorer-ui-kit';
import styled from 'styled-components';
import { Camera } from '../../../hooks/useCameras';
import { Zone } from '../../../hooks/useZones';
import { Location } from '../../../hooks/useLocations';
import { getBreadCrumbs } from '../../../utils';
const { locationId, zoneId, cameraId } = DASHBOARD_PARAMS;

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-left: 20px;
`;

const LayerGroup = styled.div`
  display: flex;
  align-items: center;
  color: hsl(0, 0%, 50%);
  font-size: 12px;
`;

const LayerText = styled.div`
  margin-left: 8px;
`;

const CrumbList = styled.div`
  display: flex;
  align-items: center;
  color: hsl(0, 0%, 50%);
  font-size: 14px;
`;

const CrumbItem = styled.div`
  display: flex;
  align-items: baseline;
  overflow: hidden;
`;


const CrumbText = styled.div`
  margin: 0 10px;
`;

const IconWrapper = styled.div`
    & > div {
      display: flex;
      align-items: center;
    }
`;

interface ITopBreadCrumbs {
  locations: Location[]
  zones: Zone[]
  cameras: Camera[]
}

const TopBreadCrumbs: React.FC<ITopBreadCrumbs> = ({ locations, zones, cameras, ...props }) => {
  const [breadcrumbList, setBreadcrumbList] = useState<string[]>([]);
  const { getParam } = useSelected();
  const layer = getParam(DASHBOARD_PARAMS.layer);
  const camId = getParam(cameraId);
  const locId = getParam(locationId);
  const zId = getParam(zoneId);

  useEffect(() => {

    setBreadcrumbList(getBreadCrumbs(locId, zId, camId, cameras, zones, locations))

  }, [getParam, cameras, locations, zones, locId, zId, camId]);

  return (
    <Container {...props}>
      <LayerGroup>
        <IconWrapper><Icon icon='ProductMapLayers' size={16} color='dimmed' weight='light' /></IconWrapper>
        <LayerText>{`Layer ${layer} ::`}</LayerText>
      </LayerGroup>
      <CrumbList>
        {breadcrumbList.map((crumb, index) => {
          return (
            <CrumbItem key={index} >
              {(index !== 0) && < IconWrapper > <Icon icon='Right' color='dimmed' size={9} weight='light' /></IconWrapper>}
              <CrumbText>{crumb}</CrumbText>
            </CrumbItem>
          )
        })}
      </CrumbList>
    </Container >
  );
};

export default TopBreadCrumbs;