
export const ZOOM_LAYER_TOP = -3  // AKA Locations Layer (higher)
export const ZOOM_LAYER0 = -2  // AKA Locations Layer (higher)
export const ZOOM_LAYER1 = -1; // AKA Locations Layer
export const ZOOM_LAYER2 = 0; // AKA Zones Layer
export const ZOOM_LAYER3 = 1 // AKA Camera Layer

export const mapOverlayZIndex = 320;
export const camerasZIndex = 340;
export const cameraZIndex = 335;
export const cameraViewZIndex = 330;
export const zoneZIndex = 360;
export const locationsZIndex = 380;
export const mapStatsZIndex = 600;