import * as React from 'react';

function SvgNotifications(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={'non-scaling-stroke'}
      >
        <path d="M10 21.75a2.087 2.087 0 004.005 0M12 3V.75M12 3a7.5 7.5 0 017.5 7.5c0 7.046 1.5 8.25 1.5 8.25H3s1.5-1.916 1.5-8.25A7.5 7.5 0 0112 3z" />
      </g>
    </svg>
  );
}

export default SvgNotifications;
