import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LatLngTuple, Polygon as IPolygon } from 'leaflet';
import { Polygon } from 'react-leaflet';

const StyledPolygon = styled(Polygon)`
  fill-opacity: 75%;
  fill: #9097FF;
  stroke: #9097FF;

  &.alerts {
    fill: hsl(359, 63%, 67%);
    stroke: hsl(359, 63%, 67%);
  }
  &:hover {
    fill-opacity: 90%;
  }
`;

interface IZonePolygon {
  positions: LatLngTuple[]
  hasAlerts?: boolean
  onClick?: () => void
  onHover?: () => void
  onMouseLeave?: () => void
}

const ZonePolygon: React.FC<IZonePolygon> = ({
  positions,
  hasAlerts = false,
  onClick = () => { },
  onHover = () => { },
  onMouseLeave = () => { }
}) => {

  const polygonRef = useRef<IPolygon>(null);

  useEffect(() => {
    if (!polygonRef.current) return;
    const ele = polygonRef.current.getElement() as SVGPathElement;
    if (!ele) return;

    if (hasAlerts) {
      ele.classList.add('alerts');
    } else {
      ele.classList.remove('alerts');
    }

  }, [hasAlerts])

  return (
    <StyledPolygon
      ref={polygonRef}
      interactive
      {...{ positions }}
      eventHandlers={{
        click: () => {
          onClick();
        },
        mouseover: () => {
          onHover();
        },
        mouseout: () => {
          onMouseLeave();
        }
      }}
    />
  );
};

export default ZonePolygon;