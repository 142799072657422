import styled, { css } from 'styled-components';

export const animation = {
  easing: {
    primary: {
      inOut: 'cubic-bezier(0.65, 0, 0.35, 1)',
      out: 'cubic-bezier(0.33, 1, 0.68, 1)',
      in: 'cubic-bezier(0.32, 0, 0.67, 0)',
    }
  },
};

export const resetButtonStyles = css`
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  user-select: none;
`;

export const SlimInput = styled.input`
  ${({ theme }) => css`
    min-height: 20px;
    font-family: ${theme.fontFamily.data};
    border: 1px solid ${theme.styles.form.input.default.normal.borderColor};
    ${theme.typography.form.input.value.compact};
    &::placeholder {
      ${theme.typography.form.input.placeholder.compact};
    }
  `};

  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  outline: none;
  background: transparent;
  cursor: pointer;
`;