import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Content, PageHeader, Label, Button, TextField, Spinner, ButtonWithLoading, useModal, AvatarUploader, ButtonWithIcon, TextAreaField, RadioButton, useTo, useNotification } from 'scorer-ui-kit';
import styled from 'styled-components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AsteriskSVG } from '../svg';
import DeleteConfirmationModal from '../components/people/DeleteConfirmationModal';
import PhotoGuidelinesModal from '../components/people/PhotoGuidelinesModal';
import { IPersonPayload, usePerson } from '../hooks/usePerson';

const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const RequiredLabel = styled(Label)`
  margin-left: 6px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.79;
  color: #8cbee2;
  margin-top: -5px;
`;

const RightDiv = styled.div`
  display: flex;
  margin-top: 31px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 62%;
  max-width: 523px;
  margin-right: 48px;
  margin-bottom: 20px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TagsWrapper = styled.div`
  margin-top: 44px;
  margin-bottom: 33px;
`;

const TextArea = styled(TextAreaField)`
  height: 180px !important;
  width: 100% !important;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-right: 53px;
`;

const LeftButton = styled(Button)`
  margin-right: 10px;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 24px;
  label {
    width: 100%;
  }
  svg {
    margin-right: -33px;
    margin-left: 9px;
    margin-top: 6px;
  }
`;

const FormInputs = styled.div`
  border-right: 1px solid #eee;
  padding-right: 53px;
`;

const GuideLineLabel = styled.div`
  font-size: 14px;
  margin-top: 11px;
  text-decoration: underline;
  color: #8cbee2;
  font-weight: 500;
  font-style: italic;
  line-height: 1.79;
  cursor: pointer;
  text-align: center;
`;

const ActionLabel = styled(Label)`
  font-size: 14px;
  font-weight: 500;
  color: #8b9196;
  margin-bottom: 17px;
`;

const PhotographContainer = styled.div`
  display: flex;
`;

const CheckingButton = styled(ButtonWithLoading)`
  margin-top: 20px;
`;

const AlertBarMessage = styled.div`
  font-size: 12px;
  margin-top: 20px;
  line-height: 2.08;
  color: #8b9196;
`;

const AlertContainer = styled(ButtonWithIcon)`
  margin-top: 35px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  height: 30px;
  padding: 0px 8px 18px 8px;
  border-radius: 5px;
  background-color: #de6b6b;
`;

const DeleteButtonAction = styled.div`
  margin-top: 171px;
`;

const SaveButton = styled(ButtonWithLoading)`
`;

const DeleteButton = styled(ButtonWithIcon)`
  padding: 0 20px 0 9px;
`;

const AlertSection = styled.div`
  margin-left: 20px;
`;

const AvatarWrapper = styled.div`
  display: block;
`;

const PhotographLabel = styled(Label)`
  margin-bottom: -8px;
  margin-right: 5px;
  span {
    margin-bottom: 0px;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  svg {
    width: 9px;
  }
`;

const FieldLabel = styled(Label)`
  margin-bottom: 0;
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 55px;
  margin-bottom: 44px;
`;

const RadioFieldWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  label {
    flex-grow: 0;
  }
`;

interface IAlert {
  type: 'error' | 'warning' | 'info' | 'success';
  message: string;
  key: Date
}

interface Params {
  personID: string
}

interface IState {
  photo_url: string,
  isUnknown: boolean
}

const AddEditPerson: React.FC = () => {
  const { personID }: Params = useParams();
  const { photo_url = '' } = useLocation()?.state as IState || {};
  const [form, setForm] = useState<IPersonPayload>({ full_name: '', category: '', tags: '', description: '', type: 'LISTED', photo_url: photo_url, features: '' });
  const [photographAlert, setPhotographAlert] = useState<IAlert | null>(null);
  const [photograph, setPhotograph] = useState<File | null>(null);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [faceChecking] = useState<boolean>(false);
  const [imageChanged, setImageChanged] = useState(false);
  const { person, personLoading, actions: { fetchPerson, uploadPhoto, updatePerson, deletePerson } } = usePerson();

  const { createModal, setModalOpen } = useModal();

  const { t } = useTranslation(['AddEditPerson', 'People', 'Common']);
  const { push } = useHistory();
  const to = useTo();
  const { sendNotification } = useNotification();

  useEffect(() => {
    if (personID) {
      fetchPerson(parseInt(personID));
    }
  }, [fetchPerson, personID]);

  const getFileFromUrl = useCallback(async (url: string, name: string, defaultType = 'image/jpeg') => {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get('content-type') || defaultType,
    });
  }, []);

  useEffect(() => {
    const getPerson = async () => {
      if (person) {
        person && setForm(person);
        setPhotograph(await getFileFromUrl(person?.photo_url, person?.photo_url?.substring(person?.photo_url?.lastIndexOf('/') + 1, person?.photo_url?.length)));
      }
      if (photo_url) {
        setPhotograph(await getFileFromUrl(photo_url, photo_url?.substring(photo_url?.lastIndexOf('/') + 1, photo_url?.length)));
      }
    }
    getPerson();
  }, [person, getFileFromUrl, photo_url]);

  const handleOnChange = useCallback((key: string) => ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({ ...form, [key]: value });
  }, [form]);

  const validateForm = useCallback(() => {
    if (!form.full_name?.trim().length) {
      sendNotification({ type: 'error', message: t('fullNameIsRequired') })
      return false;
    }
    if (photograph === null || !(photograph?.size > 0)) {
      sendNotification({ type: 'error', message: t('photographIsRequired') })
      return false;
    }
    return true;
  }, [form.full_name, photograph, sendNotification, t]);

  const onSaveChange = useCallback(async () => {
    if (!validateForm()) return;
    setSaveLoading(true);
    let payload = { ...form };

    if (photograph && imageChanged) {
      const data = new FormData();
      data.append('file', photograph, photograph.name);
      const { message, img_path, features } = await uploadPhoto(data);
      if (!img_path) {
        setPhotographAlert({
          key: new Date(),
          message: t(message),
          type: 'error'
        });
        setSaveLoading(false);
        return;
      } else {
        payload.photo_url = img_path;
        payload.features = features;
      }
    } else {
      payload.photo_url = person?.photo_url || photo_url;
    }
    const res: boolean = await updatePerson(payload, (personID ? 'PUT' : 'POST'));

    if (res) {
      sendNotification({ type: 'success', message: personID ? t('Person updated successfully') : t('Person added successfully') })
      push('/people');
    } else {
      sendNotification({ type: 'error', message: personID ? t('Failed to update person') : t('Failed to add person') })
    }
    setSaveLoading(false);
  }, [personID, form, photograph, imageChanged, person?.photo_url, photo_url, push, uploadPhoto, validateForm, updatePerson, sendNotification, t]);

  const appendToFilename = (filename: string, type: string) => {
    if (filename && filename.indexOf('newAvatar') !== -1) {
      return filename.replace('newAvatar', Date.now().toString() + type.replace('image/', '.'));
    }
    return Date.now().toString();
  };

  const onChangeImage = useCallback((imgFile: File) => {
    const imgURL = new File([imgFile], appendToFilename(imgFile.name, imgFile.type), { type: imgFile.type });
    if ((imgURL.size / 1024) > 2048) {
      setPhotographAlert({
        key: new Date(),
        message: 'photographShouldBeLessThan2MB',
        type: 'error'
      });
      return;
    }
    setForm((prev) => ({ ...prev, photo_url: URL.createObjectURL(imgURL) }))
    setPhotograph(imgURL);
    setImageChanged(true);
  }, []);

  const onRemoveImage = useCallback(() => {
    setPhotographAlert(null);
    setPhotograph(null);
  }, []);

  const onDeleteConfirmation = useCallback(async () => {
    setModalOpen(false);
    const result = await deletePerson(parseInt(personID));

    if (result) {
      sendNotification({ type: 'success', message: t('Person deleted successfully') })
      to('/people')();
    } else {
      sendNotification({ type: 'error', message: t('Failed to delete person') })
    }
  }, [setModalOpen, deletePerson, sendNotification, t, to, personID]);

  const openDeleteConfirmModal = useCallback(() => {
    createModal({
      isCloseEnable: true,
      closeText: t('Common:close'),
      width: '580px',
      padding: false,
      customComponent: <DeleteConfirmationModal fullName={form.full_name!} onDeleteConfirmation={onDeleteConfirmation} />,
    });
  }, [createModal, form.full_name, onDeleteConfirmation, t]);

  const onDelete = useCallback(() => {
    openDeleteConfirmModal();
  }, [openDeleteConfirmModal]);

  const photoGuidelinesModel = useCallback(() => {
    createModal({
      isCloseEnable: true,
      closeText: t('Common:close'),
      width: '580px',
      padding: false,
      customComponent: <PhotoGuidelinesModal />,
    });
  }, [createModal, t]);

  const onChangeRadioHandler = useCallback((value) => {
    setForm((prev) => ({ ...prev, type: value }));
  }, []);

  return (
    <Content>
      <PageHeaderContainer>
        <PageHeader title={personID ? t('editPerson') : t('People:addPerson')} areaTitle={t('people')} areaHref='/people' icon='RecognitionProfile' />
        <RightDiv>
          <AsteriskSVG />
          <RequiredLabel htmlFor='' labelText={t('denotesARequiredField')} />
        </RightDiv>
      </PageHeaderContainer>

      {
        (personLoading && personID) ? (
          <Spinner size='large' styling='secondary' />
        ) :
          (
            <Wrapper>
              <LeftContainer>
                <FormInputs>
                  <NameContainer>
                    <TextField
                      fieldState='default'
                      required
                      label={t('fullName')}
                      name='full_name'
                      value={form.full_name}
                      onChange={handleOnChange('full_name')}
                      maxLength={32}
                    />
                    <AsteriskSVG />
                  </NameContainer>

                  <RadioFieldWrapper>
                    <FieldLabel htmlFor='' labelText={t('type')} />

                    <RadioButtonsWrapper>
                      <Label htmlFor='LISTED' labelText={t('listed')} rightAlign>
                        <RadioButton
                          currentChecked={form?.type}
                          id='LISTED'
                          value='LISTED'
                          onChangeCallback={onChangeRadioHandler}
                        />
                      </Label>
                      <Label htmlFor='IMPORTANT' labelText={t('important')} rightAlign>
                        <RadioButton
                          currentChecked={form?.type}
                          id='IMPORTANT'
                          value='IMPORTANT'
                          onChangeCallback={onChangeRadioHandler}
                        />
                      </Label>
                    </RadioButtonsWrapper>
                  </RadioFieldWrapper>

                  <TextField
                    fieldState='default'
                    label={t('category')}
                    name='category'
                    placeholder='e.g. Sales Team'
                    value={form.category}
                    onChange={handleOnChange('category')}
                    maxLength={32}
                  />

                  <TagsWrapper>
                    <TextField
                      fieldState='default'
                      label={t('tags')}
                      name='tags'
                      placeholder='e.g. Priority, Parking'
                      value={form.tags}
                      onChange={handleOnChange('tags')}
                    />
                  </TagsWrapper>

                  <TextArea
                    rows={8}
                    name='description'
                    label={t('notes')}
                    fieldState='default'
                    value={form.description}
                    onChange={handleOnChange('description')}
                  />
                </FormInputs>

                <ButtonsContainer>
                  <LeftButton design='secondary' onClick={to('/people')}>{t('cancel')}</LeftButton>
                  <SaveButton loading={saveLoading} onClick={onSaveChange}>{personID ? t('saveChanges') : t('People:addPerson')}</SaveButton>
                </ButtonsContainer>

              </LeftContainer>

              <RightContainer>
                <PhotographContainer>
                  <AvatarWrapper>
                    <LabelWrapper>
                      <PhotographLabel htmlFor='' labelText={t('photograph')} />
                      <AsteriskSVG />
                    </LabelWrapper>
                    <AvatarUploader
                      onAvatarUpdate={onChangeImage}
                      buttonText={t('selectFile')}
                      buttonTextReplace={t('replacePhoto')}
                      photoText={t('dropPhoto')}
                      title=''
                      currentImg={form.photo_url}
                      cropToolTitle={t('cropImage')}
                      cropToolCancelTxt={t('cancel')}
                      cropToolConfirmTxt={t('cropSave')}
                      uploaderCropText={t('cropImage')}
                      onRemove={onRemoveImage}
                      deletePhotoText={t('remove')}
                    />
                    {faceChecking && <CheckingButton loading={faceChecking} size='small'>{t('checking')}</CheckingButton>}
                    <GuideLineLabel onClick={photoGuidelinesModel}>{t('photoGuidelines')}</GuideLineLabel>
                  </AvatarWrapper>

                  {photographAlert &&
                    <AlertSection>
                      <AlertContainer size='normal' icon='Warning' design='danger' position='left'>{t('issueDetected')}</AlertContainer>
                      <AlertBarMessage>{t(photographAlert.message)}</AlertBarMessage>
                    </AlertSection>}
                </PhotographContainer>

                {personID &&
                  <DeleteButtonAction>
                    <ActionLabel htmlFor='' labelText={t('advanceAction')} />
                    <DeleteButton icon='Delete' design='secondary' onClick={onDelete} size='small' position='left'>{t('deleteUser')}</DeleteButton>
                  </DeleteButtonAction>}

              </RightContainer>
            </Wrapper>
          )
      }
    </Content>
  );
};
export default AddEditPerson;
