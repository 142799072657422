import format from 'date-fns/format';
import sub from 'date-fns/sub';
import { useCallback, useState } from 'react';
import { dateTimeFormat } from '../utils';

export type IFileRecordType = 'images'|'clips'|'videos'
export interface ICameraFiles {
  file_name: string
  size: number,
  path: string,
  file_type: string
  record_type: IFileRecordType
  date_time: string,
}

export interface IOptionsFetchCameraFiles {
  limit?: number
  offset?: number
  startTime?: Date
  endTime?: Date
  cameraId: string
  recordType?: IFileRecordType
  sortBy?: string;
  sortDirection?: string;
}

interface IFetchFilesResponse {
  message: string
  files: ICameraFiles[]
  info: {
    total: number
    limit: number
    offset: number
  }
}


export const useCameraFiles = () => {
  const [cameraFiles, setCameraFiles] = useState<ICameraFiles[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);

  const fetchCameraFiles = useCallback(async (options?: IOptionsFetchCameraFiles ) => {

    const {
      limit = 100,
      offset = 0,
      startTime,
      endTime,
      cameraId,
      recordType,
      sortBy,
      sortDirection,
    } = (options || {});

      const searchString = new URLSearchParams({
        limit: '' + limit,
        offset: '' + offset,
        start_time: format(startTime ? startTime : sub(new Date(), { days: 12 }), dateTimeFormat),
        end_time: format(endTime ? endTime : new Date(), dateTimeFormat),
        ...(recordType !== undefined ? {content_type: recordType}:{}),
        ...(sortBy ? { sort_by: `${sortBy}` } : {}),
        ...(sortDirection ? { sort_direction: `${sortDirection}` } : {})

      });
    setIsLoading(true);
    try {
      const response = await fetch(`/api/camera/${cameraId}/files?${searchString.toString()}`);
      if(response.ok) {
        const {
          files,
          info: {
            total,
          }
        } = await response.json() as IFetchFilesResponse

        setCameraFiles(files);
        setTotalFiles(total);
      } else {
        console.error(`Error fetching Camera: ${response.statusText}`);
        return false;
      }

      return true;

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false)
    }
  },[])

  const deleteCameraFile = useCallback(async ({cameraID, filename, recordType} ) => {

    setIsLoading(true);
    try {
      
      const response = await fetch(`/api/camera/${cameraID}/files/${recordType}/${filename}`, {
        method: 'DELETE',
      });

      if(response.ok) {
        await response.json() as IFetchFilesResponse
      } else {
        console.error(`Error deleting Camera: ${response.statusText}`);
        return false;
      }

      return true;

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false)
    }
  },[])

  return {
    totalFiles,
    isLoading,
    cameraFiles,
    actions : {
      fetchCameraFiles,
      deleteCameraFile
    }
  }
}