import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypeTable } from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled from 'styled-components';
import { NumberPlate } from '../../hooks/useNumberPlates';
import { EMPTY_ROWS } from '../../utils';

const Container = styled.div`
  margin: 40px 0 10px 0;
`;

interface ICSVTable {
  platesList: NumberPlate[]
}

const UploadTable: React.FC<ICSVTable> = ({ platesList }) => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const { t } = useTranslation(['NumberPlates', 'Common']);

  const tableColumns: ITableColumnConfig[] = [
    {
      header: t('municipality'),
      cellStyle: 'firstColumn',
    },
    {
      header: t('name'),
      cellStyle: 'normalImportance',
    }
  ];

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = (platesList ?? []).map((numberPlate) => {

      const {
        type,
        name,
        area,
        user_type,
        kana,
        number
      } = numberPlate;

      const row: IRowData = {
        header: {
          icon: type === 'IMPORTANT' ? 'VehicleBlacklist' : 'VehicleWhitelist'
        },
        columns: [
          { text: `${area}${user_type} ${kana}${number}` || '-' },
          { text: name || '-' },
        ]
      };
      return row;
    });
    return data.length ? data : EMPTY_ROWS;
  }, [platesList]);

  useEffect(() => {
    setRowData(generateRowData())
  }, [generateRowData, platesList])

  return (
    <Container>
      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
      />
    </Container>
  );
};

export default UploadTable;