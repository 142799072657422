import { IPipelineAction } from '..';
import i18n from 'i18next';
const {t} = i18n;

export const Basic : IPipelineAction = {
  meta: t('eventRules.action'),
  title: t('eventRules.action'),
  editing: false,
  subtype: 'Basic',
  data: {},
  icon: 'Critical',
}

export const NewNode : IPipelineAction = {
  ...Basic,
  subtype: 'NewNode',
  title: t('eventRules.newAction'),
  data: {}
}

export const ControlRelay : IPipelineAction = {
  ...Basic,
  title: t('eventRules.controlRelay'),
  subtype: 'ControlRelay',
  icon: 'LayoutGrid',
  action_type: 'relay',
  relations: {
    editing: true,
    title: t('eventRules.cameraToRelay'),
    linkedType: 'gate'
  },
  data: {
    links:[]
  }
}
// export const ControlLight : IPipelineAction = {
//   ...Basic,
//   title: t('eventRules.controlLight'),
//   icon: 'LayoutGrid',
//   subtype: 'ControlLight',
//   action_type: 'relay',
//   relations: {
//     editing: true,
//     title: t('eventRules.cameraToRelay'),
//     linkedType: 'patlight',
//     config: [],
//   },
//   data: {
//     links:[]
//   }
// }

// export const ControlLight : IPipelineAction = {
//   ...Basic,
//   title: 'Control Light',
//   action_type: 'relay',
//   relations: {
//     title: 'Camera to Gate',
//     linkedType: 'patlight',
//     config: [],
//     links: []
//   }
// }

export const SendAlert : IPipelineAction = {
  ...Basic,
  icon: 'Notifications',
  title: t('eventRules.sendAlert'),
  subtype: 'SendAlert',
  action_type: 'alert'
}

export const SendEmail : IPipelineAction = {
  ...Basic,
  icon: 'Mail',
  title: t('eventRules.sendEmail'),
  subtype: 'SendEmail',
  action_type: 'email',
  config:[{
    name: 'email_action_id',
    fieldType: 'text'
  }]
}

export type INodeActionSubTypes = 'Basic'  | 'ControlRelay' | 'SendAlert' | 'SendEmail';

const ActionNodes = {
  Basic,
  NewNode,
  ControlRelay,
  SendEmail,
  SendAlert
}

export default ActionNodes;