import { useCallback, useContext } from 'react'
import { MapHoverContext } from '../context/MapHoverContext';

interface ZoneHover {
  zoneId: number
  locationId: number
}

interface CameraHover {
  cameraId: number
  zoneId: number
  locationId: number
}

export const useMapHover = () => {
  const { hoverItem, updateHoverItem } = useContext(MapHoverContext);

  const updateLocationHover = useCallback((locationId: number | null) => {
    if (locationId === null) {
      updateHoverItem(null)
      return;
    }

    updateHoverItem({ hoverType: 'location', locationId, cameraId: null, zoneId: null })
  }, [updateHoverItem])

  const updateZoneHover = useCallback((newValue: ZoneHover | null) => {

    if (newValue === null) {
      updateHoverItem(null)
      return;
    }
    const { zoneId, locationId } = newValue;

    updateHoverItem({ hoverType: 'zone', zoneId, locationId, cameraId: null });
  }, [updateHoverItem]);

  const updateCameraHover = useCallback((newValue: CameraHover | null) => {
    if (newValue === null) {
      updateHoverItem(null)
      return;
    }

    const { cameraId, zoneId, locationId } = newValue;

    updateHoverItem({ hoverType: 'camera', cameraId, zoneId, locationId })
  }, [updateHoverItem]);

  return {
    hoverItem,
    updateHoverItem,
    updateLocationHover,
    updateZoneHover,
    updateCameraHover,
  }

}