import React, { useCallback, useState, useRef } from 'react';
import { Icon, IFilterItem, resetButtonStyles, useClickOutside } from 'scorer-ui-kit';
import styled, { css } from 'styled-components';

const Container = styled.div`
  padding: 5px;
  position: relative;
`;

const IconWrapper = styled.div`
  margin: 0 2px;
  > div {
    display: flex;
  }
`;

const TransparentButton = styled.button`
  ${resetButtonStyles};
  color: hsl(205, 77%, 64%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  padding: 0 4px;
  margin-bottom: 3px;
  border-radius: 3px;

  ${({ theme }) => theme && css`

  &:hover:enabled {
    ${theme.styles.filters.filterButton.hover};
    ${theme.typography.filters.filterButton.hover};
  }
  &:active:enabled {
    ${theme.styles.filters.filterButton.active};
    ${theme.typography.filters.filterButton.active};
  }

  &:hover: enabled, &:active:enabled {
      ${IconWrapper} div {
        [stroke]{
          transition: stroke ${theme.animation.speed.faster} ${theme.animation.easing.primary.inOut};
          stroke: #FFF;
        }
      }
    }
  `};
`;


const ButtonText = styled.div`
  margin: 0 5px;
`;

const ContentBox = styled.div<{ isOpen: boolean, disabled: boolean }>`
  min-width: 120px;
  z-index: 100;
  position: absolute;

  ${({ isOpen, disabled }) => !isOpen && css`
    display: ${isOpen ? 'inline-block' : 'none'};
    display: ${disabled && 'none'};
    top: 0;
    left: -3px;
    transform: translateY(calc( -100% - 5px ));
  `};

  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 4px 9px 0 hsla(204, 22%, 67%, 0.07);
  border-right: solid 1px hsl(207, 16%, 86%);
  border-bottom: solid 1px hsl(207, 16%, 86%);
  border-left: solid 1px hsl(207, 16%, 86%);
  ${({ theme }) => theme.styles.filters.dropdownContainer.background};
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  display: block;
  width: 100%;
  color: hsl(0, 0%, 56%);
  font-size: 12px;
  font-weight: 500;
  margin-left: 12px;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
`;

const DropdownOption = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 3px;
  ${({ theme: { styles }, selected }) => styles && css`
    &:hover {
      cursor: pointer;
      ${Title} {
        color: ${styles.form.checkbox.checked.hover.backgroundColor};
      }
    }

    ${selected && css`
      ${Title} {
        color: ${styles.form.checkbox.checked.default.backgroundColor};
      }
    `};
  `}
`;



interface IClearDropdown {
  icon: string // button
  buttonText: string
  disabled?: boolean
  list?: IFilterItem[];
  selected?: IFilterItem
  onToggleOpenCallback?: () => void
  onCloseCallback?: () => void
  onSelect?: (newSelected: IFilterItem) => void
}

const ClearDropdown: React.FC<IClearDropdown> = ({
  icon,
  buttonText,
  disabled = false,
  list,
  selected,
  onSelect = () => { },
  onCloseCallback = () => { },
  ...props }) => {

  const mainRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = useCallback(() => {
    onCloseCallback();
    setIsOpen(false);
  }, [onCloseCallback])

  useClickOutside(mainRef, handleClose);

  return (
    <Container ref={mainRef} {...props}>
      <TransparentButton onClick={() => { setIsOpen((prev) => !prev) }} >
        <IconWrapper><Icon icon={icon} color='primary' size={12} /></IconWrapper>
        <ButtonText>{buttonText}</ButtonText>
        <IconWrapper> <Icon icon={isOpen ? 'Up' : 'Down'} size={6} /></IconWrapper>
      </TransparentButton>
      <ContentBox {...{ isOpen, disabled }}>
        {list?.map((item, index) => {
          return (
            <DropdownOption
              key={index}
              selected={selected ? (item.value === selected.value) : false}
              onClick={() => onSelect(item)}
            >
              <Title>{item.text}</Title>
            </DropdownOption>
          )
        })}
      </ContentBox>
    </Container>
  );
};

export default ClearDropdown;