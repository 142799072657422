import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

import { Button, SelectField } from 'scorer-ui-kit';
import { INodeTargets } from '..';
import TriggerNodes from '../Definitions/TriggerNodes';
import ConditionNodes from '../Definitions/ConditionNodes';
import ActionNodes from '../Definitions/ActionNodes';
import { useTranslation } from 'react-i18next';

const NodeButtonRow = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin-top: 10px;

  button {
    margin-left: 10px;
  }
`

interface INodeSectionAdd {
  type: INodeTargets;
  index: number;
  handlerCancel?: any
  handlerUpdate?: any
  filterSubtypes?: string[]
}

const NodeSectionAdd : React.FC<INodeSectionAdd> = ({type, index, handlerCancel, handlerUpdate, filterSubtypes=[]}) => {
  const { t } = useTranslation(['Common']);
  const [subtype, setSubtype] = useState<string>();

  const nodeSets : any = useMemo(() => {
    return {
      triggers: TriggerNodes,
      conditions: ConditionNodes,
      actions: ActionNodes
    }
  }, []);

  const nodeSubTypeKeys = useMemo(() => {
    const nodeSubTypes = Object.keys(nodeSets[type]);
    return nodeSubTypes.filter((subtype) => {
      if(['Basic', 'NewNode',...filterSubtypes].includes(subtype)){
        return false;
      }
      return nodeSubTypes;
    })
  }, [filterSubtypes, nodeSets, type]);

  return (
    <>
      <SelectField isCompact label={{text: t('eventRules.entityType'), htmlFor: 'selectSubtype'}} placeholder={t('eventRules.selectConditionType')} changeCallback={(subtype) => { setSubtype(subtype)}}>
        {nodeSubTypeKeys.map((node: string, index: number) => {
          return <option key={'addNew-' + index} value={node}>{t(`eventRules.${node}`)}</option>
        })}
      </SelectField>

      <NodeButtonRow>
        {handlerCancel ? <Button size='xsmall' design='secondary' onClick={ () => handlerCancel(type, index)}>{t('cancel')}</Button> : null}
        {handlerUpdate ? <Button size='xsmall' disabled={typeof subtype !== 'string'} onClick={ () => handlerUpdate(type, index, subtype)}>{t('add')}</Button> : null}
      </NodeButtonRow>
    </>
  )

}

export default NodeSectionAdd;